import React, { Component } from 'react';

import MobileSidebarMenu from '../mobileSidebars/mobileSidebarMenu.js';
import MobileSidebarFilter from '../mobileSidebars/mobileSidebarFilter.js';
import MobileSidebarLogin from '../mobileSidebars/mobileSidebarLogin.js';
import MobileSidebarSearch from '../mobileSidebars/mobileSidebarSearch.js';
import { localStorageManager } from '../Utils/Utils.js';

class Sidebars extends Component {

    searchValues = {};

    constructor (props) {
        super(props);

        this.searchValues = localStorageManager.GetLocalStorageItem("searchValues", {});
    }

    search = () => {
        const searchInputs = document.querySelectorAll("#sidebarComponent #sidebar input");
            let searchValue = '';
            let values = {};
            searchInputs.forEach(el => {
                values[el.name] = el.value;
                if (el.value == '') {
                    return;
                }
                if (searchValue === '') {
                    searchValue += el.name + "=" + el.value;
                } else {
                    searchValue += "&" + el.name + "=" + el.value;
                }
            });
            
            if (searchValue !== '') {
                localStorageManager.SetLocalStorageItem("searchValues", values);
                window.location.href = "/vyhledavani/" + encodeURI(searchValue);
            }
    }

    searchInput = (e) => {
		if (e.keyCode == 13) { 
			e.preventDefault();		

			this.search();
		}
    }
    
    searchButton = (e) => {
        e.preventDefault();		

        this.search();
    }

    render (props)  {
        
        return(
            <div id="sidebarComponent">
                <div id="sidebar" className="d-none d-lg-block sidebar">
                    <div className="leftBar">
                        <div className="m-auto sidebarWrapper" id="desktop-search">
                            <p>VYHLEDÁVÁNÍ</p>
                            <form>
                                <input placeholder="HLEDEJTE" name="searchAll" className="basicInput splashSearch col-12" type="text" onKeyDown={this.searchInput}></input>
                                <div className="splashFromButtons">
                                    <div className="float-left bringOutFilterBar"><img className="w-100 h-100" src="/assets/images/filtr.svg" alt="" /></div>
                                    <div className="float-right" onClick={this.searchButton}><img className="w-100 h-100" src="/assets/images/cd_w.svg" alt="" /></div>
                                </div>
                            </form>
                        </div>
                        <div className="m-auto sidebarWrapper d-none" id="desktop-filter">
                            <p>ROZŠÍŘENÉ VYHLEDÁVÁNÍ</p>
                            <form>
                                <input placeholder="INTERPRET" name="X_Interpret_ID.FullName" className="basicInput splashSearch col-12" type="text" defaultValue={this.searchValues["X_Interpret_ID.FullName"]} onKeyDown={this.searchInput}></input>
                                <input placeholder="TITUL" name="displayname" className="basicInput splashSearch col-12" type="text" defaultValue={this.searchValues["displayname"]} onKeyDown={this.searchInput}></input>
                                <input placeholder="ROK VYDÁNÍ" name="X_DateOfPublication" className="basicInput splashSearch col-12" type="text" defaultValue={this.searchValues["X_DateOfPublication"]} onKeyDown={this.searchInput}></input>
                                <input placeholder="NOSIČ" name="StoreMenuItem_ID.Text" className="basicInput splashSearch col-12" type="text" defaultValue={this.searchValues["StoreMenuItem_ID.Text"]} onKeyDown={this.searchInput}></input>
                                <input placeholder="CENA DO" name="X_Price" className="basicInput splashSearch col-12" type="number" defaultValue={this.searchValues["X_Price"]} onKeyDown={this.searchInput}></input>
                                <button className="squirrelFormSubmit" type="submit" onClick={this.searchButton}><img src="/assets/images/tick-inside-circle_black.svg" alt="submit button" /></button>
                            </form>
                        </div>
                    </div>
                    <div className="middleBar">

                    </div>
                    <div className="rightBar">
                        
                    </div>
                </div>


                <div className="mobile-sidebar d-block d-lg-none">
                    <MobileSidebarMenu />
                    <MobileSidebarFilter />
                    <MobileSidebarLogin login={this.props.login}/>
                    <MobileSidebarSearch />
                </div>
            </div>
            
        )

    };

}
//<img className="mobile-menu-close mobile-menu-close-img" src="/assets/images/close.svg" alt="zavření menu" />

export default Sidebars;