import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HomeLoginForm extends Component {

    formLogin = (e) => {
        e.preventDefault();

        const emailEl = document.getElementById("loginFormEmail");
        const passEl = document.getElementById("loginFormPassword");

        let email = emailEl.value;
        let password = passEl.value;
        this.props.loginFunction(email, password, [emailEl, passEl]);
    }

    render = (props) => {
        return (
            <div className="row col-12 col-lg-6 p-0 mx-auto squirrelWrapper">
                <div className="col-12 p-0 mx-auto">
                    <img className="squirrelImage p-0' mx-auto" src="/assets/images/private.svg" alt=""/>
                </div>
                <div className="col-12 p-0 mx-auto">
                    <div className="col-12 squirrelForm p-0 mx-auto squirrelText">
                        <h4>přihlásit</h4>
                        <p>Pokud nemáte vytvořený účet, tak se <Link className="redLink" to="/registrovat" alt="odkaz na stránku pro registraci">ZAREGISTRUJTE</Link> a získejte výhody členství v našem klubu jako jsou akční nabídky, přednostní prodej titulů atd.</p>
                    </div>
                </div>
                <div className="col-12 p-0 mx-auto">
                    <div className="col-12 squirrelForm squirrelMobileForm p-0 my-5 mx-auto">
                        <form onSubmit={this.formLogin} >
                            <input id="loginFormEmail" placeholder="LOGIN" className="basicInput mb-5 w-100" type="text"></input>
                            <input id="loginFormPassword" placeholder="HESLO" className="basicInput mb-5 w-100"  type="password"></input>
                            <button onClick={this.formLogin} className="squirrelFormSubmit"><img src="/assets/images/tick-inside-circle.svg" alt="submit button" /></button>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

}

export default HomeLoginForm;