import React, { Component } from 'react';
import Sharer from '../Sharer/Sharer';


class newsTile extends Component {

    share_fb = (url) => {
        window.open('https://www.facebook.com/sharer/sharer.php?u='+url,'facebook-share-dialog',"width=626, height=436")
    }
    
    render = (props) => {

        let imgSrc;

        if (this.props.imgSrc == null) {
            imgSrc = "/assets/images/newsImage.jpg";
        } else {
            imgSrc = this.props.imgSrc;
        }


        return (
            <div className="m-auto newsTile">
                <div className="newsInner mx-auto">
                    <a className="w-100 h-100 p-0 m-0" href={this.props.link} alt="odkaz na článek"/>
                    <div className="newsText">
                        <img src="/assets/images/tileEye.svg" alt="" />
                        {this.props.text}
                    </div>
                    <div className="newsImageWrapper">
                        <img src={imgSrc} className="newsImage" alt=""></img>
                    </div>
                    <div className="newsContentWrapper w-100 text-center p-3 m-0">
                    <div dangerouslySetInnerHTML={{__html: this.props.newsContent}} className="newsContent mx-auto">
                        
                    </div>
                    </div>
                    <div className="CDTileShare">
                        <img className="revealImage hidden" src="/assets/images/share.svg"  alt="tlačítko pro sdílení" />
                        <Sharer link={this.props.link} />
                    </div>
                </div>
            </div>
        );

    }

}

export default newsTile;