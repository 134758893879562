import React, { Component } from 'react';

import { NavbarSpacer } from '../Utils/Utils.js';
import RowSection from '../Sections/RowSection.js';
import SummaryPageForm from '../SummaryPageForm/SummaryPageForm.js';

class SummaryPage extends Component {

    render = (props) => {
        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto summaryPage container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/shrnuti_r.svg" header="SHRNUTÍ OBJEDNÁVKY">
                        <SummaryPageForm items={this.props.items} removeAllItemsFromCart={this.props.removeAllItemsFromCart} />
                    </RowSection>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        document.querySelector(".summaryPage .homeTileDivider").style.display = "none"
    }

}
export default SummaryPage;