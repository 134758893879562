import React from 'react';
import { Link } from 'react-router-dom';
import App from '../../App';
import { Popup } from '../Utils/Utils';
import ApiComponent from '../ApiComponent/ApiComponent';

class Cart extends ApiComponent {

    state = {
        images : {}
    }

    //tell the user to login/register if they arent
    displayPopup = (e) => {
        e.preventDefault();
        const linkLogin = <Link to="/udaje">přihlašte</Link>;
        const linkRegister = <Link to="/udaje">zaregistrujte</Link>;
        //Popup.DisplayMessage(["Pro pokračnování se ", "přihlašte", "/", "zaregistrujte"]);
        Popup.DisplayMessage("Přihlašte se prosím a vraťte se do košíku");
    }

    componentDidMount() {
        const self = this;
        this.props.items.forEach(function(item) {
            self.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/script/API/StoreCards/GetPicture?STORECARD=" + item.id)
                .then((pic) => {
                    let imagesArray = {...self.state.images};
                    imagesArray[item.id] = "data:image/png;base64," + pic.data;
                    self.setState({images:imagesArray});
                }).catch((e) => {
                    let imagesArray = {...self.state.images};
                    imagesArray[item.id] = "/assets/images/newsImage.jpg";;
                    self.setState({images:imagesArray});
                })
        });
    }

    render = () => {
        let totalPrice = 0;
        let itemsSummary = [];
        let i = 0;
        const self = this;

        const deleteHandler = this.props.deleteHandler;

        const localUser = App.LoadLocalStorageUser();

        let continueLink = (<Link to="/udaje"><button className="registerFormButtonSave vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/user_w.svg" width="20" height="20" alt=""/>POKRAČOVAT K DODACÍM ÚDAJŮM</div></button></Link>);

        if (Object.keys(localUser).length == 0) {
            continueLink = (<button onClick={this.displayPopup} className="registerFormButtonSave vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/user_w.svg" width="20" height="20" alt=""/>POKRAČOVAT K DODACÍM ÚDAJŮM</div></button>);
        }

        this.props.items.forEach(function(item) {
            totalPrice += parseInt(item.price);
            let price = item.price;
            if (Number.isInteger(price))
                price = price.toString();

            itemsSummary.push(

            <div key={i} className="cartItem">
                <div className="cartItemImageWrapper">
                    <img alt="" className="cartItemImage" src={self.state.images[item.id]}/>
                </div>
                <div className="cartItemDescWrapper">
                    <Link to={"/track/" + item.id}><h2 className="cartItemDescHeader">{item.bandName}</h2></Link>
                    <p className="cartItemDescText">{item.albumName}</p>
                    <p className="cartItemDescCode">{item.code}</p>
                    <div className="cartItemMobileImageWrapper">
                        <img alt="" className="cartItemImage" src={self.state.images[item.id]}/>
                    </div>
                </div>
                <div className="cartItemControlsWrapper">
                    <div className="cartItemControlsClose" onClick={deleteHandler}><img alt="" src="/assets/images/delete-button.svg" /></div>
                    <div className="cartItemControlsPrice color-red">{item.price}</div>
                </div>
            </div>);
            itemsSummary.push(<div key={i + 1000000} className="col-12 homeTileDivider"></div>);

            i++;
            /*
            <div key={itemsSummary.length} className="popupCartItem">
                <div className="popupCartItemLineOne">
                    <Link onClick={self.closeCart} to={item.link} alt="odkaz na položku">{item.bandName + " | " + item.albumName}</Link>
                </div>
                <div className="popupCartItemLineTwo">
                    <p>Kc{price}</p>
                    <div className="float-right">
                        <input type="number" data-index={index} value={item.amount} name="amount" readOnly={false} onChange={props.changeHandler} />
                        <button data-index={index} ><img className="w-100 h-100" src="/assets/images/close_no_decor.png" alt=""/></button>                        
                    </div>
                </div>
            </div>);*/
        });


        return (
            <div className="cartItems">
                <div className="cartCrumbs">
                    <Link to="/kosik" ><div className="cartCrumb color-red"><img src="/assets/images/cartIcon.svg" alt=""/><span>KOŠÍK</span></div></Link>
                    <div className="cartCrumb"><img className="icon-black" src="/assets/images/user.svg" alt=""/><span>DODACÍ ÚDAJE</span></div>
                    <div className="cartCrumb"><img src="/assets/images/delivery.svg" alt=""/><span>DOPRAVA A PLATBA</span></div>
                    <div className="cartCrumb noAfter"><img src="/assets/images/total.svg" alt=""/><span>SHRNUTÍ OBJEDNÁVKY</span></div>
                </div>
                <div className="col-12 homeTileDivider"></div>
                {itemsSummary}
                <div className="cartItem cartPriceSummary">
                    <div className="cartItemImageWrapper mobile">
                        <img alt="" className="cartItemImage noShadow revealImage" src="/assets/images/calculator.svg"/>
                    </div>
                    <div className="cartItemDescWrapper">
                        
                    </div>
                    <div className="cartItemControlsWrapper">
                        <p className="cartItemControlsPrice color-red">{totalPrice} Kč</p>
                    </div>
                </div>
                
                <div className="col-12 homeTileDivider"></div>

                <div className="col-12 p-0 d-flex orderPageButtonWrapper">
                    <Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt=""/>POKRAČOVAT V NÁKUPU</div></button></Link>
                    {continueLink}
                </div>

                <div className="my-5 py-5"></div>
            </div>
        );

    }

}

export default Cart;