import ApiComponent from "../ApiComponent/ApiComponent";

class Payment extends ApiComponent {

    MerchantConfig = {
        merchant: '470350',
        secret: '47820d62d27222da4572aab0dfc10462'
    }

    PaymentCreateConfig = {
        test: 'true',
        preauth: 'true',
        curr: 'CZK',
        ...this.MerchantConfig
    }

    MakePayment = (orderID, test) => {
        const response = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/script/API/Payments/ComgateCreatePayment",
            {  
                "receivedorder":orderID,
                "test":test
            }
        );
        response.then((res) => {
            console.log(res);
            if (res.data.startsWith("code=1400")) {
                //access to comgate from unauthorized location
                //happens when bazar server changes
            }

            if (typeof res.data == 'string') {
                //https://stackoverflow.com/questions/14867835/get-substring-between-two-characters-using-javascript
                const paymentUrl = decodeURIComponent(res.data.split('&redirect=').pop().split('&')[0]);
                window.location.replace(paymentUrl);
            } else {
                
            }
        });
    }

    MakePaymentTest = () => {
        const create = this.postDataFromEndpointExternal("https://payments.comgate.cz/v1.0/create", new URLSearchParams({
            price: "1000",
            refId: "10B0000101",
            label: "objednavka 10B0000101",
            method: "ALL",
            email: "jakub@vaclavik.cz",
            prepareOnly: "true",
            ...this.PaymentCreateConfig
        }).toString(), {
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
        });

        create.then((res) => {
            console.log(res);

            const data = new URLSearchParams(res.data);

            const transId = data.get("transId");
            const redirect = data.get("redirect");

            console.log(transId, redirect);

            //window.location.href = redirect;

            //commented out for test
            /*
            const capturePreauth = this.postDataFromEndpointExternal("https://payments.comgate.cz/v1.0/capturePreauth", {
                transId: transId,
                amount: "100",
                ...this.MerchantConfig
            }, {
                "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
            });*/
        });
    }

}

export default Payment;