import React, { Component } from 'react';

import { NavbarSpacer } from '../Utils/Utils.js';
import RowSection from '../Sections/RowSection.js';
import ContactInfo from '../ContactInfo/ContactInfo.js';

class ContactPage extends Component {

    render = () => {
       
        return (
            <div className="row container-fluid m-0">
                <div className="mx-auto contactPage container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/adress.svg" header="KONTAKT">
                        <ContactInfo />
                    </RowSection>
                </div>
                <div className="w-100">
                    <iframe title="contactMap" style={{"border":"none"}} src="https://frame.mapy.cz/s/nuzedekona" width="100%" height="587" frameBorder="0" />
                    
                    
                </div>
            </div>
        );

    }

}

export default ContactPage;