import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOverlay, localStorageManager, Popup } from '../Utils/Utils';
import ZasilkovnaHandler from '../ApiComponent/ZasilkovnaHandler/ZasilkovnaHandler';

class PaymentPageForm extends Component {

    packetaApiKey = 'enter-your-api-key-here';
    delivery = "";
    payment = "";
    packetaCanceled = false;

    state = {
        packetaPrice: 0,
        packetaPicked: false
    }

    showSelectedPickupPoint = (point) =>
    {
        if(point) {

            document.getElementById("zasilkovnaWidgetLabel").childNodes[0].textContent = "ZÁSILKOVNA: " + point.place;
            document.querySelector("#zasilkovnaWidgetLabel > input").value = "ZÁSILKOVNA: " + point.place;

            this.setState({packetaPicked : true});

            const packeta = {
                "ID": point.id,
                "Place": point.place,
                "City": point.city,
                "CountryCode": point.country,
                "GPS": point.gps.lat + "," + point.gps.lon,
                "PostCode": point.zip,
                "Street": point.street,
                "ZIP": point.zip
            }

            localStorageManager.SetLocalStorageItem(localStorageManager.Enums.packetaAddress, packeta);
            localStorageManager.SetLocalStorageItem(localStorageManager.Enums.packetaName, point.place);

            this.packetaCanceled = false;
        }
        else {
            this.packetaCanceled = true;
        }
    }

    componentDidMount = () => {
        LoadingOverlay.ApplyOverlay();

        //<label className="deliveryLabel large basicInput color-black placeholder-black" onClick={this.handlePaymentChange}>DOBÍRKOU<input type="radio" name="payment" value="DOBÍRKOU"></input></label>
        document.querySelector(".paymentPage .homeTileDivider").style.display = "none";

        const script = document.createElement("script");
        script.src = "https://widget.packeta.com/www/js/library.js";
        script.async = true;
        script.onload = () => this.addEventListenerToZasilkovnaWidgetButton();
      
        document.body.appendChild(script);

        /*let packetaName = localStorageManager.GetLocalStorageItem(localStorageManager.Enums.packetaName);
        if (!!packetaName) {
            document.getElementById("zasilkovnaWidgetLabel").childNodes[0].textContent = "ZÁSILKOVNA: " + packetaName;
            document.querySelector("#zasilkovnaWidgetLabel > input").value = "ZÁSILKOVNA: " + packetaName;

            document.getElementById('zasilkovnaWidgetButton').setAttribute('checked', "");
        }*/

        this.delivery = document.querySelector('#paymentPageForm input[name="delivery"]:checked').value;
        this.payment = document.querySelector('#paymentPageForm input[name="payment"]:checked').value;

        var zasilkovna = new ZasilkovnaHandler();

        zasilkovna.getInfo().then((res) => {
            if (!res.data) {
                LoadingOverlay.RemoveOverlay();
                return;
            }

            this.setState({packetaPrice : res.data[0]["X_Price"]}, LoadingOverlay.RemoveOverlay);
        });

    }

    addEventListenerToZasilkovnaWidgetButton = () => {
        document.getElementById("zasilkovnaWidgetButton").onchange = (e) => {
            window.Packeta.Widget.pick(this.packetaApiKey, this.showSelectedPickupPoint);
        }
    }

    setDataToLocalStorage = (e) => {
        let data = {};
        try {
            if (this.packetaCanceled) {
                Popup.DisplayMessage("Zvolte výdejní místo zásilkovny nebo osobní odběr");
                e.preventDefault();
                return;
            }

            data.delivery = document.querySelector('#paymentPageForm input[name="delivery"]:checked').value;
            
        } catch (error) {
            Popup.DisplayMessage("Nebyl vybrán způsob dopravy");
            e.preventDefault();
        }
        try {
            data.payment = document.querySelector('#paymentPageForm input[name="payment"]:checked').value;
        } catch (error) {
            Popup.DisplayMessage("Nebyl vybrán způsob platby");
            e.preventDefault();
        }
        localStorageManager.SetLocalStorageItem("paymentFormData", data);
    }

    changePaymentToCard = () => {
        document.querySelector("#CardPaymentInput").checked = true;
        this.payment = document.querySelector('#paymentPageForm input[name="payment"]:checked').value;
    }

    changePaymentToCash = () => {
        document.querySelector("#CashPaymentInput").checked = true;
        this.payment = document.querySelector('#paymentPageForm input[name="payment"]:checked').value;
        this.packetaCanceled = false;
    }

    handleDeliveryChange = (e) => {

        this.delivery = document.querySelector('#paymentPageForm input[name="delivery"]:checked').value;

        let el = e.target;
        let value;

        if (el.tagName == "LABEL") {
            el = el.querySelector("input");
        }
        value = el.value;

        if (value.includes("ZÁSILKOVNA") || value.includes("ZÁSILKOVNY")) {
            this.changePaymentToCard();
        } else {
            this.changePaymentToCash();
        }

    }

    handlePaymentChange = (e) => {

        this.payment = document.querySelector('#paymentPageForm input[name="payment"]:checked').value;

        if (this.delivery.includes("ZÁSILKOVNA")) {
            e.preventDefault();
            Popup.DisplayMessage("Pro doručení zásilkovnou se nedá platit hotovostí");
        } else {
            e.preventDefault();
            Popup.DisplayMessage("Pro osobní odběr se nedá zaplatit kartou předem");
        }
        /*
        let el = e.target;

        if (el.tagName == "LABEL") {
            el = el.querySelector("input");
        }

        if (el.value.includes("ZÁSILKOVNA")) {
            
        } else {
            switch (el.value) {
                case "OSOBNÍ ODBĚR":
                    
                    break;
                default:
                    throw new Error();
            }
        }*/

    }

    render = (props) => {

        return (
            <div>
                <div className="registerForm w-100 mb-0">
                    <div className="cartCrumbs">
                        <Link to="/kosik" ><div className="cartCrumb color-red"><img src="/assets/images/cartIcon.svg" alt=""/><span>KOŠÍK</span></div></Link>
                        <Link to="/udaje" ><div className="cartCrumb color-red"><img className="icon-red" src="/assets/images/user.svg" alt=""/><span>DODACÍ ÚDAJE</span></div></Link>
                        <Link to="/platba" ><div className="cartCrumb color-red"><img className="icon-red" src="/assets/images/delivery.svg" alt=""/><span>DOPRAVA A PLATBA</span></div></Link>
                        <div className="cartCrumb noAfter"><img src="/assets/images/total.svg" alt=""/><span>SHRNUTÍ OBJEDNÁVKY</span></div>
                    </div>
                    <div className="col-12 homeTileDivider"></div>
                    <form id="paymentPageForm">
                        <div className="col-12 mb-4 vertical-center"><img className="d-inline icon-red" src="/assets/images/delivery.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> VYBERTE SI ZPŮSOB DORUČENÍ</p></div>
                        <label className="deliveryLabel basicInput color-black placeholder-black" onClick={this.handleDeliveryChange}>OSOBNÍ ODBĚR<input type="radio" name="delivery" value="OSOBNÍ ODBĚR" defaultChecked={true}></input></label>
                        <label className="deliveryLabel basicInput color-black placeholder-black" onClick={this.handleDeliveryChange} id="zasilkovnaWidgetLabel">BALÍK DO ZÁSILKOVNY{!this.state.packetaPicked ? ": " + this.state.packetaPrice + "Kč" : ""}<input id="zasilkovnaWidgetButton" value="ZÁSILKOVNA" type="radio" name="delivery"></input></label>
                        <div className="desktopSpacer deliveryLabel large invisible"></div>

                        <div className="col-12 my-4 vertical-center"><img className="d-inline" src="/assets/images/wallet.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> VYBERTE SI ZPŮSOB PLATBY</p></div>
                        <label className="deliveryLabel basicInput color-black placeholder-black" onClick={this.handlePaymentChange}>HOTOVOSTÍ<input type="radio" name="payment" value="HOTOVOSTÍ" id='CashPaymentInput' defaultChecked={true}></input></label>
                        <label className="deliveryLabel basicInput color-black placeholder-black" onClick={this.handlePaymentChange}>PLATEBNÍ KARTOU<input type="radio" name="payment" value="PLATEBNÍ KARTOU" id='CardPaymentInput'></input></label>
                        <div className="desktopSpacer deliveryLabel large invisible"></div>

                    </form>
                </div>
                <div className="registerForm w-100 bottomPart">
                    <div className="col-12 homeTileDivider"></div>
                    <div className="col-12 p-0 d-flex orderPageButtonWrapper">
                        <Link to="/udaje"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0">ZPĚT</div></button></Link>
                        <Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt=""/>POKRAČOVAT V NÁKUPU</div></button></Link>
                        <Link to="/shrnuti" onClick={this.setDataToLocalStorage}><button className="registerFormButtonSave vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/save.svg" width="20" height="20" alt=""/>POKRAČOVAT KE SHRNUTÍ OBJEDNÁVKY</div></button></Link>
                    </div>
                </div>
            </div>
        );

    }

}

export default PaymentPageForm;