import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class mobileSidebarLogin extends Component {

    formLogin = (e) => {
        e.preventDefault();

        const emailEl = document.getElementById("loginFormEmailMobile");
        const passEl = document.getElementById("loginFormPasswordMobile");

        let email = emailEl.value;
        let password = passEl.value;
        this.props.login(email, password);
    }
    
    render = (props) => {
        return (
            <div className="mobile-menu mobile-menu-login ">
                <img className="mobile-menu-close mobile-menu-close-img" src="/assets/images/close.svg" alt="zavření menu" />
                <div className="row w-75 m-0 pt-5 sidebarWrapper">
                    <div className="row col-12 p-0 mx-auto squirrelWrapper">
                        <div className="col-12 p-0 mx-auto">
                            <div className="col-12 p-0 my-5 mx-auto">
                                <h4 className="text-center text-white sidebar-register-text mb-5">PŘIHLÁŠENÍ</h4>
                                <form>
                                    <input id='loginFormEmailMobile' placeholder="EMAIL" className="basicInput text-black w-100 placeholder-black" type="text"></input>
                                    <input id='loginFormPasswordMobile' placeholder="HESLO" className="basicInput mt-3 mb-5 w-100 placeholder-black" type="text"></input>
                                    <button className="squirrelFormSubmit" type="submit" onClick={this.formLogin}><img src="/assets/images/tick-inside-circle_black.svg" alt="submit button" /></button>
                                </form>
                                <h4 className="text-center sidebar-register-text mt-5"><Link className="mobile-menu-close text-white" to="/registrovat">REGISTRACE</Link></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default mobileSidebarLogin;