import SquirelForm from '../SquirelForm/SquirelForm.js';
import React, { Component } from 'react';
import { NavbarSpacer } from '../Utils/Utils.js';


class SquirrelPage extends Component {

    render = () => {

        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto servicePage miniPage container">
                    <NavbarSpacer />
                    <SquirelForm />
                </div>
            </div>
        );

    }

}

export default SquirrelPage;