import React from 'react';

const ServiceForm = () => {

    return (

        <div className="row col-12 col-lg-6 p-0 mx-auto squirrelWrapper">
            <div className="col-12 p-0 mx-auto">
                <img className="squirrelImage p-0' mx-auto" src="/assets/images/info.svg" alt=""/>
            </div>
            <div className="col-12 p-0 mx-auto">
                <div className="col-12 p-0 mx-auto squirrelText squirrelList">
                    <h4>naše služby</h4>
                    <ul>
                        <li>hudební a filmové tituly na CD, DVD a hry</li>
                        <li>široký výběr</li>
                        <li>skladem neustále přes 10.000 titulů na CD</li>
                        <li>nízké ceny</li>
                        <li>na zakoupené CD týden záruka!</li>
                        <li>prodejna přímo v centru Prahy</li>
                        <li>výkup titulů po celou otevírací dobu</li>
                    </ul>
                    <p>
                        Vykupujeme originální CD, DVD, hry na Playstation 2,3 XBox 360, PC BlueRay disky v dobrém stavu za hotové. Peníze dostanete okamžitě na ruku a to po celou otevírací dobu.
                    </p>
                    <p>
                        <b>ZÁRUKA</b><br />
                        Na všechny zakoupené nosiče poskytujeme týdenní záruku.
                    </p>
                </div>
            </div>
        </div>

    );

}

export default ServiceForm;