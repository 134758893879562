import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import App from '../../App';
import ApiComponent from '../ApiComponent/ApiComponent';
import { LoadingOverlay, parseAdressIntoComponents } from '../Utils/Utils';

class RegisterFormDelivery extends ApiComponent {

    state = {form : <div></div>}

    fillFormData = () => {
        const localUser = App.LoadLocalStorageUser();
        const localUserData = App.LoadLocalStorageUserData();

        if (!!localUserData && !!localUser) {
            document.querySelector("input[name='name']").value = localUserData.name;
            document.querySelector("input[name='OrgIdentNumber']").value = localUserData.OrgIdentNumber;
            document.querySelector("input[name='VATIdentNumber']").value = localUserData.VATIdentNumber;
            let adressArr = parseAdressIntoComponents(localUserData["residenceaddress_id.street"])
            document.querySelector("input[name='street']").value = adressArr["address"];
            document.querySelector("input[name='cp']").value = adressArr["cp"];
            document.querySelector("input[name='co']").value = adressArr["co"];
            document.querySelector("input[name='residenceaddress_id.City']").value = localUserData["residenceaddress_id.City"];
            document.querySelector("input[name='residenceaddress_id.PostCode']").value = localUserData["residenceaddress_id.PostCode"];
            document.querySelector("input[name='residenceaddress_id.Country']").value = localUserData["residenceaddress_id.Country"];

            document.querySelector("input[name='residenceaddress_id.email']").value = localUser.EMail;
            document.querySelector("input[name='X_FirstName']").value = localUser.X_FirstName;
            document.querySelector("input[name='X_LastName']").value = localUser.X_LastName;
            document.querySelector("input[name='residenceaddress_id.PhoneNumber1']").value = localUser.PhoneNumber1;
        }
	}

    registerFormChecked = (e) => {
        if (!e.target.checked) {
            this.setState({form: <div className="mt-3 registerFormCopy">
                <div className="col-12 mb-4 vertical-center"><img className="d-inline" src="/assets/images/adress.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> FAKTURAČNÍ ADRESA</p></div>
                <input inputMode="text" placeholder="JMÉNO / NÁZEV FIRMY" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="IČ" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="DIČ" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="NÁZEV ULICE" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="ČÍSLO POPISNÉ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                <input inputMode="text" placeholder="CÍSLO ORIENTAČNÍ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                <input inputMode="text" placeholder="MĚSTO" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="PSČ" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="ZEMĚ" className="basicInput registerInput color-black placeholder-black"></input>
            </div>});
        } else {
            this.setState({form: <div></div>})
        }
    }

    componentDidMount() {
        this.fillFormData();
    }

    render = () => {

        return (
            <div>
                <div className="registerForm w-100 mb-0">
                <div className="cartCrumbs">
                    <Link to="/kosik" ><div className="cartCrumb color-red"><img src="/assets/images/cartIcon.svg" alt=""/><span>KOŠÍK</span></div></Link>
                    <Link to="/udaje" ><div className="cartCrumb color-red"><img className="icon-red" src="/assets/images/user.svg" alt=""/><span>DODACÍ ÚDAJE</span></div></Link>
                    <div className="cartCrumb"><img src="/assets/images/delivery.svg" alt=""/><span>DOPRAVA A PLATBA</span></div>
                    <div className="cartCrumb noAfter"><img src="/assets/images/total.svg" alt=""/><span>SHRNUTÍ OBJEDNÁVKY</span></div>
                </div>
                    <div className="col-12 homeTileDivider"></div>
                    <form>
                        <div className="col-12 mb-4 vertical-center"><img className="d-inline" src="/assets/images/user.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> ÚDAJE ZÁKAZNÍKA</p></div>
                        <input name='residenceaddress_id.email' inputMode="text" placeholder="EMAIL" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name='X_FirstName' inputMode="text" placeholder="JMÉNO" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name='X_LastName' inputMode="text" placeholder="PŘIJMENÍ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name='residenceaddress_id.PhoneNumber1' inputMode="text" placeholder="TELEFON" className="basicInput registerInput color-black placeholder-black"></input>
                        <input readOnly inputMode="text" placeholder="HESLO" className="basicInput registerInput desktopOnly invisible color-black placeholder-black"></input>
                        <input readOnly inputMode="text" placeholder="HESLO ZNOVU" className="basicInput registerInput desktopOnly invisible color-black placeholder-black"></input>

                        <div className="col-12 mb-4 vertical-center"><img className="d-inline" src="/assets/images/adress.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> DORUČOVACÍ ADRESA</p></div>
                        <input name='name' inputMode="text" placeholder="JMÉNO / NÁZEV FIRMY" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name='OrgIdentNumber' inputMode="text" placeholder="IČ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name='VATIdentNumber' inputMode="text" placeholder="DIČ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name='street' inputMode="text" placeholder="NÁZEV ULICE" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name='cp' inputMode="text" placeholder="CÍSLO ORIENTAČNÍ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                        <input name='co' inputMode="text" placeholder="ČÍSLO POPISNÉ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                        <input name='residenceaddress_id.City' inputMode="text" placeholder="MĚSTO" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name='residenceaddress_id.PostCode' inputMode="text" placeholder="PSČ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name='residenceaddress_id.Country' inputMode="text" placeholder="ZEMĚ" className="basicInput registerInput color-black placeholder-black"></input>

                        <label className="color-red vertical-center"><input className="registerformCheckbox mr-2" type="checkbox" defaultChecked={true} onChange={this.registerFormChecked}></input>FAKTURAČNÍ ADRESA JE STEJNÁ JAKO DORUČOVACÍ ADRESA</label>

                        {this.state.form}

                    </form>
                </div>
                <div className="col-12 homeTileDivider"></div>
                <div className="registerForm w-100 bottomPart">
                    <div className="col-12 homeTileDivider"></div>
                    <div className="col-12 p-0 d-flex orderPageButtonWrapper">
                        <Link to="/kosik"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0">ZPĚT</div></button></Link>
                        <Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt=""/>POKRAČOVAT V NÁKUPU</div></button></Link>
                        <Link to="/platba"><button className="registerFormButtonSave vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/save.svg" width="20" height="20" alt=""/>POKRAČOVAT K DOPRAVĚ A PLATBĚ</div></button></Link>
                    </div>
                </div>
            </div>
        );

    }

}

export default RegisterFormDelivery;