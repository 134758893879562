import React, { Component } from 'react';

import { NavbarSpacer } from '../Utils/Utils.js';
import RowSection from '../Sections/RowSection.js';
import ProfileSquirrel from '../ProfileSquirrel/ProfileSquirrel.js';
import M11 from '../ProfileSquirrel/m11.js';

class M1 extends Component {

    render = (props) => {
        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto profilePage cartPage squirrelPage container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/cd.svg" header="Výsledky vyhledávání">
                        <M11/>
                    </RowSection>
                </div>
            </div>
        )
    }

    componentDidMount = () => {
        document.querySelector(".profilePage .homeTileDivider").style.display = "none";
    }

}

export default M1;