import React from 'react';
import ApiComponent from '../ApiComponent/ApiComponent.js';

//TODO finish this
class ImageComponent extends ApiComponent {
   
    state = {imageSource: "/assets/images/newsImage.jpg"}

    componentDidMount() {
        this.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/script/API/StoreCards/GetPicture?STORECARD=" + this.props.id)
            .then((pic) => {
                let base64Image = pic.data;
                this.setState({imageSource:"data:image/png;base64," + base64Image});
            }).catch((e) => {
                this.setState({imageSource:"/assets/images/newsImage.jpg"});
            })
    }

    render = () => {
        return <img alt={this.props.id} id={"image" + this.props.id} className={this.props.className} src={this.state.imageSource}/>
    }

}
 
export default ImageComponent;