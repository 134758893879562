import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
//import { useHistory } from 'react-router';
//import { useScroll } from 'react-router-scroll';

import { sha3_512 } from 'js-sha3';

import Navbar from './components/Navbar/Navbar.js';
import NewsTile from './components/Tiles/NewsTile.js';
import CDTile from './components/Tiles/CDTile.js';
import Footer from './components/Footer/Footer.js';
import Sidebar from './components/Sidebars/Sidebars.js';
import PopupCart from './components/ConstrolUtils/ControlCart.js';
import ControlUtils from './components/ConstrolUtils/ControlUtils.js';
import ScrollToTop from './scripts/ScrollToTop.js';

import HomePage from './components/Pages/HomePage.js';
import FastShop from './components/Pages/FastShop.js';
import DeliveryTerms from './components/Pages/DeliveryTerms.js';
import TrackPage from './components/Pages/Track.js';
import RegisterPage from './components/Pages/RegisterPage.js';
import ContactPage from './components/Pages/ContactPage.js';
import CartPage from './components/Pages/CartPage.js';
import DeliveryInfoPage from './components/Pages/DeliveryInfoPage.js';
import PaymentPage from './components/Pages/PaymentPage.js';
import SummaryPage from './components/Pages/SummaryPage.js';
import ProfilePage from './components/Pages/ProfilePage.js';
import ProfileDeliveryPage from './components/Pages/ProfileDeliveryPage.js';
import ProfileSquirrelPage from './components/Pages/ProfileSquirrelPage.js';
import ProfileOrdersPage from './components/Pages/ProfileOrdersPage.js';
import OrderPage from './components/Pages/OrderPage.js';
import ServicePage from './components/Pages/ServicePage.js';
import NewsPage from './components/Pages/NewsPage.js';
import SquirrelPage from './components/Pages/SquirrelPage.js';
import LoginPage from './components/Pages/LoginPage.js';
import ApiComponent from './components/ApiComponent/ApiComponent';
import { InputStyle, LoadingOverlay, Popup } from './components/Utils/Utils';
import SearchPage from './components/Pages/SearchPage';
import M1 from './components/Pages/m1';
import ControlPopup from './components/ConstrolUtils/ControlPopup';
import DisplayCart from './components/ConstrolUtils/DisplayCart';

class App extends ApiComponent {

  //increment number to reset cookies
  resetSequence = 101;

  constructor (props){
    super(props);

    let reset = App.LoadLocalStorageReset();
    if (reset === null || reset.sequence != this.resetSequence) {
      localStorage.clear();
      App.SetLocalStorageReset({sequence: this.resetSequence});
      console.log(this.resetSequence, App.LoadLocalStorageReset());
    }
  
    this.state = {
      newsTiles: [
        <NewsTile key="0" link="/" newsContent="Lorem <i>ipsum <b>dolor sisdgereheh</b> erheheherh</i>" imgSrc="/assets/images/daftpunk.jpg" text="Nová Operační Doba"></NewsTile>, 
        <NewsTile key="1" link="/" newsContent="Lorem ipsum ahab sit doler amos lokos bobos unos" text="Výkup DVD dočasně pozastaven!"></NewsTile>
      ],
      cdTiles: [
        <CDTile key="0" id="1" addHandler={this.addToCart} link="/track/1" bandName="daft punk" discIcon="/assets/images/cd-ico.svg" albumName="RANDOM ACCESS MEMORIES" year="2013" price="250 Kč" imgSrc="/assets/images/daftpunk.jpg"></CDTile>,
        <CDTile key="1" id="0" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="Příliš žluťoučký kůň úpěl ďábelské ódy" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg"></CDTile>
      ],
      shitTiles: [
        /*<CDTile key="0" id="0" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="motorhead" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg" comment="test comment"></CDTile>,
        <CDTile key="1" id="1" addHandler={this.addToCart} link="/track/0" bandName="daft punk" discIcon="/assets/images/cd-ico.svg" albumName="RANDOM ACCESS MEMORIES" year="2013" price="250 Kč" imgSrc="/assets/images/daftpunk.jpg" comment="super long test comment lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum"></CDTile>,
        <CDTile key="2" id="2" addHandler={this.addToCart} link="/track/0" bandName="daft punk" discIcon="/assets/images/cd-ico.svg" albumName="RANDOM ACCESS MEMORIES" year="2013" price="250 Kč" imgSrc="/assets/images/daftpunk.jpg"></CDTile>,
        <CDTile key="3" id="3" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="motorhead" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg"></CDTile>*/
      ],
      logged : false,
      cart : [/*
        {id: "1", bandName: "DAFT PUNK", albumName: "Random Access Memories", price: "250", link: "/track/1", code: "9875345436654", image: "/assets/images/motorhead.jpg"},
        {id: "0", bandName: "DAFT PUNK DAFT", albumName: "Random Access Memories Random Access Memories Random Access Memories Random Access", price: "270", link: "/track/0", code: "9875345436654", image: "/assets/images/daftpunk.jpg"}
      */],
      loggedInUser : {}
    }

    this.state.loggedInUser = App.LoadLocalStorageUser();
    this.state.cart = App.LoadLocalStorageCart();
  
    this.changeItemAmountFromInput = this.changeItemAmountFromInput.bind(this);
    this.delteItemFromCart = this.delteItemFromCart.bind(this);
    this.addToCart = this.addToCart.bind(this);

  }

  static SetLocalStorageUser = (userProfile) => {
    window.localStorage.setItem("loggedInUser", JSON.stringify(userProfile));
  }

  static LoadLocalStorageUser = () => {
    let user = JSON.parse(window.localStorage.getItem("loggedInUser"));
    if (!user) {
      return {};
    } else {
      return user;
    }
  }

  static SetLocalStorageUserData = (userData) => {
    window.localStorage.setItem("loggedInUserData", JSON.stringify(userData));
  }

  static LoadLocalStorageUserData = () => {
    let userData = JSON.parse(window.localStorage.getItem("loggedInUserData"));
    if (!userData) {
      return null;
    } else {
      return userData;
    }
  }

  static SetLocalStorageCart = (cart) => {
    window.localStorage.setItem("cart", JSON.stringify(cart));
  }

  static LoadLocalStorageCart = () => { 
    let cart = JSON.parse(window.localStorage.getItem("cart"));
    if (!cart) {
      return [];
    } else {
      return cart;
    }
  }

  static SetLocalStorageReset = (data) => {
    window.localStorage.setItem("reset", JSON.stringify(data));
  }

  static LoadLocalStorageReset = () => { 
    let reset = JSON.parse(window.localStorage.getItem("reset"));
    if (!reset) {
      return null;
    } else {
      return reset;
    }
  }

  login = (email, password, inputElements = null) => {
    if (email.length === 0 || password.length === 0) {
      Popup.DisplayMessage("Nesprávně zadaný email nebo heslo");
      return false;  
    }

    let auth = sha3_512(email + ":" + password);
    let loginResonse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/script/API/Auth/Firm", {"authorization": "Bearer " + auth});
    LoadingOverlay.ApplyOverlay(document.body);
    loginResonse.then((res) => {
      if (JSON.stringify(res.data) !== "{}") {
        App.SetLocalStorageUser(res.data);
        this.setState({loggedInUser: res.data}, () => {
          LoadingOverlay.RemoveOverlay();
          if (window.location.pathname.endsWith("/prihlaseni") || window.location.pathname.endsWith("/registrovat")) {
            window.location.href = "/profil";
          } else {
            window.location.href = "/";
          }
        });
      } else {
        if (inputElements !== null) {
          inputElements.forEach(el => {
            InputStyle.MarkInputWrong(el, "Špatné přihlašovací údaje");
          });
        } else {
          Popup.DisplayMessage("Špatné přihlašovací údaje");
        }
        LoadingOverlay.RemoveOverlay();
        return false;
      }
    });
  }

  sidebar = "mobile-menu-menu";

  innerLeftBar = (e) => {
		e.preventDefault();
		document.querySelector("#main").classList.add("ml-365");
		document.querySelector("#sidebar").classList.add("leftOut");
		document.querySelector(".ControlImgLeft").classList.add("ml-365");
		document.querySelector("#navbar").classList.add("ml-365");
	}

	innerFilterBar = (e) => {
    e.preventDefault();
    document.querySelector("#desktop-search").classList.add("d-none");
    document.querySelector("#desktop-filter").classList.remove("d-none");
	}

  innerMobileSidebar = (sidebar, e) => {
    e.preventDefault();
    this.sidebar = sidebar;

    document.querySelectorAll(".mobile-menu-block").forEach(element => {
      element.classList.remove("mobile-menu-block");
    });
    
    document.querySelector("#main").classList.add("mr-365");
    document.querySelector("#navbar").classList.add("mr-365");
    document.querySelector(".mobile-sidebar").classList.add("mr-365");
    document.querySelector(".mobile-menu." + sidebar).classList.add("mobile-menu-block");
    setTimeout(() => {
      document.addEventListener("click", this.closeSidebarOnClick);
    }, 300);
  }

  innerCloseSidebars = () => {
    document.querySelector("#sidebar").classList.remove("leftOut");
    document.querySelector("#sidebar").classList.remove("rightOut");
    document.querySelector("#main").classList.remove("ml-365");
    document.querySelector("#main").classList.remove("mr-365");
    document.querySelector(".ControlImgLeft").classList.remove("ml-365");
    document.querySelector(".ControlImgRight").classList.remove("mr-365");
    document.querySelector("#navbar").classList.remove("ml-365");
    document.querySelector("#navbar").classList.remove("mr-365");
    document.querySelector(".mobile-sidebar").classList.remove("mr-365");
    document.querySelector(".mobile-sidebar").classList.remove("ml-365");
    setTimeout(() => {
      document.querySelector("#desktop-search").classList.remove("d-none");
      document.querySelector("#desktop-filter").classList.add("d-none");
    }, 600);
  }

  innerCloseMobileSidebar = () => {
    document.querySelector(".mobile-sidebar").classList.remove("mobile-sidebar-out");
    document.querySelector("#main").classList.remove("mr-365");
    document.querySelector("#navbar").classList.remove("mr-365");
    document.querySelector(".mobile-sidebar").classList.remove("mr-365");
    document.querySelector(".mobile-sidebar").classList.remove("ml-365");

    setTimeout(() => {
      document.querySelector(".mobile-menu." + this.sidebar).classList.remove("mobile-menu-block");		
    }, 600);
  }

  closeSidebarOnClick = (e) => {

    let path = [];
    let currentEl = e.srcElement;

    while (currentEl.parentElement) {
      path.push(currentEl);
      currentEl = currentEl.parentElement;
    }

    let contains = false;
    let clickedOnFilter = false;

    path.forEach(function(item) {
      
      if (item.classList.contains("mobile-cart-count")) {
        clickedOnFilter = true
      }
      
      if (item.classList.contains("bringOutFilterBar") || item.classList.contains("mobile-sidebar") || item.classList.contains("sidebar") || (item.classList.contains("mobile-navbar-images") && !clickedOnFilter)) {
        contains = true;
      }

    });

    if (!contains) {
      this.innerCloseMobileSidebar();
      this.innerCloseSidebars();

      
      e.preventDefault();
      document.removeEventListener("click", this.closeSidebarOnClick);
    }
  }

  bringOutSidebar = () => {
    
    let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (w >= 1117) {
      document.querySelector(".middleBar").addEventListener("click", this.innerCloseSidebars);

      document.querySelectorAll(".bringOutLeftBar").forEach(item => {
        item.addEventListener("click", this.innerLeftBar) ;
      });
      
      document.querySelectorAll(".bringOutFilterBar").forEach(item => {
        item.addEventListener("click", this.innerFilterBar);
      });

    } else {

      document.querySelectorAll(".mobile-menu-close").forEach(el => {el.addEventListener("click", this.innerCloseMobileSidebar)});

      document.querySelectorAll(".mobile-bring-out-menu").forEach(item => {
          item.addEventListener("click", this.innerMobileSidebar.bind(this, "mobile-menu-menu"));
      });
    
      document.querySelectorAll(".mobile-bring-out-filters").forEach(item => {
          item.addEventListener("click", this.innerMobileSidebar.bind(this, "mobile-menu-filter"));
      });
    
      document.querySelectorAll(".mobile-bring-out-login").forEach(item => {
          item.addEventListener("click", this.innerMobileSidebar.bind(this, "mobile-menu-login"));
      });
    
      document.querySelectorAll(".mobile-bring-out-search").forEach(item => {
          item.addEventListener("click", this.innerMobileSidebar.bind(this, "mobile-menu-search"));
      });
      
    }

  }

  

  addToCart = (item, e) => {
    if (item.id == null || item.id == undefined || item.id === 0) {
      return;
    }
    
    let addToCartAnim = () => {
      document.querySelector(".addToCartWrapper").classList.add("addToCartWrapperDown");
      setTimeout(() => {
        document.querySelector(".addToCartWrapper").classList.remove("addToCartWrapperDown");        
      }, 2500);
    }

    let id;
    if (!e.target.classList.contains("addageButton")) {
      id = e.target.parentElement.dataset.id;
    } else {
      id = e.target.dataset.id;
    }
    
    let newState = this.state.cart;
    /*
    let added = false;
    newState.forEach(function(item) {
      if (item.id === id) {
        item.amount = (Number(item.amount) + 1).toString();
        added = true;
      }
    });
    if (!added) {
      newState.push(item);
    }*/

    //{id: "1", bandName: "DAFT PUNK", albumName: "Random Access Memories", price: "250", link: "/track/1", code: "9875345436654", image: "/assets/images/motorhead.jpg"}
    if (!this.state.cart.some((cartItem) => {return cartItem.id == item.id})) {
      newState.push(item);
      this.setState({ cart: newState }, () => {App.SetLocalStorageCart(this.state.cart)});
    }

    addToCartAnim();
    
  }

  changeItemAmountFromInput (e) {

    let val = e.target.value;
    let cartContent = this.state.cart;
    
    if (val > 0) {
        cartContent[e.target.dataset.index].amount = val;
    } else return;

    this.setState({
        cart: cartContent
    })

  }

  delteItemFromCart (e) {
    let newState = this.state.cart;
    newState.splice(e.target.parentElement.dataset.index, 1);
    this.setState(newState, () => {App.SetLocalStorageCart(newState)});
  }

  removeAllItemsFromCart = () => {
    this.setState({ cart: []}, () => {App.SetLocalStorageCart(null)});
  }

  cartClicked () {
    let el = document.querySelector("#popupCartWrapper");
    el.classList.add("cartPopedUp");
    el.classList.remove("cartPopedDown");
  }
  
  componentDidMount = () => {
    this.bringOutSidebar();
    window.addEventListener('resize', this.bringOutSidebar);
    if (!!this.state.loggedInUser.ID) {
      this.saveUserDataFromApi(this.state.loggedInUser.ID);
    }
  }

  render (props) {

    let loginPage;

    if (!!this.state.loggedInUser) {
      loginPage = (
        <Route exact path="/prihlaseni" render={({ match }) => (
          <LoginPage  loginFunction={this.login}/>
        )} />);
    } else {
      loginPage = (
        <Route exact path="/prihlaseni" render={({ match }) => (
          <ProfilePage user={this.state.loggedInUser}/>
        )} />);
    }

    return (
      <Router >
      <div>
        <Sidebar login={this.login}/>
        <main id="main" role="main" className="w-100">
          <Navbar itemCount={this.state.cart.length} userName={this.state.loggedInUser["X_FirstName"]}/>  

          <Route exact path="/" render={({ match }) => (
            <HomePage addToCart={this.addToCart} cartItemCount={this.state.cart.length} newsTiles={this.state.newsTiles} cdTiles={this.state.cdTiles} shitTiles={this.state.shitTiles} loginFunction={this.login} controlsBinding={this.bringOutSidebar}/>
          )} />

          <Route exact path="/sluzby" render={({ match }) => (
            <ServicePage/>
          )} />

          <Route exact path="/veverka" render={({ match }) => (
            <SquirrelPage/>
          )} />

          {loginPage}

          <Route exact path="/novinky" render={({ match }) => (
            <NewsPage newsTiles={this.state.newsTiles}/>
          )} />

          <Route exact path="/zkracene/:page/:perPage/:search?" render={({ match }) => (
            <FastShop page={match.params.page} perPage={match.params.perPage} search={match.params.search} AddToCartHandler={this.addToCart} />
          )} />

          <Route exact path="/podminky" render={({ match }) => (
            <DeliveryTerms />
          )} />

          <Route exact path="/track/:trackId" render={({ match }) => (
            <TrackPage trackId={match.params.trackId}  AddToCartHandler={this.addToCart}/>
          )} />
          
          <Route exact path="/registrovat" render={({ match }) => (
            <RegisterPage loginFunction={this.login}/>
          )} />

          <Route exact path="/kontakt" render={({ match }) => (
            <ContactPage />
          )} />

          <Route exact path="/kosik" render={({ match }) => (
            <CartPage items={this.state.cart} deleteHandler={this.delteItemFromCart}/>
          )} />

          <Route exact path="/udaje" render={({ match }) => (
            <DeliveryInfoPage/>
          )} />

          <Route exact path="/platba" render={({ match }) => (
            <PaymentPage/>
          )} />

          <Route exact path="/shrnuti" render={({ match }) => (
            <SummaryPage items={this.state.cart} removeAllItemsFromCart={this.removeAllItemsFromCart}/>
          )} />

          <Route exact path="/profil" render={({ match }) => (
            <ProfilePage user={this.state.loggedInUser}/>
          )} />

          <Route exact path="/profil/dodaci-udaje" render={({ match }) => (
            <ProfileDeliveryPage/>
          )} />

          <Route exact path="/profil/veverka" render={({ match }) => (
            <ProfileSquirrelPage/>
          )} />

          <Route exact path="/profil/objednavky" render={({ match }) => (
            <ProfileOrdersPage/>
          )} />

          <Route exact path="/objednavka/:id/:res" render={({ match }) => (
            <OrderPage id={match.params.id} result={match.params.res}/>
          )} />

          <Route exact path="/objednavka/:id" render={({ match }) => (
            <OrderPage id={match.params.id}/>
          )} />

          <Route exact path="/vyhledavani/:search" render={({ match }) => (
            <SearchPage search={match.params.search} AddToCartHandler={this.addToCart} />
          )} />

          <Route exact path="/m1" render={({ match }) => (
            <M1/>
          )} />

          <div>
            <div onClick={this.cartClicked} className="addToCartWrapper"><img src="/assets/images/cart_delish_2_2x.png" alt="" /></div>
          </div>
          <PopupCart items={this.state.cart} changeHandler={this.changeItemAmountFromInput} deleteHandler={this.delteItemFromCart}/>
          <ControlPopup />
          <Footer />
          <ControlUtils itemCount={this.state.cart.length} />
          <DisplayCart items={this.state.cart}/>
          <ScrollToTop />
        </main>
      </div>
      </Router>
    );
  };

}

export default App;
