import React, {Component} from 'react'

class ContactInfo extends Component {

    render = () => {

        return (
            <div className="row w-100 mx-auto p-0 color-gray contactInfo">
                <div className="contactInfoSection col-6 col-lg-4 p-0 mb-5">
                    <h4 className="termText color-red">ADRESA SÍDLA FIRMY</h4>
                    <p className="termText">Lukáš Hobzík</p>
                    <p className="termText">U chmelnice 5</p>
                    <p className="termText">Praha 4, 143 00</p>
                    <p className="termText">IČO: 43714897</p>
                    <br />
                    <p className="termText">Petr Větrovec</p>
                    <p className="termText">Klostermannova 691/43</p>
                    <p className="termText">Praha 4, 143 00</p>
                    <p className="termText">IČO: 40815048</p>
                    <br />
                    <p className="termText">Radim Jeniš</p>
                    <p className="termText">U Modřanské školy 149/6</p>
                    <p className="termText">Praha 4, 143 00</p>
                    <p className="termText">IČO: 43717608</p>
                </div>
                <div className="contactInfoSection col-6 col-lg-4 p-0 mb-5">
                    <h4 className="termText color-red">PROVOZOVNA A VÝKUPNA</h4>
                    <p className="termText">Školská 36</p>
                    <p className="termText">Praha 1</p>
                    <p className="termText">IČO: 43714897</p>
                    <p className="termText">&nbsp;</p>
                    <br />
                    <a className="termText" href="tel:+420-222240914">tel: 222 240 914</a>
                    <br />
                    <a className="termText" href="mailto: bazar@bazar-cd.cz">email: bazar@bazar-cd.cz</a>
                    <p className="termText">č.ú.: 192758990/0300</p>
                    <p className="termText">&nbsp;</p>
                    <br />
                    <h4 className="termText color-red">OTEVÍRACÍ DOBA</h4>
                    <p className="termText">Po-Pa 9-19 hod</p>
                    <p className="termText">So 10-14 hod</p>
                </div>
                <div className="d-none d-lg-block col-lg-4 p-0">
                    <img className="w-100" src="/assets/images/gogle_photo.jpg" alt="obrázek obchodu z venku"/>
                </div>
            </div>
        );

    }

}

export default ContactInfo;