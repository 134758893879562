import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class ControlCart extends Component {

    wrapperClicked = (e) => {
        if (e.target.id === "popupCartWrapper" || e.target.classList.contains("popupCartClose")) {
            this.closeCart();
        }
    }

    closeCart = (e) => {
        
        //if (typeof(e) === "undefined")
        //    return;

        let checkbox = document.querySelector("#controlCartConfirm");
        if (checkbox.checked) {
            let el = document.querySelector("#popupCartWrapper");
            el.classList.add("cartPopedDown");
            el.classList.remove("cartPopedUp");
        } else {
            if (typeof(e) !== "undefined") {
                if (e.target.tagName == "A") {
                    console.log(e.target.href);
                    if (e.target.href.includes("/track/")) {
                        let el = document.querySelector("#popupCartWrapper");
                        el.classList.add("cartPopedDown");
                        el.classList.remove("cartPopedUp");
                    } else {
                        e.preventDefault();
                        let form = document.querySelector("#popupCartConfirmFormWrapper");
                        form.classList.add("popupCartConfirmFormWrapperAnim");
                        setTimeout(() => {
                            form.classList.remove("popupCartConfirmFormWrapperAnim");
                        }, 1050);
                    }
                } else {
                    let el = document.querySelector("#popupCartWrapper");
                    el.classList.add("cartPopedDown");
                    el.classList.remove("cartPopedUp");
                }

            } else {
                let el = document.querySelector("#popupCartWrapper");
                el.classList.add("cartPopedDown");
                el.classList.remove("cartPopedUp");
            }
        }
        
    }

    render = (props) => {

        let innerProps = this.props;

        let totalPrice = 0;
        let itemsSummary = [];
        let index = 0;
        let self = this;

        this.props.items.forEach(function(item) {
            if (typeof Number.parseFloat(item.price) == 'number')
                totalPrice += Number.parseFloat(item.price);

            let price = item.price;
            if (Number.isInteger(price))
                price = price.toString();

            let link;
            if (link == undefined) link = "/track/" + item.id;
            else link = item.link;

            itemsSummary.push(
            <div key={itemsSummary.length} className="popupCartItem">
                <div className="popupCartItemLineOne">
                    <Link onClick={self.closeCart} to={link} alt="odkaz na položku">{item.bandName + " | " + item.albumName}</Link>
                </div>
                <div className="popupCartItemLineTwo">
                    <p>{price}</p>
                    <div className="float-right">
                        <button data-index={index} onClick={innerProps.deleteHandler}><img className="w-100 h-100" src="/assets/images/close_no_decor.png" alt=""/></button>                        
                    </div>
                </div>
            </div>);
            index++;
        });
        
        if (itemsSummary.length === 0)
            itemsSummary.push(<div key={itemsSummary.length} className="popupCartItem"><p className="controlCartEmptyText">Váš košík neobsahuje žádnou položku :-(</p></div>);
        
        return (
            <div onClick={this.wrapperClicked} id="popupCartWrapper" className="cartPopedDown">
                <div id="popupCart">
                    <img className="popupCartClose" src="/assets/images/close_no_decor.png" alt="tlačítko pro zavření nákupního košíku" />
                    <div className="popupCartPriceSumarry">
                        <p>CELKEM</p>
                        <p>{totalPrice.toString() + " Kč"}</p>
                    </div>
                    <div className="popupCartItemsSummary">
                        {itemsSummary}
                    </div>
                    <div className="popupCartConfirmForm">
                        <form>
                            <div id="popupCartConfirmFormWrapper">
                                <input className="uberCheckbox" type="checkbox" id="controlCartConfirm"/>
                                <div className="popupCartConfirmFormText">
                                    <Link onClick={this.closeCart} to="/Podminky">Souhlasím s obchodními podmínkami</Link>
                                </div>
                            </div>
                            <Link onClick={this.closeCart} className="cartSubmitButton" to="/Kosik" >OBJEDNAT</Link>
                        </form>
                    </div>
                </div>
            </div>
        );

    }

    

}

export default ControlCart;