import React, { Component } from 'react';

class mobileSidebarSearch extends Component {

    search = () => {
        const searchInputs = document.querySelectorAll("#sidebarComponent .mobile-menu-search input");
            let searchValue = '';
            searchInputs.forEach(el => {
                if (el.value == '') {
                    return;
                }
                if (searchValue === '') {
                    searchValue += el.name + "=" + el.value;
                } else {
                    searchValue += "&" + el.name + "=" + el.value;
                }
            });
            
            if (searchValue !== '') {
                window.location.href = "/vyhledavani/" + encodeURI(searchValue);
            }
    }

    searchInput = (e) => {
		if (e.keyCode == 13) { 
			e.preventDefault();		

			this.search();
		}
    }
    
    searchButton = (e) => {
        e.preventDefault();		

        this.search();
    }
    
    render = (props) => {
        return (
            <div className="mobile-menu mobile-menu-search">
                <img className="mobile-menu-close mobile-menu-close-img" src="/assets/images/close.svg" alt="zavření menu" />
                <div className="row w-75 m-0 pt-5 sidebarWrapper">
                    <div className="m-auto splashWrapper splashWrapperMobile">
                        <p>VYHLEDÁVÁNÍ</p>
                        <form>
                            <input className="basicInput splashSearch col-12" name="searchAll" onKeyDown={this.searchInput} type="text"></input>
                            <div className="splashFromButtons">
                                <div className="float-left bringOutRightBar mobile-bring-out-filters"><img className="w-100 h-100" src="/assets/images/filtr.svg" alt="" /></div>
                                <div className="float-right" onClick={this.searchButton}><img className="w-100 h-100" src="/assets/images/cd_w.svg" alt="" /></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

}

export default mobileSidebarSearch;