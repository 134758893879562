import React, { Component } from 'react';

import { NavbarSpacer } from '../Utils/Utils.js';
import RowSection from '../Sections/RowSection.js';
import PaymentPageForm from '../PaymentPageForm/PaymentPageForm.js';

class PaymentPage extends Component {

    render = (props) => {
        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto paymentPage container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/delivery.svg" header="DOPRAVA A PLATBA">
                        <PaymentPageForm />
                    </RowSection>
                </div>
            </div>
        );
    }

}
export default PaymentPage;