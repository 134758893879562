import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import { NavbarSpacer } from '../Utils/Utils.js';
import RowSection from '../Sections/RowSection.js';

class DeliveryTerms extends Component {

    render = (props) => {
        
        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto DeliveryTerms container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/order_r.svg" header="DODACÍ A OBCHODNÍ PODMÍNKY">
                        <div className="color-gray w-95"> 
                            <p className="termText">K nákupu v našem e-shopu je třeba se <HashLink className="redLink" to="/registrovat">zaregistrovat</HashLink>. Výhodou registrace je, že při každém dalším nákupu Vám bude stačit přihlásit se pomocí Vašeho uživatelského jména a hesla. Registrovaný zákazník také získává nárok na 5% slevu při nákupu nad 1000 Kč a 10% slevu při nákupu nad 2000 Kč.</p>
                            <br />
                            <p className="termText">Všechny Vámi poskytnuté osobní údaje (jméno, příjmení, bydliště) archivujeme v naší databázi, kde jsou chráněna před zneužitím. Ručíme za to, že Vaše údaje nebudou předávány nikomu dalšímu, kdo není přímo zainteresován na dodání zboží a slouží nám jen k interním účelům, tj. identifikace kupujícího, uskutečnění a zaúčtování platby za nákup a doručení zásilky.</p>
                            <br />                            
                            <p className="termText">Vyplněním a potvrzením registrace do Bazar CD souhlasíte se zpracováním Vašich poskytnutých osobních údajů a jejich zařazením do databáze naší firmy a s jejich naložením v souladu se zákonem 101/2000 Sb., o ochraně osobních údajů, tj. zejména o tom, že poskytnutí osobních údajů je dobrovolné, že subjekt údajů má právo k jejich přístupu. Osobní data v databázi jsou shromažďována a zpracovávána maximálně po dobu 5 let od jejich získání. Osobní údaje zákazníků jsou plně zabezpečeny proti zneužití. Subjekt údajů má právo přístupu ke svým osobním údajům a právo na jejich opravu včetně dalších zákonných práv k těmto údajům.</p>
                            <br />
                            <p className="termText">Veškeré nabízené nosiče jsou prodávány jako bazarové.Posíláme jen cd a dvd které jsou v perfektním stavu a poskytujeme na ně záruku 12 měsíců.U zboží ,objednaného pomocí prostředků komunikace na dálku-internetu , má spotřebitel právo dle § 53 odst. 7 Obč.Z. od smlouvy odstoupit do 14 dnů od dodání zboží. V takovém případě musí být odstoupení od smlouvy nejlépe spolu s vráceným zbožím v nepoškozeném stavu doručeno nejpozději čtrnáctý den od doručení zásilky. Při odstoupení od smlouvy vrátíme spotřebiteli jím zaplacené finanční prostředky nejpozději do 30 dnů od jeho odstoupení od smlouvy. Vrácení zboží nelze učinit formou dobírky.</p>
                            <br />
                            <p className="termText">Protože vetšinu titulů máme pouze po jednom kuse je třeba počítat s tím, že Vámi objednaný titul již mohl být prodán jinému zákazníkovi. Proto posíláme po obdržení Vaší objednávky email s potvrzením. Pokud by Vaše objednávka nebyla kompletní, kontaktujeme Vás telefonicky nebo emailem.</p>
                            <br />
                            <h3 className="termHeader">DOPRAVA</h3>
                            <p className="termText">Pro doručení vašich zásilek využíváme služeb České pošty.</p>
                            <br />
                            <h3 className="termHeader">OSOBNÍ ODBĚR</h3>
                            <p className="termText">Po obdržení Vaší objednávky (telefonicky, emailem) Vám zboží budeme rezervovat po dobu 1 týdne na naší prodejně. V tomto případě neplatíte dopravné.</p>
                            <br />
                            <h3 className="termHeader">ČESKÁ POŠTA</h3>
                            <p className="termText">Zásilku odešleme na Vámi uvedenou dodací adresu. Přesně Vás budeme emailem informovat o dni, kdy jsme tuto Vaši zásilku předali České poště</p>
                            <br />
                            <p className="termText">Nabízíme Vám dva způsoby doručení prostřednictvím České pošty a samozřejmě je pouze na Vás, který si vyberete.</p>
                            <br />
                            <h3 className="termHeader">OBCHODNÍ BALÍK NA DOBÍRKU</h3>
                            <ul className="termList">
                                <li className="termText">je vhodný především pro rychlou a spolehlivou přepravu zboží na území celé České republiky</li>
                                <li className="termText">pošta Vám dodá balík následující pracovní den po dni podání</li>
                                <li className="termText">má přednost před ostatními zásilkami, které jsou prostřednictvím České pošty zasílány</li>
                                <li className="termText">pokud nebudete pracovníkem pošty zastižen, zanechá Vám oznámení o uložení zásilky a zásilku uloží na poště, kde si ji budete moci do 7 kalendářních dnů vyzvednout (to je úložní doba, po kterou zůstává balík na Vaší poště než je odeslán zpět do naší firmy)</li>
                                <li className="termText">technologie této služby využívá moderní systém evidence zásilek s využitím čárového kódu a zásilky jsou sledovány výpočetní technikou od podání až k dodání, což znamená, že si kdykoli můžete na poště zjistit (buď na telefonním čísle 800 177 889 nebo na internetových stránkách České pošty www.cpost.cz), co přesně se v daném okamžiku s Vaším obchodním balíkem děje (k tomu potřebujete znát pouze číslo Vaší zásilky, které naleznete v detailu své objednávky)</li>
                                <li className="termText">je veden a přepravován v zcela samostatném režimu, tj. mimo jakékoli další zásilky, které jsou posílány prostřednictvím České pošty a s tím souvisí i mnohem šetrnější a opatrnější zacházení.</li>
                            </ul>
                            <br />
                            <p className="termHeader">Cena obchodního balíku na dobírku je 120 Kč</p>
                            <br />
                            <h3 className="termHeader">DOPORUČENÝ BALÍK NA DOBÍRKU</h3>
                            <ul className="termList">
                                <li className="termText">pošta obvykle dodává Doporučený balík ve lhůtě do 3 pracovních dnů ode dne podání (tuto lhůtu Česká pošta negarantuje na rozdíl od doby dodání u Obchodního balíku, která je popsána výše)</li>
                                <li className="termText">opět stejně jako u obchodního balíku, pokud Vás zaměstnanec pošty nezastihne, zanechá Vám oznámení o uložení zásilky a uloží ji na poště, kde si ji budete moci do 14 dnů vyzvednout (to je úložní doba, po kterou zůstává balík na Vaší poště než je odeslán zpět do naší firmy)</li>
                            </ul>
                            <br />
                            <p className="termHeader">Cena doporučeného balíku je 80 Kč.</p>
                            <br />
                            <h3 className="termHeader">ZA OBJEDNANÉ TITULY MŮŽETE ZAPLATIT TŘEMI ZPŮSOBY, KTERÉ NAVAZUJÍ NA VÁMI ZVOLENÝ ZPŮSOB DOPRAVY</h3>
                            <ul className="termList">
                                <li className="termText mt-4">Platba na dobírku</li>
                                <li className="termText mt-4">Platba v hotovosti – v případě osobního převzetí zásilky</li>
                                <li className="termText mt-4">Platba převodem – peníze nepřevádíte na náš firemní účet ve chvíli, kdy učiníte objednávku, ale až poté, kdy obdržíte email s variabilním symbolem a částkou, kterou máte poukázat na náš učet. Při této variantě je poštovné za obchodní balík 90 Kč a za doporučený balík 50 Kč. Zásilku odesíláme po obdržení platby na náš účet. V případě, že peníze nebudou připsány na náš účet do 10 dnů od uskutečnění objednávky, považujeme ji za zrušenou. Pokud by peníze dorazily později, budou v plné výši vráceny na Váš účet.</li>
                            </ul>
                            <div className="my-5 py-5"></div>
                        </div>
                    </RowSection>
                </div>
            </div>
        );

    }

}

export default DeliveryTerms