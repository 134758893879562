import React, {Component} from 'react';

class ControlUtils extends Component {

    render (props) {
        let count = this.props.itemCount;

        return (

            <div className="d-none d-lg-block ControlUtilsDown" id="utils">
                <div className="ControlImgLeft ControlUtils bringOutLeftBar">
                    <img src="/assets/images/eyeglass.svg" alt="" />
                </div>
                <div onClick={this.cartClicked} className="ControlImgRight ControlUtils">
                    <img src="/assets/images/cart.svg" alt="" />
                    <p>{count}</p>
                </div>
            </div>

        );

    }

    cartClicked () {
        let el = document.querySelector("#popupCartWrapper");
        el.classList.add("cartPopedUp");
        el.classList.remove("cartPopedDown");
    }
    

}

export default ControlUtils;