import React, { Component } from 'react';
import { useHistory } from "react-router-dom";

class splashPage extends Component {

	searchOnEnter = (e) => {
		if (e.keyCode == 13) { 
			e.preventDefault();

			const searchInputs = document.querySelectorAll("#splashPageComponent input");
			let searchValue;
			if (window.innerWidth > 990) {
				searchValue = searchInputs[0].value;
			} else {
				searchValue = searchInputs[1].value;
			}
			console.log(searchValue);
			window.location.href = "/vyhledavani/searchAll=" + encodeURI(searchValue);
		}
	}

	search = (e) => {

		const searchInputs = document.querySelectorAll("#splashPageComponent input");
		let searchValue;
		if (window.innerWidth > 990) {
			searchValue = searchInputs[0].value;
		} else {
			searchValue = searchInputs[1].value;
		}
		console.log(searchValue);
		window.location.href = "/vyhledavani/searchAll=" + encodeURI(searchValue);
		
	}

	scrollDown = (e) => {
		e.preventDefault();

		const height = window.innerHeight;

		window.scrollBy(0, height * 0.8);
	}

	render = (props) => {
		return (
			<div id="splashPageComponent">
				<div className="d-none d-lg-block row splashPage p-0 m-0">
					<video className="splashVideo" controls={false} autoPlay={true} loop={true}>
						<source src="assets\video\vinil.mp4" type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					<div className="splashVideoOverlay"></div>
					<div className="m-auto splashWrapper">
						<h1>Vítejte v CD Bazaru</h1>
						<form>
							<input className="basicInput splashSearch col-12" type="text" placeholder="Zadejte hledaný titul" onKeyDown={this.searchOnEnter}></input>
							<div className="splashFromButtons">
								<div className="float-left bringOutFilterBar bringOutLeftBar bringOutRightBar mobile-bring-out-filters"><img className="w-100 h-100" src="/assets/images/filtr.svg" alt="" /></div>
								<div className="float-right"><img className="w-100 h-100" src="/assets/images/cd_w.svg" alt="" onClick={this.search}/></div>
							</div>
						</form>
					</div>
					<div className="spashArrow" onClick={this.scrollDown}><img src="/assets/images/arrow_w.svg" /></div>
				</div>
				<div className="d-block d-lg-none splashPage">
					<video id="splashPageVideo" className="splashVideoMobile" muted={true} controls={false} autoPlay={true} loop={true}>
						<source src="assets\video\vinil.mp4" type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					<div className="splashVideoOverlay mobile"></div>
					<div className="m-auto splashWrapper splashWrapperMobile">
						<h1>Vítejte v CD Bazaru</h1>
						<form>
							<input className="basicInput splashSearch col-12" type="text" placeholder="Zadejte hledaný titul" onKeyDown={this.searchOnEnter}></input>
							<div className="splashFromButtons">
								<div className="float-left bringOutFilterBar mobile-bring-out-filters"><img className="w-100 h-100" src="/assets/images/filtr.svg" alt="" /></div>
								<div className="float-right"><img className="w-100 h-100" src="/assets/images/cd_w.svg" alt="" onClick={this.search}/></div>
							</div>
						</form>
					</div>
					<div className="spashArrow" onClick={this.scrollDown}><img src="/assets/images/arrow_w.svg" /></div>
				</div>
			</div>
		);
	}

}

export default splashPage;
