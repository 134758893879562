import React from 'react';
import { Link } from 'react-router-dom';
import App from '../../App';
import ApiComponent from '../ApiComponent/ApiComponent';
import { LoadingOverlay, Popup } from '../Utils/Utils';

class Profile extends ApiComponent {

	logout = () => {
		//window.localStorage.setItem("loggedInUser", "{}");
		App.SetLocalStorageUser(null)
		App.SetLocalStorageUserData(null);
		App.SetLocalStorageCart(null);
		window.location.href = "/";
	}

	fillFormData = () => {
        try {
			const localUser = App.LoadLocalStorageUser();

			if (Object.keys(localUser).length == 0) {
				window.location.href = "/";
			}
			 
            document.querySelector("input[name='residenceaddress_id.email']").value = localUser.EMail;
            document.querySelector("input[name='X_FirstName']").value = localUser.X_FirstName;
            document.querySelector("input[name='X_LastName']").value = localUser.X_LastName;
			document.querySelector("input[name='residenceaddress_id.PhoneNumber1']").value = localUser.PhoneNumber1;
			
			const apiResponse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/receivedorders/query", {
				"select": [
					"Amount"
				],
				"where": [
					"Firm_ID eq '" + localUser.ID + "'"
				]
			});

			apiResponse.then(res => {
				let totalSpentAmount = 0;

				res.data.forEach(element => {
					totalSpentAmount += element.Amount;
				});

				totalSpentAmount = totalSpentAmount.toString().match(/.{3}/g).join(' ');

				document.querySelector(".cartItemControlsWrapper > .cartItemControlsPrice.color-red").innerHTML = totalSpentAmount + " Kč";
			});
            
        } catch (error) {
            //User not logged in...probably
        }
	}

	saveProfileData = (e) => {
		const user = App.LoadLocalStorageUser();
		if (Object.keys(user).length === 0) {
			Popup.DisplayMessage("Špatně přihlášený uživatel");
			return;
		}

		const userData = App.LoadLocalStorageUserData();
		if (Object.keys(userData).length === 0) {
			Popup.DisplayMessage("Špatně přihlášený uživatel");
			return;
		}

		//ani se nepokousim o zmenu hesla (auth) z duvodu bezpecnosti
		let newFirmsData = {};
		let userChangedFirmsData = false;

		const firstName = document.querySelector("input[name='X_FirstName']").value;
		if (firstName != user.X_FirstName) {
			newFirmsData.X_FirstName = firstName
			userChangedFirmsData = true;
		}

		const lastName = document.querySelector("input[name='X_LastName']").value;
		if (lastName != user.X_LastName) {
			newFirmsData.X_LastName = lastName
			userChangedFirmsData = true;
		}

		let newProfileData = {};
		let userChangedProfileData = false;

		const PhoneNumber1 = document.querySelector("input[name='residenceaddress_id.PhoneNumber1']").value;
		if (PhoneNumber1 != user.PhoneNumber1) {
			newProfileData.PhoneNumber1 = PhoneNumber1;
			userChangedProfileData = true;
		}

		if (!userChangedFirmsData && !userChangedProfileData) {
			return;
		} else {
			LoadingOverlay.ApplyOverlay();
		}

		let firmsResponse;
		let ProfileResponse;
		if (userChangedFirmsData) {
			firmsResponse = this.putDataFromEndpoint("https://api.abra.slox.cz/BazarCD/firms/" + user.ID + "?select=id", newFirmsData);
		}
		if (userChangedProfileData) {
			ProfileResponse = this.putDataFromEndpoint("https://api.abra.slox.cz/BazarCD/addresses/" + userData.residenceaddress_id + "?select=id", newProfileData);
		}

		Promise.all([firmsResponse, ProfileResponse])
			.then(() => {
				Promise.all([
					this.saveUserFromApi(user.ID),
					this.saveUserDataFromApi(user.ID)
				]).then(() => {
					this.fillFormData();
					LoadingOverlay.RemoveOverlay();
					Popup.DisplayMessage("Informace úspěšně změněny");
				});
			});

	}

	componentDidMount = () => {
		this.fillFormData();
	}

	render = (props) => {

		return (
			<div className="cartItems">
				<div className="cartCrumbs Profile">
					<Link to="/profil" ><div className="cartCrumb color-red"><img src="/assets/images/user.svg" alt=""/><span>OSOBNÍ ÚDAJE</span></div></Link>
					<Link to="/profil/dodaci-udaje" ><div className="cartCrumb"><img className="icon-black" src="/assets/images/adress_b.svg" alt=""/><span>DODACÍ ÚDAJE</span></div></Link>
					<Link to="/profil/veverka" ><div className="cartCrumb"><img src="/assets/images/veverka_b.svg" alt=""/><span>HLÍDACÍ VEVERKA</span></div></Link>
					<Link to="/profil/objednavky" ><div className="cartCrumb noAfter"><img src="/assets/images/objednavka_b.svg" alt=""/><span>MOJE OBJEDNÁVKY</span></div></Link>
				</div>
				<div className="col-12 homeTileDivider"></div>
				
				<div className="cartItem">
					<div className="cartItemImageWrapper">
						<img alt="" className="cartItemImage" src="/assets/images/cd_2.svg"/>
					</div>
					<div className="cartItemDescWrapper">
						<h2 className="cartItemDescHeader">{this.props.user.X_FirstName + " " + this.props.user.X_LastName}</h2>
						<p className="cartItemDescText color-black"></p>
						<p className="cartItemDescCode">CELKEM UTRACENO V CD BAZARU</p>
						<div className="cartItemMobileImageWrapper">
							<img alt="" className="cartItemImage" src="/assets/images/cd_2.svg"/>
						</div>
					</div>
					<div className="cartItemControlsWrapper">
						<div className="cartItemControlsPrice color-red">0 Kč</div>
					</div>
				</div>
				<div className="col-12 homeTileDivider"></div>
				
				<div className="registerForm w-100 col-12 p-0 m-0 row">

					<div className="col-12 pl-0 mb-4 vertical-center"><img className="d-inline" src="/assets/images/user.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> LOGIN, JMÉNO & HESLO</p></div>
					<input inputMode="text" name="residenceaddress_id.email" placeholder="EMAIL" className="basicInput registerInput color-black placeholder-black"></input>
					<input inputMode="text" name="X_FirstName" placeholder="JMÉNO" className="basicInput registerInput color-black placeholder-black"></input>
					<input inputMode="text" name="X_LastName" placeholder="PŘIJMENÍ" className="basicInput registerInput color-black placeholder-black"></input>
					<input inputMode="text" name="password" placeholder="HESLO" className="basicInput registerInput color-black placeholder-black"></input>
					<input inputMode="text" name="confirmPassword" placeholder="HESLO ZNOVU" className="basicInput registerInput color-black placeholder-black"></input>
					<input inputMode="text" name="residenceaddress_id.PhoneNumber1" placeholder="TELEFON" className="basicInput registerInput color-black placeholder-black"></input>

				</div>

				<div className="col-12 homeTileDivider"></div>


				<div className="col-12 p-0 d-flex orderPageButtonWrapper">
					<Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt=""/>POKRAČOVAT V NÁKUPU</div></button></Link>
					<Link to="/profil/dodaci-udaje"><button className="registerFormButtonSave vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/sent-mail.svg" width="20" height="20" alt=""/>POKRAČOVAT NA DODACÍ ÚDAJE</div></button></Link>
					<button onClick={this.saveProfileData} className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0">ULOŽIT ÚDAJE</div></button>
				</div>

				<div className="col-12 mt-4 p-0 d-flex orderPageButtonWrapper">
					<button onClick={this.logout} className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0">ODHLÁSIT</div></button>
				</div>

				<div className="my-5 py-5"></div>
			</div>
		);

	}

}

export default Profile;