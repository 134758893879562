import LoginFrom from '../HomeLoginForm/HomeLoginForm.js';
import React, { Component } from 'react';
import { NavbarSpacer } from '../Utils/Utils.js';


class LoginPage extends Component {

    render = (props) => {

        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto servicePage miniPage container">
                    <NavbarSpacer />
                    <LoginFrom loginFunction={this.props.loginFunction}/>
                </div>
            </div>
        );

    }

}

export default LoginPage;