import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import App from '../../App';
import ApiComponent from '../ApiComponent/ApiComponent';
import { LoadingOverlay } from '../Utils/Utils';

class ProfileOrders extends ApiComponent {

    constructor (props){
        super(props);
      
        this.state = {
            orders : [
                /*{id: 10887898797, date: "23. 10. 2018", price: 2310},
                {id: 10887898797, date: "23. 10. 2018", price: 2310},
                {id: 10887898797, date: "23. 10. 2018", price: 2310},
                {id: 10887898797, date: "23. 10. 2018", price: 2310},
                {id: 10887898797, date: "23. 10. 2018", price: 2310}*/
            ]
        }
    }

    getOrders = () => {

        const userId = App.LoadLocalStorageUser().ID;

        LoadingOverlay.ApplyOverlay();
        const response = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/receivedorders/query", {
            "select": [
                "Amount",
                "ID",
                "displayname",
                "CreatedAt$DATE"
            ],
            "where": [
                "Firm_ID eq '" + userId + "'"
            ]
        });

        response.then((res) => {
            LoadingOverlay.RemoveOverlay();
            let newState = {orders:[]};

            res.data.forEach(order => {
                let day = order["CreatedAt$DATE"].substring(8, 10);
                if (day.startsWith("0")) {
                    day = day[1];
                }
                let month = order["CreatedAt$DATE"].substring(5, 7);
                if (month.startsWith("0")) {
                    month = month[1];
                }
                let year = order["CreatedAt$DATE"].substring(0, 4)
                let date = day + ". " + month + ". " + year;
                newState.orders.push({price: order.Amount, displayname: order.displayname, id: order.ID, date: date});
            });
            
            this.setState(newState);
        })
    }

    componentDidMount() {
        this.getOrders();
    }

    render = () => {
        let totalPrice = 0;
        let itemsSummary = [];
        let i = 0;
        
        if (this.state.orders.length > 0) {
            this.state.orders.forEach(function(item) {

                totalPrice += parseInt(item.price);
                let price = item.price;
                if (Number.isInteger(price))
                    price = price.toString();

                itemsSummary.push(

                <div key={i} className="cartItem">
                    <div className="cartItemImageWrapper">
                        <img alt="" className="cartItemImage" src="/assets/images/cd_2.svg"/>
                    </div>
                    <div className="cartItemDescWrapper">
                        <Link to={"/objednavka/" + item.id}><h2 className="cartItemDescHeader">{item.displayname}</h2></Link>
                        <Link to={"/objednavka/" + item.id}><p className="cartItemDescText color-black"><img width="16" height="17" src="/assets/images/cd-basket_b.svg" alt="" /> ZOBRAZIT POLOŽKY OBJEDNÁVKY</p></Link>
                        <p className="cartItemDescCode color-red"><img width="16" height="17" src="/assets/images/suma.svg" alt="" /> CELKOVÁ CENA</p>
                        <div className="cartItemMobileImageWrapper">
                            <img alt="" className="cartItemImage" src="/assets/images/cd_2.svg" />
                        </div>
                    </div>
                    <div className="cartItemControlsWrapper">
                        <div className="cartItemControlsClose color-black">{item.date}</div>
                        <div className="cartItemControlsPrice color-red">{item.price} Kč</div>
                    </div>
                </div>);
                itemsSummary.push(<div key={i + 1000000} className="col-12 homeTileDivider"></div>);

                i++;
            }.bind(this));
        } else {
            itemsSummary.push(<div key={1} className="cartItem">
                <p className="standardFont w-100 my-5 py-5">Nemáte zatím žádné objednávky</p>
            </div>);
            itemsSummary.push(<div key={2} className="col-12 homeTileDivider"></div>);
        }


        return (
            <div className="cartItems">
                <div className="cartCrumbs Profile">
                    <Link to="/profil" ><div className="cartCrumb"><img className="icon-black" src="/assets/images/user.svg" alt=""/><span>OSOBNÍ ÚDAJE</span></div></Link>
                    <Link to="/profil/dodaci-udaje" ><div className="cartCrumb"><img className="icon-black" src="/assets/images/adress_b.svg" alt=""/><span>DODACÍ ÚDAJE</span></div></Link>
                    <Link to="/profil/veverka" ><div className="cartCrumb"><img src="/assets/images/veverka_b.svg" alt=""/><span>HLÍDACÍ VEVERKA</span></div></Link>
                    <Link to="/profil/objednavky" ><div className="cartCrumb noAfter color-red"><img className="icon-red" src="/assets/images/objednavka_b.svg" alt=""/><span>MOJE OBJEDNÁVKY</span></div></Link>
                </div>
                <div className="col-12 homeTileDivider"></div>
                {itemsSummary}

                <div className="cartItem cartPriceSummary">
                    <div className="cartItemImageWrapper mobile">
                        <img alt="" className="cartItemImage noShadow revealImage" src="/assets/images/calculator.svg"/>
                    </div>
                    <div className="cartItemDescWrapper">
                        
                    </div>
                    <div className="cartItemControlsWrapper">
                        <p className="cartItemControlsPrice color-red">{totalPrice} Kč</p>
                    </div>
                </div>
                
                <div className="col-12 homeTileDivider"></div>

                <div className="col-12 p-0 d-flex orderPageButtonWrapper">
                    <Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt=""/>POKRAČOVAT V NÁKUPU</div></button></Link>
                </div>

                <div className="my-5 py-5"></div>
            </div>
        );
    }
    

}

export default ProfileOrders;