import React, { Component } from 'react';

class navbarAnimations extends Component {

    componentDidMount () {
        document.querySelector("#navbar").style.backgroundColor = "transparent";
        this.innerNavbarBG();
        this.navbarBG();
    }

    componentWillUnmount () {
        this.revertNavbarBG();
        window.removeEventListener('scroll', this.innerNavbarBG);
    }

    innerNavbarBG () {
        if(window.pageYOffset <= 250)
        {
            document.querySelectorAll(".navbarBG").forEach(element => {
                element.classList.remove("extend");
            });
        } else {
            document.querySelectorAll(".navbarBG").forEach(element => {
                element.classList.add("extend");
            });
        } 
        
    }

    revertNavbarBG () {
        document.querySelector("#navbar").style.backgroundColor = "black"; 
    }

    navbarBG () {
        
        window.addEventListener('scroll', this.innerNavbarBG);

    }


    render () {
        return <div></div>
    }

}

export default navbarAnimations;