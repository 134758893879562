import React from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const mobileSidebarMenu = () => {

    return (

        <div className="mobile-menu mobile-menu-menu row w-100 m-0 pt-5">
            <img className="mobile-menu-close mobile-menu-close-img" src="/assets/images/close.svg" alt="zavření menu" />
            <HashLink to="/sluzby"><img className="col-6 mobile-menu-close" src="/assets/images/sluzby_mobile.png" alt="Služby"/></HashLink>
            <Link to="/zkracene/0/10"><img className="col-6 mobile-menu-close" src="/assets/images/shopzkracene_mobile.png" alt="E-Shop Zkráceně"/></Link>
            <HashLink to="/novinky"><img className="col-6 mobile-menu-close" src="/assets/images/novinky_mobile.png" alt="Novinky"/></HashLink>
            <HashLink to="/veverka"><img className="col-6 mobile-menu-close" src="/assets/images/veverka_mobile.png" alt="Hlídací Veverka"/></HashLink>
            <Link to="/Podminky"><img className="col-6 mobile-menu-close" src="/assets/images/podminky_mobile.png" alt="Dodací Podmínky"/></Link>
            <Link to="/kontakt"><img className="col-6 mobile-menu-close" src="/assets/images/kontakt_mobile.png" alt="Kontakt"/></Link>
        </div>

    );

}

export default mobileSidebarMenu;