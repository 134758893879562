import { Component } from 'react';
import $ from 'jquery';

class HomePageAnimations extends Component {
    _isMounted = false;

    componentDidMount () {
        this.slideUp(".newsText");
        this.closeButton();
        this.revealSectionIcons();
        this.utilsSlideDown();
    }

    componentWillUnmount () {
        this._isMounted = false;
        this.unbindEvents();
    }


    innerNewsTextScroll = (item) => {
        let top_of_element = $(item).offset().top;
        let bottom_of_element = $(item).offset().top + $(item).outerHeight();
        let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        let top_of_screen = $(window).scrollTop() + 150;
    
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
            $(item).get(0).classList.add("popedUp");
            $(item).get(0).classList.remove("popedDown");
        } else {                    
            $(item).get(0).classList.add("popedDown");
            $(item).get(0).classList.remove("popedUp");
        }
    }

    slideUp = (selector = ".newsText") => {

        $(selector).get().forEach(item => {
            window.addEventListener("scroll", this.innerNewsTextScroll.bind(null, item));
        });

    }

    closeButton = () => {
        document.querySelectorAll(".collapseButton").forEach(element => {element.addEventListener("click", (event) => {
            let el;

            if (event.srcElement.nodeName === "IMG") {
                el = event.srcElement.parentElement;
            } else {
                el = event.srcElement;
            }

            let id = el.dataset.target;
            
            let target = document.querySelector("[id='" + id + "']");

            if (target.classList.contains("collapsed")) {
                target.classList.remove("collapsed");
                el.children[0].src = "/assets/images/close.svg";
            } else {
                target.classList.add("collapsed");
                el.children[0].src = "/assets/images/open.svg";
            }
            
        });
    });
    }

    innerCloseSidebars = () => {
        document.querySelector("#sidebar").classList.remove("leftOut");
        document.querySelector("#sidebar").classList.remove("rightOut");
        document.querySelector("#main").classList.remove("ml-365");
        document.querySelector("#main").classList.remove("mr-365");
        document.querySelector(".ControlImgLeft").classList.remove("ml-365");
        document.querySelector(".ControlImgRight").classList.remove("mr-365");
        document.querySelector("#navbar").classList.remove("ml-365");
        document.querySelector("#navbar").classList.remove("mr-365");
    }

    innerLeftBar = (e) => {
        e.preventDefault();
        document.querySelector("#main").classList.add("ml-365");
        document.querySelector("#sidebar").classList.add("leftOut");
        document.querySelector(".ControlImgLeft").classList.add("ml-365");
        document.querySelector("#navbar").classList.add("ml-365");
    }

    innerRightBar = (e) => {
        e.preventDefault();
        document.querySelector("#main").classList.add("mr-365");
        document.querySelector("#sidebar").classList.add("rightOut");
        document.querySelector(".ControlImgRight").classList.add("mr-365");
        document.querySelector("#navbar").classList.add("mr-365");
    }

    innerCloseMobileSidebar = () => {
        document.querySelector(".mobile-sidebar").classList.remove("mobile-sidebar-out");
    }

    innerMobileSidebar = (e) => {
        e.preventDefault();
        document.querySelector(".mobile-sidebar").classList.add("mobile-sidebar-out");
        document.querySelector(".mobile-menu").style.display = "block";
    }

    bringOutSidebar = () => {
        let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        if (w >= 1117) {
        

            document.querySelector(".middleBar").addEventListener("click", this.innerCloseSidebars);

            document.querySelectorAll(".bringOutLeftBar").forEach(item => {
                item.addEventListener("click", this.innerLeftBar) ;
            });
            
            document.querySelectorAll(".bringOutRightBar").forEach(item => {
                item.addEventListener("click", this.innerRightBar);
            });

        } else {

            document.querySelector(".mobile-menu-close").addEventListener("click", this.innerCloseMobileSidebar);

            document.querySelectorAll(".mobile-bring-out-filters").forEach(item => {
                item.addEventListener("click", this.innerMobileSidebar);
            });
                
        }

    }

    correctImageAspects = () => {
        document.querySelectorAll(".newsImage").forEach((item) => {
            if (item.offsetWidth !== item.offsetHeight) item.style.height = (item.offsetWidth + "px");
        });
    }

    innerRevealIcons = (item) => {
        let top_of_element = $(item).offset().top;
        let bottom_of_element = $(item).offset().top + $(item).outerHeight();
        let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        let top_of_screen = $(window).scrollTop() + 150;
    
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
            item.classList.remove("hidden");
        }
    }

    revealSectionIcons = () => {
        document.querySelectorAll(".revealImage.hidden").forEach(item => {
            window.addEventListener("scroll", this.innerRevealIcons.bind(null, item));
        });
    }

    innerUtilsDown () {
        let ut = document.querySelector("#utils");
        if(window.pageYOffset <= 200) 
        {
                ut.classList.add("ControlUtilsUp");
                ut.classList.remove("ControlUtilsDown");
            } else {                    
                ut.classList.add("ControlUtilsDown");
                ut.classList.remove("ControlUtilsUp");
        }
    }

    utilsSlideDown () {
        window.addEventListener("scroll", this.innerUtilsDown);
        this.innerUtilsDown();
    }

    unbindEvents = () => {
        window.removeEventListener("scroll", this.innerUtilsDown);
        let ut = document.querySelector("#utils");
        ut.classList.add("ControlUtilsDown");
        ut.classList.remove("ControlUtilsUp");
    }


    render = () => {
        return null
    }

}

export default HomePageAnimations;