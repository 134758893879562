import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ImageComponent from '../ImageComponent/ImageComponent';

class Order extends Component {

    state = {
        items : []
    }

    render = (props) => {

        //let totalPrice = 0;
        let itemsSummary = [];
        let i = 0;
        const length = Object.keys(this.props.items).length;
        this.props.items.forEach(function(item) {
            //totalPrice += parseInt(item.price);
            let price = item.price;
            if (Number.isInteger(price))
                price = price.toString();
                
            itemsSummary.push(

            <div key={i} className="cartItem">
                <div className="cartItemImageWrapper">
                    <ImageComponent alt="" className="cartItemImage" id={item.id} />
                </div>
                <div className="cartItemDescWrapper">
                    <Link to={"/track/" + item.id}><h2 className="cartItemDescHeader">{item.bandName}</h2></Link>
                    <p className="cartItemDescText">{item.albumName}</p>
                    <p className="cartItemDescCode">{item.code}</p>
                    <div className="cartItemMobileImageWrapper">
                        <ImageComponent alt="" className="cartItemImage" id={item.id} />
                    </div>
                </div>
                <div className="cartItemControlsWrapper">
                    <div className="cartItemControlsPrice color-red">{item.price} Kč</div>
                </div>
            </div>);
    
            if (i + 1 != length) {
                itemsSummary.push(<div key={i + 1000000} className="col-12 homeTileDivider"></div>);
            }

            i++;
        });


        return (
            <div className="cartItems">
                <div className="col-12 homeTileDivider"></div>
                <div className="col-12 homeTileDivider"></div>
                {itemsSummary}
                <div className="col-12 homeTileDivider fat"></div>
                <div className="cartItem cartPriceSummary">
                    <div className="cartItemImageWrapper mobile">
                        <img alt="" className="cartItemImage noShadow revealImage" src="/assets/images/calculator.svg"/>
                    </div>
                    <div className="cartItemDescWrapper">
                        
                    </div>
                    <div className="cartItemControlsWrapper">
                        <p className="cartItemControlsPrice color-red">{this.props.totalPrice} Kč</p>
                    </div>
                </div>
                
                <div className="col-12 homeTileDivider"></div>

                <div className="col-12 p-0 d-flex orderPageButtonWrapper">
                    <Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt=""/>POKRAČOVAT V NÁKUPU</div></button></Link>
                </div>

                <div className="my-5 py-5"></div>
            </div>
        );

    }

    componentDidMount = () => {
        //this.setState({items: this.props.items});
    }

}

export default Order;