import React, { Component } from 'react';
import { localStorageManager } from '../Utils/Utils';

class mobileSidebarFilter extends Component {

    searchValues = {};

    constructor (props) {
        super(props);

        this.searchValues = localStorageManager.GetLocalStorageItem("searchValues", {});
    }

    search = () => {
        const searchInputs = document.querySelectorAll("#sidebarComponent .mobile-menu-filter input");
            let searchValue = '';
            let values = {};
            searchInputs.forEach(el => {
                values[el.name] = el.value;
                if (el.value == '') {
                    return;
                }
                if (searchValue === '') {
                    searchValue += el.name + "=" + el.value;
                } else {
                    searchValue += "&" + el.name + "=" + el.value;
                }
            });

            if (searchValue !== '') {
                localStorageManager.SetLocalStorageItem("searchValues", values);
                window.location.href = "/vyhledavani/" + encodeURI(searchValue);
            }
    }

    searchInput = (e) => {
		if (e.keyCode == 13) { 
			e.preventDefault();		

			this.search();
		}
    }
    
    searchButton = (e) => {
        e.preventDefault();		

        this.search();
    }
    
    render = (props) => {
        return (
            <div className="mobile-menu mobile-menu-filter row w-75 m-0 pt-5">
                <img className="mobile-menu-close mobile-menu-close-img" src="/assets/images/close.svg" alt="zavření menu" />
                <div className="w-100 my-4"></div>
                <div className="sidebarWrapper">
                    <p>ROZŠÍŘENÉ VYHLEDÁVÁNÍ</p>
                    <form>
                        <input placeholder="INTERPRET" name="X_Interpret_ID.FullName" className="basicInput splashSearch col-12" type="text" defaultValue={this.searchValues["X_Interpret_ID.FullName"]} onKeyDown={this.searchInput}></input>
                        <input placeholder="TITUL" name="displayname" className="basicInput splashSearch col-12" type="text" defaultValue={this.searchValues["displayname"]} onKeyDown={this.searchInput}></input>
                        <input placeholder="ROK VYDÁNÍ" name="X_DateOfPublication" className="basicInput splashSearch col-12" type="text" defaultValue={this.searchValues["X_DateOfPublication"]} onKeyDown={this.searchInput}></input>
                        <input placeholder="NOSIČ" name="StoreMenuItem_ID.Text" className="basicInput splashSearch col-12" type="text" defaultValue={this.searchValues["StoreMenuItem_ID.Text"]} onKeyDown={this.searchInput}></input>
                        <input placeholder="CENA DO" name="X_Price" className="basicInput splashSearch col-12" type="number" defaultValue={this.searchValues["X_Price"]} onKeyDown={this.searchInput}></input>
                        <button className="squirrelFormSubmit" type="submit" onClick={this.searchButton}><img src="/assets/images/tick-inside-circle_black.svg" alt="submit button" /></button>
                    </form>
                </div>
            </div>
        )
    }

}

export default mobileSidebarFilter;