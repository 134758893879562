import React, { Component } from 'react';
import TileSection from '../Sections/TileSection.js';
import { NavbarSpacer, ScreenSpacer } from '../Utils/Utils.js';


class NewsPage extends Component {

    render (props) {
        return (
        <div>
          <div className="row w-100 p-0 m-0">
            <div className="HomePageSection miniPage">
              <NavbarSpacer />
              <TileSection icon="/assets/images/infoooo.svg" hideIcon={false} header="AKTUALITY">
                {this.props.newsTiles}
              </TileSection>
            </div>
          </div>
          <ScreenSpacer />
        </div>
        );
    }
}

export default NewsPage;