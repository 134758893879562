import React, { Component } from 'react';

class TripleSection extends Component  {

    render (props) {

        let OneRow = [];
        let TwoRow = [];
        let ThreeRow = [];
        let tmpNews = [];
        let newChildren = [];

        if (this.props.children !== null && this.props.children !== undefined) {
            if (this.props.children.length > 0) {
                newChildren = this.props.children;
            }
        }

        newChildren.forEach((item, key) => {
            
            item = React.cloneElement(
                item,
                {index: key + new Date().getTime()}
            );

            if ((key + 1) % 3 === 0) {
                tmpNews.push(item);
                tmpNews.push(<div className="col-12 homeTileDivider" key={key + 20000}></div>);
                ThreeRow.push(<div className="col-12 row p-0 mx-auto tileRow" key={key + 120000}>{tmpNews}</div>);

                tmpNews = [];
            } else {
                tmpNews.push(item);
                tmpNews.push(<div className="homeTileDivider mobile-block" key={key + 50000}></div>);
                if (this.props.children[key + 1] == null) {
                    tmpNews.push(<div className="homeTileDivider" key={key + 60000}></div>);
                    ThreeRow.push(<div className="col-12 row SpaceAround" key={key + 10000}>{tmpNews}</div>);
                } else {
                    tmpNews.push(<div className="homeTileVerticalDivider" key={key + 30000}></div>);
                }
            }

        });

        tmpNews = [];

        newChildren.forEach((item, key) => {
            
            item = React.cloneElement(
                item,
                {index: key + new Date().getTime()}
            );

            /*if ((key + 1) % 2 === 0) {
                tmpNews.push(item);
                tmpNews.push(<div className="col-12 homeTileDivider" key={key + 21000}></div>);
                TwoRow.push(<div className="col-12 row p-0 mx-auto tileRow" key={key + 11000}>{tmpNews}</div>);

                tmpNews = [];
            } else {
                tmpNews.push(item);
                tmpNews.push(<div className="homeTileDivider mobile-block" key={key + 51000}></div>);
                if (this.props.children[key + 1] == null) {
                    TwoRow.push(<div className="col-12 row SpaceAround" key={key + 11000}>{tmpNews}</div>);
                } else {
                    tmpNews.push(<div className="homeTileVerticalDivider" key={key + 31000}></div>);
                }
            }*/
            if ((key + 1) % 2 === 0) {
                tmpNews.push(item);
                tmpNews.push(<div className="col-12 homeTileDivider" key={key + 21000 + new Date().getTime()}></div>);
                TwoRow.push(<div className="col-12 row p-0 mx-auto tileRow" key={key + 11000 + new Date().getTime()}>{tmpNews}</div>);

                tmpNews = [];
            } else {
                tmpNews.push(item);
                tmpNews.push(<div className="homeTileDivider mobile-block" key={key + 51000}></div>);
                if (this.props.children[key + 1] == null) {
                    //tmpNews.push(<div className="homeTileVerticalDivider" key={key + 31000}></div>);
                    //tmpNews.push(<div className="col-4 m-auto newsTile" key={key + 41000}></div>);
                    tmpNews.push(<div className="col-12 homeTileDivider" key={key + 21000}></div>);
                    
                    TwoRow.push(<div className="col-12 row SpaceAround" key={key + 11000}>{tmpNews}</div>);
                    
                    tmpNews = [];
                } else {
                    tmpNews.push(<div className="homeTileVerticalDivider" key={key + 31000}></div>);
                }
            }

        });

        newChildren.forEach((item, key) => {
            
            item = React.cloneElement(
                item,
                {index: key + new Date().getTime()}
            );

            tmpNews = [];
            
            tmpNews.push(item);
            tmpNews.push(<div className="homeTileDivider" key={key + 52000}></div>);
            //tmpNews.push(<div className="homeTileVerticalDivider" key={key + 32000}></div>);
            OneRow.push(<div className="col-12 row SpaceAround" key={key + 11000}>{tmpNews}</div>);

        });

        let icon;

        if (!this.props.hideIcon) {
            icon = <img className="tileSectionImage float-left" src={this.props.icon} alt=""/>;
        } else {
            icon = <img className="tileSectionImage revealImage float-left hidden" src={this.props.icon} alt=""/>;
        }

        let ret;

        if ((JSON.stringify(this.props.children) === JSON.stringify([])) || (this.props.children === undefined)) {
            if (this.props.emptyMessage === "" || this.props.emptyMessage === undefined) {
                return <div></div>;
            }
            
            ret = (
                <div className="row TripleSection mx-auto">
                    <div className="tileSectionTitle tileSectionWidth">
                        <div>
                            {icon}
                            <h2>| {this.props.header}</h2>
                        </div>
                    </div>
                    <div className="tileSectionWidth" />
                    <div className="col-1 col-lg-5 mr-auto tileSectionTitle"></div>
                    <div className="collapsable row w-100">
                        <div className="col-12 homeSectionEmpty">
                            <h2 className="text-center mt-5 mb-2">{this.props.emptyMessage}</h2>
                        </div>
                        <div className="col-12 homeTileDivider"></div>
                    </div>                
                </div>
            );
        } else {
            ret = (
                <div className="row TripleSection mx-auto">
                    <div className="row TripleSectionTitleWrapper">
                        <div className="TripleSectionTitle tileSectionWidth">
                            <div>
                                {icon}
                                <h2 className="noMobile">| VÝSLEDKY VYHLEDÁVÁNÍ | NALEZENO <span className="red">{this.props.resultCount}</span> TITULŮ</h2>
                                <h2 className="onlyMobile">| NALEZENO <span className="red">{this.props.resultCount}</span> TITULŮ</h2>
                            </div>
                        </div>
                        <div className="tileSectionWidth">
                            <div className="closeButtonWrapper">
                                
                            </div>
                        </div>
                    </div>
                    <div className="ThreeRow collapsable row mx-auto w-100">
                        {ThreeRow}
                    </div>
                    <div className="TwoRow row mx-auto w-100">
                        {TwoRow}
                    </div>
                    <div className="OneRow row mx-auto w-100">
                        {OneRow}
                    </div>
                </div>
            );
        }

        return ret;

    }

}

export default TripleSection;