import React from 'react';
import $ from 'jquery';

import ApiComponent from '../ApiComponent/ApiComponent.js';

import CDTile from '../Tiles/CDTile.js';
import SplashPage from '../SplashPage/SplashPage.js';
import TileSection from '../Sections/TileSection.js';
import BlackSection from '../Sections/BlackSection.js';
import SquirelForm from '../SquirelForm/SquirelForm.js';
import ServiceForm from '../ServiceForm/ServiceForm.js';
import LoginFrom from '../HomeLoginForm/HomeLoginForm.js';

import HomePageAnimations from '../Animations/HomePageAnimations.js';
import NavbarBG from '../Animations/NavbarAnimations.js';

class HomePage extends ApiComponent {

    constructor(props) {
      super(props);
      this.addToCart = this.props.addToCart.bind(this);

      this.state = {
        cdTiles : []
      }

    }

    getItemIcon (iconString) {
      switch (iconString) {
        case "CD":
          return "cd-ico.svg"
        default:
          return "dvd-ico.svg";
      }
    }

    componentDidMount() {

      //because react cant into muted attribute...
      //this is needed because otherwise autoplay doesnt work on modern browsers
      //welp, it doesnt work despite this, oh well
      document.querySelector("#splashPageVideo").setAttribute("muted", true);

      this.slideUp(".newsText");
      this.closeButton();
      this.revealSectionIcons();
      this.utilsSlideDown();

      if (this.state.cdTiles.length == 0) {
        const apiResponse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/query", {
            "class": "storecards",
            "select": ["id", "code", "name",
            "StoreMenuItem_ID.Text",
            "X_Interpret_ID.FullName",
            "X_DateOfPublication",
            "X_Price",
            "Picture_ID"
            ],
            "where": ["Hidden eq false", "X_PublishEshop eq 'A'"],
            "take": 6
          }
        );

        apiResponse.then((res) => {

          let data = res.data;
          let cdTiles = [];

          data.forEach((el, index) => {
              let yearData = "";

              if (el.X_DateOfPublication) {
                  yearData = el.X_DateOfPublication.substring(0, 4);
              }

              cdTiles.push(<CDTile key={index} id={el.id} addHandler={this.props.addToCart} link={"/track/" + el.id} bandName={el["X_Interpret_ID.FullName"]} discIcon={"/assets/images/" + this.getItemIcon(el["StoreMenuItem_ID.Text"])} albumName={el.name} year={yearData} price={el.X_Price + " Kč"} imgSrc={el.Picture_ID}></CDTile>);
          });

          this.setState({
            cdTiles : cdTiles},
            () => {
              this.unbindEvents();
              this.slideUp(".newsText");
              this.closeButton();
              this.revealSectionIcons();
              this.utilsSlideDown();
            });

        });
      }
      
      document.querySelector("#splashPageVideo").addEventListener('loadeddata', function() {
        document.querySelector("#splashPageVideo").play();
     }, false);

     this.props.controlsBinding();
      
    }

    componentWillUnmount () {
      this.unbindEvents();
  }

    innerNewsTextScroll = (item) => {
      let top_of_element = $(item).offset().top;
      let bottom_of_element = $(item).offset().top + $(item).outerHeight();
      let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
      let top_of_screen = $(window).scrollTop() + 150;
  
      if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
          $(item).get(0).classList.add("popedUp");
          $(item).get(0).classList.remove("popedDown");
      } else {                    
          $(item).get(0).classList.add("popedDown");
          $(item).get(0).classList.remove("popedUp");
      }
  }

  slideUp = (selector = ".newsText") => {

      $(selector).get().forEach(item => {
          window.addEventListener("scroll", this.innerNewsTextScroll.bind(null, item));
      });

  }

  closeButton = () => {
      document.querySelectorAll(".collapseButton").forEach(element => {element.addEventListener("click", (event) => {
          let el;

          if (event.srcElement.nodeName === "IMG") {
              el = event.srcElement.parentElement;
          } else {
              el = event.srcElement;
          }

          let id = el.dataset.target;
          
          let target = document.querySelector("[id='" + id + "']");

          if (target.classList.contains("collapsed")) {
              target.classList.remove("collapsed");
              el.children[0].src = "/assets/images/close.svg";
          } else {
              target.classList.add("collapsed");
              el.children[0].src = "/assets/images/open.svg";
          }
          
      });
  });
  }

  innerCloseSidebars = () => {
      document.querySelector("#sidebar").classList.remove("leftOut");
      document.querySelector("#sidebar").classList.remove("rightOut");
      document.querySelector("#main").classList.remove("ml-365");
      document.querySelector("#main").classList.remove("mr-365");
      document.querySelector(".ControlImgLeft").classList.remove("ml-365");
      document.querySelector(".ControlImgRight").classList.remove("mr-365");
      document.querySelector("#navbar").classList.remove("ml-365");
      document.querySelector("#navbar").classList.remove("mr-365");
  }

  innerLeftBar = (e) => {
      e.preventDefault();
      document.querySelector("#main").classList.add("ml-365");
      document.querySelector("#sidebar").classList.add("leftOut");
      document.querySelector(".ControlImgLeft").classList.add("ml-365");
      document.querySelector("#navbar").classList.add("ml-365");
  }

  innerRightBar = (e) => {
      e.preventDefault();
      document.querySelector("#main").classList.add("mr-365");
      document.querySelector("#sidebar").classList.add("rightOut");
      document.querySelector(".ControlImgRight").classList.add("mr-365");
      document.querySelector("#navbar").classList.add("mr-365");
  }

  innerCloseMobileSidebar = () => {
      document.querySelector(".mobile-sidebar").classList.remove("mobile-sidebar-out");
  }

  innerMobileSidebar = (e) => {
      e.preventDefault();
      document.querySelector(".mobile-sidebar").classList.add("mobile-sidebar-out");
      document.querySelector(".mobile-menu").style.display = "block";
  }

  bringOutSidebar = () => {
      let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if (w >= 1117) {
      

          document.querySelector(".middleBar").addEventListener("click", this.innerCloseSidebars);

          document.querySelectorAll(".bringOutLeftBar").forEach(item => {
              item.addEventListener("click", this.innerLeftBar) ;
          });
          
          document.querySelectorAll(".bringOutRightBar").forEach(item => {
              item.addEventListener("click", this.innerRightBar);
          });

      } else {

          document.querySelector(".mobile-menu-close").addEventListener("click", this.innerCloseMobileSidebar);

          document.querySelectorAll(".mobile-bring-out-filters").forEach(item => {
              item.addEventListener("click", this.innerMobileSidebar);
          });
              
      }

  }

  correctImageAspects = () => {
      document.querySelectorAll(".newsImage").forEach((item) => {
          if (item.offsetWidth !== item.offsetHeight) item.style.height = (item.offsetWidth + "px");
      });
  }

  innerRevealIcons = (item) => {
      let top_of_element = $(item).offset().top;
      let bottom_of_element = $(item).offset().top + $(item).outerHeight();
      let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
      let top_of_screen = $(window).scrollTop() + 150;
  
      if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
          item.classList.remove("hidden");
      }
  }

  revealSectionIcons = () => {
      document.querySelectorAll(".revealImage.hidden").forEach(item => {
          window.addEventListener("scroll", this.innerRevealIcons.bind(null, item));
      });
  }

  innerUtilsDown () {
      let ut = document.querySelector("#utils");
      if(window.pageYOffset <= 200) 
      {
              ut.classList.add("ControlUtilsUp");
              ut.classList.remove("ControlUtilsDown");
          } else {                    
              ut.classList.add("ControlUtilsDown");
              ut.classList.remove("ControlUtilsUp");
      }
  }

  utilsSlideDown () {
      window.addEventListener("scroll", this.innerUtilsDown);
      this.innerUtilsDown();
  }

  unbindEvents = () => {
      window.removeEventListener("scroll", this.innerUtilsDown);
      let ut = document.querySelector("#utils");
      ut.classList.add("ControlUtilsDown");
      ut.classList.remove("ControlUtilsUp");
  }

    render (props) {
        return (
        <div>
          <SplashPage />
          <div className="row w-100 p-0 m-0">
            <div className="HomePageSection">
              <div id="news" className="visibility-none" />
              <TileSection icon="/assets/images/infoooo.svg" header="AKTUALITY" buttonAction="collapse">
                {this.props.newsTiles}
              </TileSection>
              <TileSection icon="/assets/images/cd-new.svg" header="PRÁVĚ PŘISTÁLO V CD BAZARU" emptyMessage="Omlouváme se, bohužel není nic k dispozici.">
                {this.state.cdTiles}
              </TileSection>
            </div>
          </div>
          <BlackSection>
            <div id="services" className="visibility-none" />
            <ServiceForm />
          </BlackSection>
          <div className="row w-100 p-0 m-0">
            <div className="HomePageSection">
              <TileSection icon="/assets/images/cd-new.svg" header="DOPORUČENÉ" emptyMessage="Omlouváme se, bohužel není nic k dispozici.">
                {this.props.shitTiles}
              </TileSection>
            </div>
          </div>
          <BlackSection>
            <div id="squirrel" className="visibility-none" />
            <SquirelForm />
          </BlackSection>
          <div className="row w-100 p-0 m-0">
            <div className="HomePageSection">
              <TileSection icon="/assets/images/cd-new.svg" header="SUPER CD" emptyMessage="Omlouváme se, bohužel není nic k dispozici.">
              </TileSection>
            </div>
          </div>
          <BlackSection>
            <div id="login" className="visibility-none" />
            <LoginFrom loginFunction={this.props.loginFunction}/>
          </BlackSection>
          <div className="row w-100 p-0 m-0">
            <div className="HomePageSection">
              <TileSection icon="/assets/images/cd-new.svg" header="PÍSNIČKY Z 30'" emptyMessage="Omlouváme se, nenašli jsme CD podle vašich filtru.">
              </TileSection>
            </div>
          </div>
          <NavbarBG />
        </div>
        );
    }

}

export default HomePage;