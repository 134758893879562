import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

//interpret
//titul
//rok
//nosic
//add to cart
//barcode
//comment
//price
class grid extends Component {

    constructor (props) {
        super(props);

        this.state = {
            page : 0,
            perPage : 10,
            search : "",
            items : null
        };

        /*
        items : [
                {id: 0, show: 1, interpret: "jsri Peterson", titul: "bubak bo sfw fwaf Lorem", rok: "2008", nosic: "CD", kod: "8596985401229", komentar: "", cena: "259.00"},
                {id: 1, show: 1, interpret: "jtti", titul: "bubak", rok: "1111", nosic: "CD", kod: "8596985401229", komentar: "bobob", cena: "441.00"},
                {id: 2, show: 1, interpret: "j1i", titul: "bubak", rok: "3559", nosic: "CD", kod: "8596985401229", komentar: "", cena: "441.00"},
                {id: 3, show: 1, interpret: "Ava", titul: "bubak", rok: "2001", nosic: "CD", kod: "8596985401229", komentar: "", cena: "442.00"},
                {id: 4, show: 1, interpret: "Eva", titul: "bubak", rok: "2005", nosic: "CD", kod: "8596985401229", komentar: "2xCD", cena: "259.00"},
                {id: 5, show: 1, interpret: "Peter", titul: "bubak", rok: "3530", nosic: "CAR", kod: "8596985401229", komentar: "", cena: "300.00"},
                {id: 6, show: 1, interpret: "j2ri", titul: "bubak", rok: "", nosic: "TAPE", kod: "8596985401229", komentar: "", cena: "259.00"},
                {id: 7, show: 1, interpret: "jiri", titul: "bubak", rok: "", nosic: "DVD", kod: "8596985401229", komentar: "", cena: "50.00"}
            ]
        */

        if (Number(this.props.page) < 0) {
            this.state.page = 0
        } else {
            this.state.page = this.props.page;
        }

        if (Number(this.props.perPage) < 0) {
            this.state.perPage = 10;
        } else {
            this.state.perPage = this.props.perPage;
        }


        if (props.search !== undefined)
        this.state.search = props.search;

        this.defaultURL = "/zkracene/";
        if (!!this.props.defaultURL) {
            this.defaultURL = this.props.defaultURL;
        }
        
    }

    updateItemCount = (e) => {
        let value = Number(e.target.innerText);
        
        if (Number.isInteger(value) && value >= 5 && value <= 100) {
            this.setState({perPage : Number(value)});
            window.history.pushState(null, null, this.defaultURL + this.state.page + "/" + value + "/" + this.state.search);
        }
    }

    revealItemCounts () {
        document.querySelector(".GridSelect").classList.add("show");
    }

    pageForward = () => {
        let newPage = Number(this.state.page) + 1;

        if (this.getFutureItems().length !== 0) {
            this.setState({page : newPage})
            window.history.pushState(null, null, this.defaultURL + newPage + "/" + this.state.perPage + "/" + this.state.search);
        }
    }

    pageBackwards = () => {
        let newPage = Number(this.state.page) - 1;

        if (!(newPage < 0)) {
            this.setState({page : newPage})
            window.history.pushState(null, null, this.defaultURL + newPage + "/" + this.state.perPage + "/" + this.state.search);
        }
    }

    pageHome = () => {
        let newPage = 0;

        if (!(newPage < 0)) {
            this.setState({page : newPage})
            window.history.pushState(null, null, this.defaultURL + newPage + "/" + this.state.perPage + "/" + this.state.search);
        }
    }

    pageEnd = () => {
        //tady je floor, nikoliv ceil, protoze list zacina na indexu 0
        let newPage = Math.floor(this.state.items.length / this.state.perPage);

        if (this.getFutureItems().length !== 0) {
            this.setState({page : newPage})
            window.history.pushState(null, null, this.defaultURL + newPage + "/" + this.state.perPage + "/" + this.state.search);
        }
    }

    getFilteredItems = () => {

        let filteredItems = [];
        this.state.items.forEach(item => {
            if (item.show) filteredItems.push(item);
        })
        return filteredItems;
        
    }

    getItems () {
        let page = Number(this.state.page);
        let perPage = Number(this.state.perPage);

        return this.getFilteredItems().slice(page * perPage, page * perPage + perPage);
    }

    getFutureItems () {
        let page = Number(this.state.page) + 1;
        let perPage = Number(this.state.perPage);

        return this.getFilteredItems().slice(page * perPage, page * perPage + perPage);
    }

    getPastItems () {
        let page = Number(this.state.page) - 1;
        let perPage = Number(this.state.perPage);

        return this.getFilteredItems().slice(page * perPage, page * perPage + perPage);
    }

    getFuturePage () {
        if (this.getFutureItems().length === 0) {
            return this.state.page;
        } else {
            return Number(this.state.page) + 1;
        }
    }

    getPastPage () {
        if (this.getPastItems().length === 0) {
            return this.state.page;
        } else {
            return Number(this.state.page) - 1;
        }
    }

    previousChildIndex = 0;

    orderBy = (e) => {
        let child = e.target;
        
        let i = 1;
        while( (child = child.previousSibling) != null ) 
            i++;

        this.previousChildIndex = i;

        let el = document.querySelector(".fastShopTable table");
        let reverse = false;

        if (el.classList.length === 0)
            el.className = "q";


        if (!el.classList.contains("orderBy" + i)) {
            el.className = "";
            el.classList.add("orderBy" + i);
            reverse = false;
        } else {
            if (el.classList.contains("reverse")) {
                el.classList.remove("reverse");
                reverse = false
            } else if (el.classList.contains("orderBy" + i)) {
                el.classList.add("reverse");
                reverse = true;   
            } else {
                el.classList.replace(el.classList, ["orderBy" + i]);
                reverse = false;
            }
        }

        const order = (a, b) => {
            //order by column index
            switch (i) {
                case 1:
                    a = a.interpret;
                    b = b.interpret;
                    break;
                case 2:
                    a = a.titul;
                    b = b.titul;
                    break;
                case 3:
                    a = a.rok;
                    b = b.rok;
                    break;
                case 4:
                    a = a.nosic;
                    b = b.nosic;
                    break;
                case 5:
                    a = a.kod;
                    b = b.kod;
                    break;
                case 6:
                    a = a.kod;
                    b = b.kod;
                    break;
                case 7:
                    a = a.komentar;
                    b = b.komentar;
                    break;
                case 8:
                    a = a.cena;
                    b = b.cena;
                    break;
            
                default:
                    break;
            }
            
            if (i === 8) {
                if (a === "neuvedeno" || b === "neuvedeno") {
                    return 1;
                }
                if (reverse) {
                    if (parseInt(b) > parseInt(a)) return 1;
                    else if (parseInt(b) < parseInt(a)) return -1;
                    else return 0;
                } else {
                    if (parseInt(a) > parseInt(b)) return 1;
                    else if (parseInt(a) < parseInt(b)) return -1;
                    else return 0;
                }
            } else {
                if (reverse) {
                    if (b === null) return 0;
                    return b.localeCompare(a, 'en', { sensitivity: 'base' });                
                } else {
                    if (a === null) return 0;
                    return a.localeCompare(b, 'en', { sensitivity: 'base' });
                }
            }
        }

        this.setState({page : 0});

        let newItems = this.state.items;
        this.setState({items : newItems.sort(order)});

        window.history.pushState(null, null, this.defaultURL + "0/" + this.state.perPage + "/" + this.state.search);

    }

    searchChange = (e) => {

        if (!this.state.items || this.state.items.length === 0) {
            return;
        }

        let search = e.target.value.toLocaleLowerCase();

        let items = this.state.items;
        
        if (search === "") {
            items.forEach(el => {
                el.show = 1;
            });
            this.setState({page: 0, items: items});
            document.querySelector(".GridTextCross").classList.remove("show");
        } else {

            document.querySelector(".GridTextCross").classList.add("show");
            
            let newItems = Array.from(items);
            
            let i = 0;

            items.forEach(el => {
                if (Object.values(el).some((i) => {
                    if (!i)
                        return 0;

                    if (i.toString().toLocaleLowerCase().includes(search)) {
                        return 1;
                    } else {
                        return 0;
                    }
                })) {
                    newItems[i].show = 1;
                } else {
                    newItems[i].show = 0;
                }
                i++;
            });

            this.setState({page : 0, items: newItems});

        }

        this.setState({search: e.target.value});
        
        window.history.pushState(null, null, this.defaultURL + "0/" + this.state.perPage + "/" + e.target.value);
        
    }

    clearInput = (e) => {
        e.persist();

        if (e.target.classList.contains("GridTextCross")) {
            document.getElementById("fastShopSearch").value = "";
            document.querySelector(".GridTextCross").classList.remove("show");
            

            let items = this.state.items;
            items.forEach(el => {
                el.show = 1;
            });
            this.setState({page: 0, items: items});
        }
        
    }

    inputEnter = (e) => {
        if (document.querySelector("#fastShopSearch").value !== "")
        document.querySelector(".GridTextCross").classList.add("show");        
    }

    inputLeave = (e) => {
        if (document.querySelector("#fastShopSearch").value === "")
        document.querySelector(".GridTextCross").classList.remove("show");
    }

    componentDidMount = () => {
        //this.setState({perPage : Number(this.perPage)});
        document.querySelector("#fastShopSearch").value = this.state.search;
        let e = {target : document.querySelector("#fastShopSearch")} //fake event
        this.searchChange(e);
    }

    componentWillReceiveProps = (nextProps) => {
        let newItems = [];
        if (!!nextProps.items)
            nextProps.items.forEach(el => {
                //{id: 0, show: 1, interpret: "jsri Peterson", titul: "bubak bo sfw fwaf Lorem", rok: "2008", nosic: "CD", kod: "8596985401229", komentar: "", cena: "259.00"},
                let price;
                if (el.X_Price !== 0) {
                    /*if (!el.X_Price.toString().includes(".00 Kč"))
                        price = el.X_Price + ".00 Kč";
                    else
                        price = el.X_Price;*/
                        
                    price = el.X_Price + " Kč";
                } else
                    price = "neuvedeno"

                newItems.push({id: el.id, show: 1, interpret: el["X_Interpret_ID.FullName"], titul: el.name, rok: el.X_DateOfPublication, nosic: el["StoreMenuItem_ID.Text"], kod: el.ean, komentar: "", cena: price});
            });
        this.setState({ items: newItems });
    }

    render (props) {

        /*if (this.perPage === 0 ) {
            this.perPage = this.props.perPage;
        } */

        let tableContent = [];
        let tableHead = (
            <tr onClick={this.orderBy}>
                <th>interpret</th>
                <th>titul</th>
                <th>rok</th>
                <th>nosič</th>
                <th>PŘIDAT DO KOŠÍKU</th>
                <th>ČÁROVÝ KÓD</th>
                <th>KOMENTÁŘ</th>
                <th>CENA KČ</th>
            </tr>
        );

        let key = 0;
        let items;

        if (!!this.state.items && this.state.items.length > 0) {
            items = this.getItems();
        } else {
            items = [];
        }

        items.forEach(el => {
            if (el.show) {

                let year = "";

                if (el.rok) {
                    year = el.rok.substring(0, 4);
                }

                let row = (<tr key={key}>
                    <td>{el.interpret}</td>
                    <td><Link to={"/track/" + el.id}>{el.titul}</Link></td>
                    <td>{year}</td>
                    <td>{el.nosic}</td>
                    <td onClick={(event)=>this.props.onClickHandler({id:el.id, bandName: el.interpret, albumName: el.titul, price: el.cena}, event)}>do košíku</td>
                    <td>{el.kod}</td>
                    <td>{el.komentar}</td>
                    <td>{el.cena}</td>
                </tr>);

                tableContent.push(row);
                key++;
            }
        });

        let table = (
            <table>
                <thead>
                    {tableHead}
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
        );

        let searchbar;
        if (this.props.enableSearch === true || this.props.enableSearch === undefined || this.props.enableSearch === null) { //basically setting true as default value
            searchbar = (
                <div className="GridTextWrapper" onClick={this.clearInput} onMouseEnter={this.inputEnter} onMouseLeave={this.inputLeave}>
                    <input id="fastShopSearch" className="GridText" type="text" placeholder="Search" onChange={this.searchChange}></input>
                    <div className="GridTextCross"></div>
                </div>
            );
        } else {
            searchbar = (
                <div className="GridTextWrapper d-none" onClick={this.clearInput} onMouseEnter={this.inputEnter} onMouseLeave={this.inputLeave}>
                    <input id="fastShopSearch" className="GridText" type="text" placeholder="Search" onChange={this.searchChange}></input>
                    <div className="GridTextCross"></div>
                </div>
            );
        }

        return (
            <div className="w-100 fastShopTableWrapper">
            <div className="w-100 fastShopTable">
                <div className="fastTableControls">
                    <button className="GridButton left" onClick={this.pageHome} >{"|<"}</button>
                    <button className="GridButton left" onClick={this.pageBackwards} >{"<"}</button>
                    <div className="GridSelect" onClick={this.revealItemCounts}>
                        {this.state.perPage.toString()}
                        <div className={"GridOption" + ((this.state.perPage === 5) ? " selected" : "")} onClick={this.updateItemCount}>5</div>
                        <div className={"GridOption" + ((this.state.perPage === 10) ? " selected" : "")} onClick={this.updateItemCount}>10</div>
                        <div className={"GridOption" + ((this.state.perPage === 25) ? " selected" : "")} onClick={this.updateItemCount}>25</div>
                        <div className={"GridOption" + ((this.state.perPage === 50) ? " selected" : "")} onClick={this.updateItemCount}>50</div>
                    </div>
                    <button className="GridButton right" onClick={this.pageForward} >{">"}</button>
                    <button className="GridButton right" onClick={this.pageEnd} >{">|"}</button>
                    {searchbar}
                </div>
                {table}
            </div>
            </div>
        );

    }

}

export default grid;