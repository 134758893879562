import React from 'react';

const SquirelFrom = () => {

    return (

        <div className="row col-12 col-lg-6 p-0 mx-auto squirrelWrapper">
            <div className="col-12 p-0 mx-auto">
                <img className="squirrelImage p-0' mx-auto" src="/assets/images/squirrel_2.svg" alt=""/>
            </div>
            <div className="col-12 p-0 mx-auto">
                <div className="col-12 p-0 squirrelForm mx-auto squirrelText">
                    <h4>hlídací veverka</h4>
                    <p>Zapněte si Hlídací veverku a buďte vždy ihned informováni o nových titulech, které nám přistanou v CD Bazaru!</p>
                </div>
            </div>
            <div className="col-12 p-0 mx-auto">
                <div className="col-12 squirrelForm squirrelMobileForm p-0 my-5 mx-auto">
                    <form>
                        <input placeholder="JMÉNO INTERPRETA" className="basicInput w-100" type="text"></input>
                        <input placeholder="NÁZEV ALBA" className="basicInput mt-5 w-100" type="text"></input>
                        <input placeholder="VÁŠ EMAIL" className="basicInput my-5 w-100" type="text"></input>
                        <button className="squirrelFormSubmit" type="submit"><img src="/assets/images/tick-inside-circle.svg" alt="submit button" /></button>
                    </form>
                </div>
            </div>
        </div>

    );

}

export default SquirelFrom;