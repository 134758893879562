import React, { Component } from 'react';

import { NavbarSpacer } from '../Utils/Utils.js';
import RowSection from '../Sections/RowSection.js';
import ProfileSquirrel from '../ProfileSquirrel/ProfileSquirrel.js';

class ProfileDeliveryPage extends Component {

    render = (props) => {
        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto profilePage cartPage squirrelPage container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/user_b.svg" header="MŮJ ÚČET → HLÍDACÍ VEVERKA">
                        <ProfileSquirrel/>
                    </RowSection>
                </div>
            </div>
        )
    }

    componentDidMount = () => {
        document.querySelector(".profilePage .homeTileDivider").style.display = "none";
    }

}

export default ProfileDeliveryPage;