import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Profile extends Component {

    squirrelClicked = (e) => {
        this.hideSquirrel();
    }

    showSquirrel = () => {
        document.querySelector(".squirrelPage .squirrelInputWrapper").style.display = "flex";
    }

    hideSquirrel = () => {
        document.querySelector(".squirrelPage .squirrelInputWrapper").style.display = "none";
    }

    componentDidMount = () => {
        this.hideSquirrel();
    }

    render = (props) => {

        return (
            <div className="cartItems">
                <div className="cartCrumbs Profile">
                    <Link to="/profil" ><div className="cartCrumb"><img className="icon-black" src="/assets/images/user.svg" alt=""/><span>OSOBNÍ ÚDAJE</span></div></Link>
                    <Link to="/profil/dodaci-udaje" ><div className="cartCrumb"><img className="icon-black" src="/assets/images/adress_b.svg" alt=""/><span>DODACÍ ÚDAJE</span></div></Link>
                    <Link to="/profil/veverka" ><div className="cartCrumb color-red"><img className="icon-red" src="/assets/images/veverka_b.svg" alt=""/><span>HLÍDACÍ VEVERKA</span></div></Link>
                    <Link to="/profil/objednavky" ><div className="cartCrumb noAfter"><img src="/assets/images/objednavka_b.svg" alt=""/><span>MOJE OBJEDNÁVKY</span></div></Link>
                </div>
                <div className="col-12 homeTileDivider"></div>
                
                <div className="col-12 pl-0 mb-4 vertical-center"><img className="d-inline" src="/assets/images/cd.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> HLÍDANÍ INTERPRETI</p></div>
                <div className="cartItem">
                    <div className="cartItemImageWrapper">
                        <img alt="" className="cartItemImage" src="/assets/images/love.jpg"/>
                    </div>
                    <div className="cartItemDescWrapper">
                        <h2 className="cartItemDescHeader">empire of the sun</h2>
                        <p className="cartItemDescText">DATUM VYTVOŘENÍ VEVERKY</p>
                        <p className="cartItemDescCode">10. 05. 2019</p>
                        <div className="cartItemMobileImageWrapper">
                            <img alt="" className="cartItemImage" src="/assets/images/cd_2.svg"/>
                        </div>
                    </div>
                    <div className="cartItemControlsWrapper">
                        <div className="cartItemControlsClose"><img alt="" src="/assets/images/delete-button.svg" /></div>
                    </div>
                </div>
                <div className="col-12 homeTileDivider"></div>

                <div className="cartItem">
                    <div className="cartItemImageWrapper">
                        <img alt="" className="cartItemImage" src="/assets/images/love.jpg"/>
                    </div>
                    <div className="cartItemDescWrapper">
                        <h2 className="cartItemDescHeader">empire of the sun</h2>
                        <p className="cartItemDescText">DATUM VYTVOŘENÍ VEVERKY</p>
                        <p className="cartItemDescCode">10. 05. 2019</p>
                        <div className="cartItemMobileImageWrapper">
                            <img alt="" className="cartItemImage" src="/assets/images/cd_2.svg"/>
                        </div>
                    </div>
                    <div className="cartItemControlsWrapper">
                        <div className="cartItemControlsClose"><img alt="" src="/assets/images/delete-button.svg" /></div>
                    </div>
                </div>
                <div className="col-12 homeTileDivider"></div>

                <div className="cartItem">
                    <div className="cartItemImageWrapper">
                        <img alt="" className="cartItemImage" src="/assets/images/love.jpg"/>
                    </div>
                    <div className="cartItemDescWrapper">
                        <h2 className="cartItemDescHeader">empire of the sun</h2>
                        <p className="cartItemDescText">DATUM VYTVOŘENÍ VEVERKY</p>
                        <p className="cartItemDescCode">10. 05. 2019</p>
                        <div className="cartItemMobileImageWrapper">
                            <img alt="" className="cartItemImage" src="/assets/images/cd_2.svg"/>
                        </div>
                    </div>
                    <div className="cartItemControlsWrapper">
                        <div className="cartItemControlsClose"><img alt="odebrat hlídací veverku" src="/assets/images/delete-button.svg" /></div>
                    </div>
                </div>
                <div className="col-12 homeTileDivider"></div>

                <div className="col-12 p-0 d-flex orderPageButtonWrapper">
                    <div className="squirrelInputWrapper">
                        <input className="basicInput"></input>
                        <div className="squirrelInputAccept" onClick={this.squirrelClicked}><img className="icon-red w-100 h-100" src="/assets/images/tick-inside-circle_black.svg" alt="přidat hlídací veverku"/></div>
                        <div className="squirrelInputDeny" onClick={this.hideSquirrel}><img className="icon-red w-100 h-100" src="/assets/images/delete-button.svg" alt="nepřidat hlídací veverku"/></div>
                    </div>
                    <Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt=""/>POKRAČOVAT V NÁKUPU</div></button></Link>
                    <button onClick={this.showSquirrel} className="registerFormButtonSave vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/veverka_w.svg" width="20" height="20" alt=""/>PŘIDAT NOVOU VEVERKU</div></button>
                </div>

                <div className="my-5 py-5"></div>
            </div>
        );

    }

}
export default Profile;