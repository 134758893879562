import React from 'react';
import { Link } from 'react-router-dom';
import ApiComponent from '../ApiComponent/ApiComponent';
import { sha3_512 } from 'js-sha3';
import { LoadingOverlay, Popup, InputStyle } from '../Utils/Utils';

class RegisterForm extends ApiComponent {

    markedInputs = [];
    annotatedInputs = [];

    submutRegisterForm = (e) => {
        let formInputs = document.querySelectorAll("#registerForm input:not([type=checkbox])");
        let formData = {};
        let adressData = {};
        
        let adress = { //residenceaddress_id.Street
            street : null,
            cp : null,
            co : null
        }

        let auth = {
            email: null,
            password: null
        }

        let confirmPassword;

        formInputs.forEach(element => {
            switch (element.name) {
                case "street": // adress
                    adress.street = element.value;
                    break;
                case "cp": // adress
                    adress.cp = element.value
                    break;
                case "co": // adress
                    adress.co = element.value;
                    break;
                case "residenceaddress_id.email": //auth
                    auth.email = element.value;
                    adressData["email"] = element.value;
                    break;
                case "password": //auth
                    auth.password = element.value;
                    break;
                case "confirmPassword":
                    confirmPassword = element.value;
                    break;
                default:
                    if (element.name.indexOf("residenceaddress_id.") !== -1) {
                        adressData[element.name.substring(20)] = element.value;
                    } else {
                        formData[element.name] = element.value;
                    }
                    break;
            }
        });

        /*if (auth.password !== confirmPassword) {
            Popup.DisplayMessage("Zadané heslo nesouhasí s potvrzením hesla");
            return;
        }*/

        if (!adress.co) {
            adressData["street"] = adress.street + " " + adress.co;
        } else if (!adress.cp) {
            adressData["street"] = adress.street + " " + adress.cp;
        } else if (!adress.cp && !adress.co) {
            Popup.DisplayMessage("Neplatná adresa");
            return;
        } else {
            adressData["street"] = adress.street + " " + adress.cp + " " + adress.co;
        }

        formData["X_auth"] = sha3_512(auth.email + ":" + auth.password);

        console.log(this.markedInputs);
        this.markedInputs.forEach(el => {
            InputStyle.UnmarkInput(document.getElementsByName(el)[0]);
        });
        this.annotatedInputs.forEach(el => {
            InputStyle.UnmarkInput(document.getElementsByName(el)[0]);
        });
        this.markedInputs = [];
        this.annotatedInputs = [];

        let inputValidationFailed = false;

        const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(String(adressData["email"]).toLowerCase())) {
            //Popup.DisplayMessage("Neplatný email");
            let a = document.getElementsByName("residenceaddress_id.email");
            InputStyle.MarkInputWrong(a[0], "Neplatný email");
            /*setTimeout(() => {
                InputStyle.UnmarkInput(a[0]);
            }, 5000);*/
            this.markedInputs.push("residenceaddress_id.email");
            inputValidationFailed = true;
        }

        let phoneNumer = adressData["PhoneNumber1"];
        if (phoneNumer.match(/^[a-zA-Z]+$/) != null || phoneNumer.replaceAll(" ", "").length != 9) {
            let a = document.getElementsByName("residenceaddress_id.PhoneNumber1");
            InputStyle.MarkInputWrong(a[0], "Neplatné čislo");
            this.markedInputs.push("residenceaddress_id.PhoneNumber1");
            inputValidationFailed = true;
        }

        if (auth.password !== confirmPassword) {
            let a = document.getElementsByName("password");
            let b = document.getElementsByName("confirmPassword");

            InputStyle.MarkInputWrong(a[0], "Hesla se neshodují");
            InputStyle.MarkInputWrong(b[0], "Hesla se neshodují");

            this.markedInputs.push("password");
            this.markedInputs.push("confirmPassword");

            inputValidationFailed = true;
        } else {
            const passValidation = this.validatePasswordString(auth.password);
            if (passValidation !== true) {
                let a = document.getElementsByName("password");
                InputStyle.MarkInputWrong(a[0], passValidation);
                this.markedInputs.push("password");
                inputValidationFailed = true;
            }
        }

        let a = document.getElementsByName("OrgIdentNumber")[0];
        let ic = a.value;
        if (ic.length > 0) {
            if (ic.length != 8 || ic.match(/^[a-zA-Z]+$/) != null) {
                InputStyle.MarkInputWrong(a, "Neplatné IČO");
                this.markedInputs.push("OrgIdentNumber");
                inputValidationFailed = true;
            }
        }

        a = document.getElementsByName("VATIdentNumber")[0];
        let dic = a.value;
        if (dic.length > 0) {
            if (dic.length >= 8 || dic.length <= 12 || dic.substring(0, 2).match(/[^a-zA-Z]+$/) != null) {
                InputStyle.MarkInputWrong(a, "Neplatné DIČ");
                this.markedInputs.push("VATIdentNumber");
                inputValidationFailed = true;
            }
        }


        if (inputValidationFailed) {
            return;
        }

        const responseRelated = this.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/firms?select=id&where=residenceaddress_id.email+eq+'" + adressData["email"] + "'");

        LoadingOverlay.ApplyOverlay();

        responseRelated.then((res) => {
            console.log(res);
            console.log(res.data);
            console.log(res.data.length);
            if (res.data.length != 0) {
                LoadingOverlay.RemoveOverlay();
                let a = document.getElementsByName("residenceaddress_id.email");
                InputStyle.MarkInputWrong(a[0], "Tento email je již registrovaný");
                this.markedInputs.push("residenceaddress_id.email");
                //Popup.DisplayMessage("Tento email je již registrovaný");
                return; //TODO trying to register already registered email
            }

            const registerResponse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/firms", formData);
            registerResponse.then((res) => {
                const adressResponse = this.putDataFromEndpoint("https://api.abra.slox.cz/BazarCD/addresses/" + res.data.residenceaddress_id.id + "?select=id", adressData);
                adressResponse.then((res) => {
                    LoadingOverlay.RemoveOverlay();
                    this.props.loginFunction(auth.email, auth.password);
                    //window.location.href = "/profil";
                });
            });
            
        });

    }

    validatePasswordInput = (e) => {
        const originalElName = e.currentTarget.name;
        const originalElSelectionStart = e.currentTarget.selectionStart;

        this.annotatedInputs.forEach(el => {
            InputStyle.UnmarkInput(document.getElementsByName(el)[0]);
        });
        this.annotatedInputs = [];

        const password = document.querySelector("#registerForm input[name='password']").value;
        const confirmPassword = document.querySelector("#registerForm input[name='confirmPassword']").value;

        if (password !== confirmPassword && confirmPassword !== '') {
            let a = document.getElementsByName("password");
            let b = document.getElementsByName("confirmPassword");

            InputStyle.AnnotateInput(a[0], "Hesla se neshodují", InputStyle.AnnotationColors.Red);
            InputStyle.AnnotateInput(b[0], "Hesla se neshodují", InputStyle.AnnotationColors.Red);

            this.annotatedInputs.push("password");
            this.annotatedInputs.push("confirmPassword");

        } else {
            const passValidation = this.validatePasswordString(password);
            if (passValidation !== true) {
                let a = document.getElementsByName("password");
                InputStyle.AnnotateInput(a[0], passValidation, InputStyle.AnnotationColors.Red);
                this.annotatedInputs.push("password");
            }
        }

        const target = document.querySelector("#registerForm input[name='" + originalElName + "']");
        if (!!target) {
            target.focus();
            target.selectionStart = originalElSelectionStart;
        }
    }

    validatePasswordString = (pass) => {
        let errors = [];

        if (pass.length < 6) {
            errors.push("6 znaků"); 
        }
        if (pass.search(/[a-z]/) < 0) {
            errors.push("jedno malé písmeno");
        }
        if (pass.search(/[A-Z]/) < 0) {
            errors.push("jedno velké písmeno");
        }
        if (pass.search(/[0-9]/) < 0) {
            errors.push("jednu číslici"); 
        }
        if (errors.length > 0) {
            return "Heslo musí obsahovat alespoň " + errors.join(", ");
        }
        return true;
    }


    state = {form : <div></div>};

    registerFormChecked = (e) => {
        if (!e.target.checked) {
            this.setState({form: <div className="mt-3 registerFormCopy">
                <div className="col-12 mb-4 vertical-center"><img className="d-inline" src="/assets/images/adress.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> FAKTURAČNÍ ADRESA</p></div>
                <input name="cCompanyName" inputMode="text" placeholder="JMÉNO / NÁZEV FIRMY" className="basicInput registerInput color-black placeholder-black"></input>
                <input name="cIc" inputMode="text" placeholder="IČ" className="basicInput registerInput color-black placeholder-black"></input>
                <input name="cDic" inputMode="text" placeholder="DIČ" className="basicInput registerInput color-black placeholder-black"></input>
                <input name="cStreet" inputMode="text" placeholder="NÁZEV ULICE" className="basicInput registerInput color-black placeholder-black"></input>
                <input name="cCp" inputMode="text" placeholder="ČÍSLO POPISNÉ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                <input name="cCo" inputMode="text" placeholder="CÍSLO ORIENTAČNÍ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                <input name="cCity" inputMode="text" placeholder="MĚSTO" className="basicInput registerInput color-black placeholder-black"></input>
                <input name="cPsc" inputMode="text" placeholder="PSČ" className="basicInput registerInput color-black placeholder-black"></input>
                <input name="cCountry" inputMode="text" placeholder="ZEMĚ" className="basicInput registerInput color-black placeholder-black"></input>
            </div>});
        } else {
            this.setState({form: <div></div>})
        }
    }

    render = () => {

        return (
            <div>
                <div className="registerForm mb-0">
                    <form name="registerForm" id="registerForm">
                        <div className="col-12 mb-4 vertical-center"><img className="d-inline" src="/assets/images/user.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> ÚDAJE ZÁKAZNÍKA</p></div>
                        <input name="residenceaddress_id.email" inputMode="text" placeholder="EMAIL" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="X_FirstName" inputMode="text" placeholder="JMÉNO" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="X_LastName" inputMode="text" placeholder="PŘIJMENÍ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="password" inputMode="password" type="password" placeholder="HESLO" className="basicInput registerInput color-black placeholder-black" onChange={this.validatePasswordInput}></input>
                        <input name="confirmPassword" inputMode="password" type="password" placeholder="HESLO ZNOVU" className="basicInput registerInput color-black placeholder-black" onChange={this.validatePasswordInput}></input>
                        <input name="residenceaddress_id.PhoneNumber1" inputMode="text" placeholder="TELEFON" minLength={9} className="basicInput registerInput color-black placeholder-black"></input>

                        <div className="col-12 mb-4 mt-2 vertical-center"><img className="d-inline" src="/assets/images/adress.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> DORUČOVACÍ ADRESA</p></div>
                        <input name="name" inputMode="text" placeholder="JMÉNO / NÁZEV FIRMY" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="OrgIdentNumber" inputMode="text" placeholder="IČ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="VATIdentNumber" inputMode="text" placeholder="DIČ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="street" inputMode="text" placeholder="NÁZEV ULICE" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="cp" inputMode="text" placeholder="ČÍSLO POPISNÉ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                        <input name="co" inputMode="text" placeholder="CÍSLO ORIENTAČNÍ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                        <input name="residenceaddress_id.City" inputMode="text" placeholder="MĚSTO" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="residenceaddress_id.PostCode" inputMode="text" placeholder="PSČ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="residenceaddress_id.Country" inputMode="text" placeholder="ZEMĚ" className="basicInput registerInput color-black placeholder-black"></input>

                        <label className="color-red vertical-center"><input className="registerformCheckbox mr-2" type="checkbox" defaultChecked={true} onChange={this.registerFormChecked}></input>FAKTURAČNÍ ADRESA JE STEJNÁ JAKO DORUČOVACÍ ADRESA</label>

                        {this.state.form}

                    </form>
                </div>
                <div className="registerForm bottomPart">
                    <div className="col-12 homeTileDivider"></div>
                    <div className="col-12 p-0 d-flex orderPageButtonWrapper">
                        <Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt=""/>POKRAČOVAT V NÁKUPU</div></button></Link>
                        <button onClick={this.submutRegisterForm} className="registerFormButtonSave vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/save.svg" width="20" height="20" alt=""/>ULOŽIT ÚDAJE</div></button>
                    </div>
                </div>
            </div>
        );

    }

}

export default RegisterForm;