import React, { Component } from 'react';

import { NavbarSpacer } from '../Utils/Utils.js';
import RowSection from '../Sections/RowSection.js';
import Cart from '../Cart/Cart.js';

class CartPage extends Component {

    render = (props) => {
        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto cartPage container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/cartIcon.svg" header="KOŠÍK">
                        <Cart items={this.props.items} deleteHandler={this.props.deleteHandler}/>
                    </RowSection>
                </div>
            </div>
        )
    }

    componentDidMount = () => {
        document.querySelector(".cartPage .homeTileDivider").style.display = "none";
    }

}

export default CartPage;