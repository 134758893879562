import React, { Component } from 'react';

const NavbarSpacer = () => {

    return (
        <div className="UtilsNavbarSpacer"/>
    );

}

class ScreenSpacer extends Component {

    render = () => {
        return (
            <div id="screenSpacer" />
        )
    }

    componentDidMount = () => {
        
        let bodyHeight = document.body.offsetHeight;
        let windowHeight = window.innerHeight;
        
        if (bodyHeight < windowHeight) 
            document.querySelector("#screenSpacer").style.height = windowHeight - bodyHeight + "px";

    }

}

class LoadingOverlay extends Component {

    static overlayElement = null;

    static ApplyOverlay = (element) => {
        if (!!this.overlayElement) {
            return;
        }
        if (!element) {
            element = document.body;
        }

        this.overlayElement = document.createElement("div");
        this.overlayElement.id = "LoadingOverlayElement";
        let img = document.createElement("img");
        img.src = "/assets/images/cd.svg";
        this.overlayElement.appendChild(img);
        element.parentNode.insertBefore(this.overlayElement, element);

        //wait 20s and then remove the overlay in case something goes wrong
        setTimeout(this.RemoveOverlay, 20000);
    }

    static RemoveOverlay = () => {
        if (!this.overlayElement) {
            this.overlayElement = document.querySelector("#LoadingOverlayElement");
            if (!this.overlayElement) {
                //couldnt find overlay element - probably ApplyOverlay wasnt called for some reason
                return;
            }
        }

        this.overlayElement.remove();
        this.overlayElement = null;

        clearTimeout(this.RemoveOverlay);
    }

}

class localStorageManager {

    static SetLocalStorageItem = (itemName, itemValue) => {
        window.localStorage.setItem(itemName, JSON.stringify(itemValue));
    }
    
    static GetLocalStorageItem = (ItemName, defaultReturnValue = null) => {
        let item = JSON.parse(window.localStorage.getItem(ItemName));
        if (!item) {
            return defaultReturnValue;
        } else {
            return item;
        }
    }

    static Enums = {
        searchValues: 1,
        paymentFormData: 2,
        loggedInUserData: 3,
        loggedInUser: 4,
        cart: 5,
        CDAudio: 6,
        cachedOrder: 7,
        packetaAddress: 8,
        packetaName: 9
    }

}

class Popup {

    //TODO add a way to pass elements as argument
    static DisplayMessage = (message) => {
        const el = document.querySelector("#popupWrapper");
        const text = el.querySelector("#popupText");

        text.innerHTML = "";
        if (typeof message == "string") {
            text.innerHTML = message;
        } else if (typeof message == "object") {
            message.forEach(element => {
                text.innerHTML += element;
            });
        } else {
            console.log("invalid argument");
            return;
        }

        el.classList.add("cartPopedUp");
        el.classList.remove("cartPopedDown");
    }

    static Hide = () => {
        const el = document.querySelector("#popupWrapper");
        el.classList.remove("cartPopedUp");
        el.classList.add("cartPopedDown");
    }

}

class InputStyle {

    static AnnotationColors = {
        Black: "color-black",
        Gray: "color-gray",
        White: "color-white",
        Red: "color-red"
    };

    static MarkInputWrong = (element, inputMessage) => {
        element.classList.add("wrong");
        let infoElement = document.createElement("div");
        infoElement.className = "inputWrongInfo";
        
        let messageElement = document.createElement("p");
        messageElement.innerHTML = inputMessage;

        infoElement.appendChild(messageElement);
        infoElement.appendChild(element.cloneNode());
        element.replaceWith(infoElement);
    }

    static AnnotateInput = (element, inputMessage, annotationColor) => {
        element.classList.add("annotated");
        let infoElement = document.createElement("div");
        infoElement.className = "inputAnnotated";
        
        let messageElement = document.createElement("p");
        messageElement.innerHTML = inputMessage;
        messageElement.className = annotationColor;

        infoElement.appendChild(messageElement);

        element.parentNode.insertBefore(infoElement, element);
        infoElement.appendChild(element)
/*
        infoElement.appendChild(element);
        element.replaceWith(infoElement);*/
    }

    static UnmarkInput = (element) => {
        element.parentNode.replaceWith(element)
    }

}

const getItemIcon = (iconString) => {
    switch (iconString) {
      case "CD":
        return "cd-ico.svg"
      default:
        return "dvd-ico.svg";
    }
}

//returns adress array
//ex. input "Svatoslavova 523 13"
//ex. output {adress: "Svatoslavova", cp: "523", co: "13"}
const parseAdressIntoComponents = (adress) => {
    let adressSplit = adress.split(" ");
    let adressArray = {"cp": adressSplit[adressSplit.length - 2], "co": adressSplit[adressSplit.length -1]}
    adressSplit.pop();
    adressSplit.pop();
    adressArray["address"] = adressSplit.join(" ");
    return adressArray;
}

export { NavbarSpacer, ScreenSpacer, LoadingOverlay, localStorageManager, Popup, InputStyle, getItemIcon, parseAdressIntoComponents };