import React from 'react';

const BlackSection = (props) => {

    let className;

    if (props.className === undefined) {
        className = "";
    } else {
        className = props.className;
    }

    return (
        <div className={"row w-100 BlackSection " + className}>
            {props.children}
        </div>
    );

}

export default BlackSection;