import React from 'react';
import { Link } from 'react-router-dom';
import App from '../../App';
import ApiComponent from '../ApiComponent/ApiComponent';
import { LoadingOverlay, Popup, parseAdressIntoComponents } from '../Utils/Utils';

class ProfileDelivery extends ApiComponent {

    state = {form : <div></div>}

    fillFormData = () => {
        try {
            const localUser = App.LoadLocalStorageUser();
            const localUserData = App.LoadLocalStorageUserData();

            if (!!localUserData) {
                document.querySelector("input[name='name']").value = localUserData.name;
                document.querySelector("input[name='OrgIdentNumber']").value = localUserData.OrgIdentNumber;
                document.querySelector("input[name='VATIdentNumber']").value = localUserData.VATIdentNumber;
                let adressArr = parseAdressIntoComponents(localUserData["residenceaddress_id.street"])
                document.querySelector("input[name='street']").value = adressArr["address"];
                document.querySelector("input[name='cp']").value = adressArr["cp"];
                document.querySelector("input[name='co']").value = adressArr["co"];
                document.querySelector("input[name='residenceaddress_id.City']").value = localUserData["residenceaddress_id.City"];
                document.querySelector("input[name='residenceaddress_id.PostCode']").value = localUserData["residenceaddress_id.PostCode"];
                document.querySelector("input[name='residenceaddress_id.Country']").value = localUserData["residenceaddress_id.Country"];
            } else {
                const userDataPromise = this.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/firms/" + localUser.ID + "?select=name,OrgIdentNumber,VATIdentNumber,residenceaddress_id.street,residenceaddress_id.City,residenceaddress_id.PostCode,residenceaddress_id.Country");
                LoadingOverlay.ApplyOverlay();
                userDataPromise.then((res) => {
                    const userData = res.data;

                    document.querySelector("input[name='name']").value = userData.name;
                    document.querySelector("input[name='OrgIdentNumber']").value = userData.OrgIdentNumber;
                    document.querySelector("input[name='VATIdentNumber']").value = userData.VATIdentNumber;
                    let adressArr = parseAdressIntoComponents(localUserData["residenceaddress_id.street"])
                    document.querySelector("input[name='street']").value = adressArr["address"];
                    document.querySelector("input[name='cp']").value = adressArr["cp"];
                    document.querySelector("input[name='co']").value = adressArr["co"];
                    document.querySelector("input[name='residenceaddress_id.City']").value = userData["residenceaddress_id.City"];
                    document.querySelector("input[name='residenceaddress_id.PostCode']").value = userData["residenceaddress_id.PostCode"];
                    document.querySelector("input[name='residenceaddress_id.Country']").value = userData["residenceaddress_id.Country"];

                    LoadingOverlay.RemoveOverlay();
                });
            }
            
        } catch (error) {
            LoadingOverlay.RemoveOverlay();
            //User not logged in...probably
        }
	}

    registerFormChecked = (e) => {
        if (!e.target.checked) {
            this.setState({form: <div className="mt-3 registerFormCopy">
                <div className="col-12 mb-4 vertical-center"><img className="d-inline" src="/assets/images/adress.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> FAKTURAČNÍ ADRESA</p></div>
                <input inputMode="text" placeholder="JMÉNO / NÁZEV FIRMY" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="IČ" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="DIČ" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="NÁZEV ULICE" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="ČÍSLO POPISNÉ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                <input inputMode="text" placeholder="CÍSLO ORIENTAČNÍ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                <input inputMode="text" placeholder="MĚSTO" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="PSČ" className="basicInput registerInput color-black placeholder-black"></input>
                <input inputMode="text" placeholder="ZEMĚ" className="basicInput registerInput color-black placeholder-black"></input>
            </div>});
        } else {
            this.setState({form: <div></div>})
        }
    }

    componentDidMount = () => {
		this.fillFormData();
    }
    
    saveDeliveryData = () => {
        const user = App.LoadLocalStorageUser();
		if (Object.keys(user).length === 0) {
			Popup.DisplayMessage("Špatně přihlášený uživatel");
			return;
		}

		const userData = App.LoadLocalStorageUserData();
		if (Object.keys(userData).length === 0) {
			Popup.DisplayMessage("Špatně přihlášený uživatel");
			return;
		}

		let newFirmsData = {};
        let userChangedFirmsData = false;
        
		let newProfileData = {};
        let userChangedProfileData = false;
        
        let AddressArr = {};
        
        let formElements = document.querySelector(".registerForm > form").elements;
        formElements.forEach(el => {
            if (el.type !== "checkbox") {
                switch (el.name) {
                    case "OrgIdentNumber":
                    case "VATIdentNumber":
                    case "name":
                    case "residenceaddress_id.City":
                    case "residenceaddress_id.PostCode":
                    case "residenceaddress_id.Country":
                        if (el.value != userData[el.name]) {
                            newProfileData[el.name] = el.value;
                            userChangedProfileData = true;
                        }
                        break;
                    case "street":
                    case "co":
                    case "cp":
                        /*if (el.name != userData[el.name]) {
                            newProfileData[el.name] = el.value;
                        }*/
                        userChangedProfileData = true;
                        switch (el.name) {
                            case "street":
                                AddressArr["street"] = el.value;
                                break;
                            case "co":
                                AddressArr["co"] = el.value;
                                break;
                            case "cp":
                                AddressArr["cp"] = el.value;
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        if (el.value != user[el.name]) {
                            newFirmsData[el.name] = el.value;
                            userChangedFirmsData = true;
                        }
                        break;
                }
            }
        });

		if (!userChangedFirmsData && !userChangedProfileData) {
			return;
        }
        
        LoadingOverlay.ApplyOverlay();
        if (Object.keys(AddressArr).length != 0) {
            newProfileData["street"] = AddressArr["street"] + " " + AddressArr["cp"] + " " + AddressArr["co"];
        }

		let firmsResponse;
		let ProfileResponse;
		if (userChangedFirmsData) {
			firmsResponse = this.putDataFromEndpoint("https://api.abra.slox.cz/BazarCD/firms/" + user.ID + "?select=id", newFirmsData);
		}
		if (userChangedProfileData) {
			ProfileResponse = this.putDataFromEndpoint("https://api.abra.slox.cz/BazarCD/addresses/" + userData.residenceaddress_id + "?select=id", newProfileData);
		}

		Promise.all([firmsResponse, ProfileResponse])
			.then(() => {
                Promise.all([
					this.saveUserFromApi(user.ID),
					this.saveUserDataFromApi(user.ID)
                ]).then(() => {
					this.fillFormData();
					LoadingOverlay.RemoveOverlay();
					Popup.DisplayMessage("Údaje úspěšně změněny");
				});
			});

	}

    render = () => {

        return (
            <div className="cartItems">
                <div className="cartCrumbs Profile">
                    <Link to="/profil" ><div className="cartCrumb"><img className="icon-black" src="/assets/images/user.svg" alt=""/><span>OSOBNÍ ÚDAJE</span></div></Link>
                    <Link to="/profil/dodaci-udaje" ><div className="cartCrumb color-red"><img className="icon-red" src="/assets/images/adress_b.svg" alt=""/><span>DODACÍ ÚDAJE</span></div></Link>
                    <Link to="/profil/veverka" ><div className="cartCrumb"><img src="/assets/images/veverka_b.svg" alt=""/><span>HLÍDACÍ VEVERKA</span></div></Link>
                    <Link to="/profil/objednavky" ><div className="cartCrumb noAfter"><img src="/assets/images/objednavka_b.svg" alt=""/><span>MOJE OBJEDNÁVKY</span></div></Link>
                </div>
                <div className="col-12 homeTileDivider"></div>
                <div className="registerForm mb-0">
                    <form>
                        <div className="col-12 mb-4 vertical-center"><img className="d-inline" src="/assets/images/adress.svg" width="17" height="17" alt=""/><p className="d-inline m-0 ml-2 color-red"> DORUČOVACÍ ADRESA</p></div>
                        <input name="name" inputMode="text" placeholder="JMÉNO / NÁZEV FIRMY" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="OrgIdentNumber" inputMode="text" placeholder="IČ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="VATIdentNumber" inputMode="text" placeholder="DIČ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="street" inputMode="text" placeholder="NÁZEV ULICE" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="cp" inputMode="text" placeholder="ČÍSLO POPISNÉ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                        <input name="co" inputMode="text" placeholder="CÍSLO ORIENTAČNÍ" className="basicInput registerInput half-input color-black placeholder-black"></input>
                        <input name="residenceaddress_id.City" inputMode="text" placeholder="MĚSTO" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="residenceaddress_id.PostCode" inputMode="text" placeholder="PSČ" className="basicInput registerInput color-black placeholder-black"></input>
                        <input name="residenceaddress_id.Country" inputMode="text" placeholder="ZEMĚ" className="basicInput registerInput color-black placeholder-black"></input>

                        <label className="color-red vertical-center"><input className="registerformCheckbox mr-2" type="checkbox" defaultChecked={true} onChange={this.registerFormChecked}></input>FAKTURAČNÍ ADRESA JE STEJNÁ JAKO DORUČOVACÍ ADRESA</label>

                        {this.state.form}

                    </form>
                </div>
                <div className="col-12 homeTileDivider"></div>
                <div className="registerForm bottomPart">
                    <div className="col-12 homeTileDivider"></div>
                    <div className="col-12 p-0 d-flex orderPageButtonWrapper">
                        <Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt=""/>POKRAČOVAT V NÁKUPU</div></button></Link>
                        <button onClick={this.saveDeliveryData} className="registerFormButtonSave vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/save.svg" width="20" height="20" alt=""/>ULOŽIT DODACÍ ÚDAJE</div></button>
                    </div>
                </div>
            </div>
        );

    }

}

export default ProfileDelivery;