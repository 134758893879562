import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './components/Navbar/Navbar.css';
import './components/SplashPage/SplashPage.css';
import './components/Sections/TileSection.css';
import './components/Sections/TripleSection.css';
import './components/Tiles/NewsTile.css';
import './components/Tiles/CDTile.css';
import './components/Tiles/TripleTile.css';
import './components/Sections/BlackSection.css';
import './components/SquirelForm/SquirelForm.css';
import './components/ServiceForm/ServiceForm.css';
import './components/Footer/Footer.css';
import './components/ConstrolUtils/ControlUtils.css';
import './components/Sidebars/Sidebars.css';
import './components/ConstrolUtils/ControlCart.css';
import './components/ConstrolUtils/DisplayCart.css';
import './components/ConstrolUtils/ControlPopup.css';
import './components/Sections/RowSection.css';
import './components/Grid/Grid.css';
import './components/Utils/Utils.css';
import './components/mobileSidebars/mobileSidebars.css';
import './components/Pages/HomePage.css';
import './components/Pages/Track.css';
import './components/RegisterForm/registerForm.css';
import './components/ContactInfo/ContactInfo.css';
import './components/Cart/Cart.css'
import './components/Pages/DeliveryTerms.css';
import './components/Pages/ContactPage.css';
import './components/Pages/SearchPage.css';
import './components/PaymentPageForm/PaymentPageForm.css';
import './components/SummaryPageForm/SummaryPageForm.css';
import './components/Profile/Profile.css';
import './components/ProfileDelivery/ProfileDelivery.css';
import './components/ProfileSquirrel/ProfileSquirrel.css';
import './components/ProfileOrders/ProfileOrders.css';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
