import React, { Component } from 'react';
import RegisterForm from '../RegisterForm/registerForm.js';

import RowSection from '../Sections/RowSection.js';
import { NavbarSpacer } from '../Utils/Utils.js';

class RegisterPage extends Component {

    render = () => {

        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto registerPage container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/user.svg" header="REGISTRACE ZÁKAZNÍKA">
                        <RegisterForm loginFunction={this.props.loginFunction}/>
                    </RowSection>
                </div>
            </div>
        );

    }

}

export default RegisterPage;