import React, { Component } from 'react';

import { NavbarSpacer } from '../Utils/Utils.js';
import RowSection from '../Sections/RowSection.js';
import RegisterFormDelivery from '../RegisterForm/registerFormDelivery.js';

class DeliveryInfoPage extends Component {

    render = (props) => {
        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto cartPage container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/cartIcon.svg" header="DODACÍ ÚDAJE">
                        <RegisterFormDelivery />
                    </RowSection>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        document.querySelector(".cartPage .homeTileDivider").style.display = "none"
    }
}
export default DeliveryInfoPage;