
import ApiComponent from '../ApiComponent';

class ZasilkovnaHandler extends ApiComponent {

    zasilkovnaId = 2010000101;

    getZasilkovnaId = () => {
        return this.zasilkovnaId;
    }
    
    /**
     * returns info on zasilkovna
     * @returns promise
     */
    getInfo = () => {
        const apiResponse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/query", {
            "class": "storecards",
            "select": ["id", "code", "name",
            "StoreMenuItem_ID.Text",
            "X_Interpret_ID.FullName",
            "X_Publisher_ID.Name",
            "X_DateOfPublication",
            "X_Price"
            ],
            "where": ["id eq '" + this.zasilkovnaId + "'"]
          }
        );

        return apiResponse;
    }

}
 
export default ZasilkovnaHandler;