import React, { Component } from 'react';

class tileSection extends Component  {
    
    closId = "";

    render (props) {

        let clos;

        if (this.props.buttonAction === "collapse") {
            this.closId = (Math.random().toString().substr(2));
            clos = <button className="collapseButton hiddenButton" type="button" data-target={this.closId} ><img src="/assets/images/close.svg" className="tileCloseButton" alt="" /></button>
        }

        let news = []
        let tmpNews = [];
        let newChildren = [];

        if (this.props.children !== null && this.props.children !== undefined) {
            if (this.props.children.length > 0) {
                newChildren = this.props.children;
            }
        }

        newChildren.forEach((item, key) => {
            
            item = React.cloneElement(
                item,
                {index: key}
            );

            if ((key + 1) % 2 === 0) {
                tmpNews.push(item);
                tmpNews.push(<div className="col-12 homeTileDivider" key={key + 20000}></div>);
                news.push(<div className="col-12 row p-0 mx-auto tileRow" key={key + 10000}>{tmpNews}</div>);

                tmpNews = [];
            } else {
                tmpNews.push(item);
                tmpNews.push(<div className="homeTileDivider mobile-block" key={key + 50000}></div>);
                if (this.props.children[key + 1] == null) {
                    tmpNews.push(<div className="homeTileVerticalDivider" key={key + 30000}></div>);
                    tmpNews.push(<div className="col-4 m-auto newsTile" key={key + 40000}></div>);

                    news.push(<div className="col-12 row" key={key + 10000}>{tmpNews}</div>);

                    tmpNews.push(<div className="col-12 homeTileDivider" key={key + 20000}></div>);
                    
                    tmpNews = [];
                } else {
                    tmpNews.push(<div className="homeTileVerticalDivider" key={key + 30000}></div>);
                }
            }

        });

        let icon;

        if (!this.props.hideIcon) {
            icon = <img className="tileSectionImage float-left" src={this.props.icon} alt=""/>;
        } else {
            icon = <img className="tileSectionImage revealImage float-left hidden" src={this.props.icon} alt=""/>;
        }

        let ret;

        if ((JSON.stringify(this.props.children) === JSON.stringify([])) || (this.props.children === undefined)) {
            if (this.props.emptyMessage === "" || this.props.emptyMessage === undefined) {
                return <div></div>;
            }
            
            ret = (
                <div className="row tileSection mx-auto">
                    <div className="tileSectionTitle tileSectionWidth">
                        <div>
                            {icon}
                            <h2>| {this.props.header}</h2>
                        </div>
                    </div>
                    <div className="tileSectionWidth" />
                    <div className="col-1 col-lg-5 mr-auto tileSectionTitle">{clos}</div>
                    <div id={this.closId} className="collapsable row w-100">
                        <div className="col-12 homeSectionEmpty">
                            <h2 className="text-center mt-5 mb-2">{this.props.emptyMessage}</h2>
                        </div>
                        <div className="col-12 homeTileDivider"></div>
                    </div>                
                </div>
            );
        } else {
            ret = (
                <div className="row tileSection mx-auto">
                    <div className="row tileSectionTitleWrapper">
                        <div className="tileSectionTitle tileSectionWidth">
                            <div>
                                {icon}
                                <h2>| {this.props.header}</h2>
                            </div>
                        </div>
                        <div className="tileSectionWidth">
                            <div className="closeButtonWrapper">
                                {clos}
                            </div>
                        </div>
                    </div>
                    <div id={this.closId} className="collapsable row mx-auto w-100">
                        {news}
                    </div>
                </div>
            );
        }

        return ret;

    }

}

export default tileSection;