import React from 'react';

const rowSection = (props) => {

    let icon;

    if (props.iconWidth !== undefined) {
        icon = <div className="sectionIconRight"><img src={props.icon} width={props.iconWidth} height={props.iconHeight} className="float-left" alt=""/></div>;
    } else {
        icon = <img className="tileSectionImage revealImage float-left" src={props.icon} alt=""/>;
    }
    


    return (

        <div className="row tileSection mx-auto rowSection">
            <div className="col-10 col-lg-5 rowSectionTitle">
                {icon}
                <h2>| {props.header}</h2>
            </div>
            
            <div className="d-none d-lg-block col-lg-5 mx-auto"></div>
            <div className="col-12 homeTileDivider"></div>
            <div className="collapsable col-12 p-0 row mx-auto w-100">
                {props.children}
            </div>
        </div>

    );

}

export default rowSection;