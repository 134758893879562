import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class ControlPopup extends Component {

    closeElementIDs = ["popupWrapper", "popupConfirm"];

    popupClicked = (e) => {
        if (this.closeElementIDs.includes(e.target.id)) {
            let el = document.querySelector("#popupWrapper");
            el.classList.add("cartPopedDown");
            el.classList.remove("cartPopedUp");
        }
    }

    render = (props) => {

        let text;

        if (!!this.props.text) {
            text = <p id='popupText'>{this.props.text}</p>
        } else {
            text = <p id='popupText'></p>
        }

        return (<div id="popupWrapper" className="cartPopedDown" onClick={this.popupClicked}>
            <div id="popup">
                {text}
                <div id='popupConfirm'>ok</div>
            </div>
        </div>);
    }

}

export default ControlPopup;