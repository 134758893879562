import React from 'react';
import { Link } from 'react-router-dom';
import App from '../../App';
import { LoadingOverlay, localStorageManager, Popup, parseAdressIntoComponents } from '../Utils/Utils';
import Payment from '../Payment/Payment';
import ZasilkovnaHandler from '../ApiComponent/ZasilkovnaHandler/ZasilkovnaHandler';
import DisplayCart from '../ConstrolUtils/DisplayCart';

class SummaryPageForm extends Payment {

    PaymentType_ID = null;

    sale1000 = "10S0000101";
    sale2000 = "20S0000101";

    state = {
        price: 0,
        sale: 0,
        delivery: undefined
    }

    placeOrder = async (e) => {
        e.preventDefault();

        LoadingOverlay.ApplyOverlay();

        //this.MakePaymentTest();
        //return;

        const localUser = App.LoadLocalStorageUser();
        if (Object.keys(localUser).length == 0) {
            //i wanted to insert links into the popup, but that didnt work
            Popup.DisplayMessage(["Pro pokračnování se ", "přihlašte", "/", "zaregistrujte"]);
            return;
        }

        let callBody = {
            PricesWithVAT: true,
            ExternalNumber: "Objednávka z e-shopu č. 1",
            rows: []
        };

        let paymentFormData = localStorageManager.GetLocalStorageItem("paymentFormData", null);
        if (!!paymentFormData.payment) {
            switch (paymentFormData.payment) {
                case "HOTOVOSTÍ":
                    callBody.PaymentType_ID = "2000000101";
                    this.PaymentType_ID = "2000000101";
                    break;
                case "DOBÍRKOU":
                    callBody.PaymentType_ID = "4000000101";
                    this.PaymentType_ID = "4000000101";

                    const dobirkaAmountID = this.state.price < 20000 ? '6070000101' : '7070000101';
                    callBody.rows.push({
                        rowtype: 3,
                        store_id: "2000000101",
                        storecard_id: dobirkaAmountID,
                        quantity: 1,
                        division_id: "1000000101"
                    })
                    break;
                case "PLATEBNÍ KARTOU":
                    callBody.PaymentType_ID = "6000000101";
                    this.PaymentType_ID = "6000000101";
                    break;
                default:
                    Popup.DisplayMessage("Špatně zadaný způsob platby");
                    return;
            }
        } else {
            Popup.DisplayMessage("Špatně zadaný způsob platby");
            return;
        }

        let userId = App.LoadLocalStorageUser().ID;
        if (!!userId) {
            callBody.firm_id = userId;
        } else {
            Popup.DisplayMessage("Pro založení objednávky se musíte přihlásit");
            return;
        }

        let cart = App.LoadLocalStorageCart();
        if (cart.length === 0) {
            Popup.DisplayMessage("Nemáte v košíku žádné položky");
            return;
        }
        cart.forEach((item) => {
            callBody.rows.push({
                rowtype: 3,
			    store_id: "2000000101",
			    storecard_id: item.id,
			    quantity: 1,
			    division_id: "1000000101"
            })
        });

        if (this.state.sale == 1000) {
            callBody.rows.push({
                rowtype: 3,
			    store_id: "2000000101",
			    storecard_id: this.sale1000,
			    quantity: 1,
			    division_id: "1000000101"
            })
        }

        if (this.state.sale == 2000) {
            callBody.rows.push({
                rowtype: 3,
			    store_id: "2000000101",
			    storecard_id: this.sale2000,
			    quantity: 1,
			    division_id: "1000000101"
            })
        }

        const packetaName = localStorageManager.GetLocalStorageItem(localStorageManager.Enums.packetaName);
        if (paymentFormData.delivery.includes("ZÁSILKOVNA")) {

            //doruceni do zasilkovny
            const packeta = localStorageManager.GetLocalStorageItem(localStorageManager.Enums.packetaAddress);

            //nastaveni informaci o zasilkovne
            callBody.x_banchplace = packeta.Place
            callBody.x_barcode = "Z4389044925"
            callBody.x_barcodetext = "0,00"
            callBody.x_branchcity = packeta.City
            callBody.x_branchcountry = packeta.CountryCode
            callBody.x_branchid = packeta.ID
            callBody.x_branchstreet = packeta.Street
            callBody.x_branchzip = packeta.ZIP

            //pridani radku do objednavky
            //Doprava-Z-Point-do-5-kg
            callBody.rows.push({
                rowtype: 3,
			    store_id: "2000000101",
			    storecard_id: '2010000101',
			    quantity: 1,
			    division_id: "1000000101"
            })

        }

        const apiResponse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/receivedorders", callBody);

        if (paymentFormData.delivery.includes("ZÁSILKOVNA")) {
            apiResponse.then(this.finishOrder);
        } else {
            //TODO - add popup saying that the order was created and ask the user if he wants to go to the order page or homepage
            apiResponse.then(this.finishOrder);
        }

    }

    finishOrder = (res) => {
        LoadingOverlay.RemoveOverlay();
        if (res.status === 200 || res.status === 201) {
            //console.log("UNCOMMENT BEFORE PRODUCTION");
            this.props.removeAllItemsFromCart();
            if (this.PaymentType_ID == "6000000101") {
                Popup.DisplayMessage("Proběhne přesměrování na platební bránu");    
                this.MakePayment(res.data.id, true);
            } else {
                window.location.href = "/objednavka/" + res.data.id;
            }
        } else {
            console.log(res.data);
            Popup.DisplayMessage("Stala se chyba, objednávka nebyla vytvořena");
        }
    }

    fillFormData = () => {
        try {
            const localUser = App.LoadLocalStorageUser();
            const localUserData = App.LoadLocalStorageUserData();

            if (!!localUserData) {
                document.querySelector("input[name='name']").value = localUserData.name;
                document.querySelector("input[name='OrgIdentNumber']").value = localUserData.OrgIdentNumber;
                document.querySelector("input[name='VATIdentNumber']").value = localUserData.VATIdentNumber;
                let adressArr = parseAdressIntoComponents(localUserData["residenceaddress_id.street"])
                document.querySelector("input[name='street']").value = adressArr["address"];
                document.querySelector("input[name='cp']").value = adressArr["cp"];
                document.querySelector("input[name='co']").value = adressArr["co"];
                document.querySelector("input[name='residenceaddress_id.City']").value = localUserData["residenceaddress_id.City"];
                document.querySelector("input[name='residenceaddress_id.PostCode']").value = localUserData["residenceaddress_id.PostCode"];
                document.querySelector("input[name='residenceaddress_id.Country']").value = localUserData["residenceaddress_id.Country"];
            } else {
                const userDataPromise = this.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/firms/" + localUser.ID + "?select=name,OrgIdentNumber,VATIdentNumber,residenceaddress_id.street,residenceaddress_id.City,residenceaddress_id.PostCode,residenceaddress_id.Country");
                LoadingOverlay.ApplyOverlay();
                userDataPromise.then((res) => {
                    const userData = res.data;

                    document.querySelector("input[name='name']").value = userData.name;
                    document.querySelector("input[name='OrgIdentNumber']").value = userData.OrgIdentNumber;
                    document.querySelector("input[name='VATIdentNumber']").value = userData.VATIdentNumber;
                    let adressArr = parseAdressIntoComponents(localUserData["residenceaddress_id.street"])
                    document.querySelector("input[name='street']").value = adressArr["address"];
                    document.querySelector("input[name='cp']").value = adressArr["cp"];
                    document.querySelector("input[name='co']").value = adressArr["co"];
                    document.querySelector("input[name='residenceaddress_id.City']").value = userData["residenceaddress_id.City"];
                    document.querySelector("input[name='residenceaddress_id.PostCode']").value = userData["residenceaddress_id.PostCode"];
                    document.querySelector("input[name='residenceaddress_id.Country']").value = userData["residenceaddress_id.Country"];

                    LoadingOverlay.RemoveOverlay();
                });
            }

            //document.querySelector("input[name='residenceaddress_id.email']").value = localUser.EMail;
            document.querySelector("input[name='X_FirstName']").value = localUser.X_FirstName;
            document.querySelector("input[name='X_LastName']").value = localUser.X_LastName;
            document.querySelector("input[name='residenceaddress_id.PhoneNumber1']").value = localUser.PhoneNumber1;
            
        } catch (error) {
            LoadingOverlay.RemoveOverlay();
            //User not logged in...probably
        }
	}

    componentDidMount() {
        let self = this;
        LoadingOverlay.ApplyOverlay();

        this.fillFormData();

        let sale = 0;

        let priceTemp = 0;
        if (!!this.props.items) {
            this.props.items.forEach(function (item) {
                priceTemp += parseInt(item.price.slice(0, -3));
            });
        }

        //decreases the price the user sees and lets other shit know to add the sale items to cart
        //ID: 10S0000101 - 5% off from 1000
        //ID: 20S0000101 - 5% off from 2000
        if (priceTemp > 1000) {
            priceTemp -= 50;
            sale = 1000
        }
        if (priceTemp > 2000) {
            priceTemp -= 50;
            sale = 2000;
        }

        let paymentFormData = localStorageManager.GetLocalStorageItem("paymentFormData", null);

        if (paymentFormData.delivery.includes("ZÁSILKOVNA")) {
            var zasilkovna = new ZasilkovnaHandler();

            zasilkovna.getInfo().then((res) => {
                if (!res.data) {
                    LoadingOverlay.RemoveOverlay();
                    return;
                }
                
                let price = res.data[0]["X_Price"];
                let name = res.data[0]["name"];

                priceTemp += price;

                self.setState({price : priceTemp, sale : sale, delivery: {name: name, price: price}}, LoadingOverlay.RemoveOverlay);

            });
        } else {
            self.setState({price : priceTemp, sale : sale, delivery: undefined}, LoadingOverlay.RemoveOverlay);
        }

    }

    cartClicked = () => {
        DisplayCart.BringUp();
    }

    render = (props) => {
        let paymentFormData = localStorageManager.GetLocalStorageItem("paymentFormData", null);

        let zpusobPlatby;
        let doruceni;

        if (!!paymentFormData) {
            if (!!paymentFormData.payment) {
                zpusobPlatby = paymentFormData.payment;
            } else {
                zpusobPlatby = "NEZADÁNO";
            }
            
            if (!!paymentFormData.delivery) {
                doruceni = paymentFormData.delivery;
            } else {
                doruceni = "NEZADÁNO";
            }
            
        } else {
            zpusobPlatby = "NEZADÁNO";
            doruceni = "NEZADÁNO";
        }

        var saleItem;
        
        if (this.state.sale == 1000) {
            saleItem = (
                <div>
                <p className="cartItemDescCode cartSaleText color-red">SLEVA 50 Kč Z ČÁSTKY 1000,-</p>
                <div className="col-12 homeTileDivider"></div>
            </div>
            );
        }
        if (this.state.sale == 2000) {
            saleItem = (
                <div>
                <p className="cartItemDescCode cartSaleText color-red">SLEVA 100 Kč Z ČÁSTKY 2000,-</p>
                <div className="col-12 homeTileDivider"></div>
            </div>
            );
        }
    
        return (
            <div className="cartItems">
                <div className="cartCrumbs">
                    <Link to="/kosik" ><div className="cartCrumb color-red"><img src="/assets/images/cartIcon.svg" alt="" /><span>KOŠÍK</span></div></Link>
                    <Link to="/udaje" ><div className="cartCrumb color-red"><img className="icon-red" src="/assets/images/user.svg" alt="" /><span>DODACÍ ÚDAJE</span></div></Link>
                    <Link to="/platba" ><div className="cartCrumb color-red"><img className="icon-red" src="/assets/images/delivery.svg" alt="" /><span>DOPRAVA A PLATBA</span></div></Link>
                    <Link to="/shrnuti" ><div className="cartCrumb color-red noAfter"><img className="icon-red" src="/assets/images/total.svg" alt="" /><span>SHRNUTÍ OBJEDNÁVKY</span></div></Link>
                </div>
                <div className="col-12 homeTileDivider"></div>

                <div className="cartItem">
                    <div className="cartItemImageWrapper">
                        <img alt="" className="cartItemImage" src="/assets/images/cd_2.svg" />
                    </div>
                    <div className="cartItemDescWrapper">
                        <Link to="/kosik"><h2 className="cartItemDescHeader">košík</h2></Link>
                        <p id="summaryShowCart" className="cartItemDescText color-black" onClick={this.cartClicked}><img width="16" height="17" src="/assets/images/cd-basket_b.svg" alt=""/>ZOBRAZIT POLOŽKY V KOŠÍKU</p>
                        <p className="cartItemDescCode color-red"><img width="16" height="17" src="/assets/images/suma.svg" alt="" />CELKOVÁ CENA</p>
                        <div className="cartItemMobileImageWrapper">
                            <img alt="" className="cartItemImage" src="/assets/images/cd_2.svg" />
                        </div>
                    </div>
                    <div className="cartItemControlsWrapper">
                        <div className="cartItemControlsPrice color-red">{this.state.price} Kč</div>
                    </div>
                </div>
                <div className="col-12 homeTileDivider"></div>
                {saleItem}
                <div className="registerForm w-100 col-12 p-0 m-0 row">

                    <div className="col-12 mb-4 vertical-center"><img className="d-inline" src="/assets/images/user.svg" width="17" height="17" alt="" /><p className="d-inline m-0 ml-2 color-red"> ÚDAJE ZÁKAZNÍKA</p></div>
                    <input name='X_FirstName' inputMode="text" placeholder="JMÉNO" readOnly={true} className="basicInput registerInput color-black placeholder-black"></input>
                    <input name='X_LastName' inputMode="text" placeholder="PŘIJMENÍ" readOnly={true} className="basicInput registerInput color-black placeholder-black"></input>
                    <input name='residenceaddress_id.PhoneNumber1' inputMode="text" readOnly={true} placeholder="TELEFON" className="basicInput registerInput color-black placeholder-black"></input>

                    <div className="col-12 mb-4 vertical-center"><img className="d-inline" src="/assets/images/adress.svg" width="17" height="17" alt="" /><p className="d-inline m-0 ml-2 color-red"> DORUČOVACÍ ADRESA</p></div>
                    <input name='name' inputMode="text" placeholder="JMÉNO / NÁZEV FIRMY" readOnly={true} className="basicInput registerInput color-black placeholder-black"></input>
                    <input name='OrgIdentNumber' inputMode="text" placeholder="IČ" readOnly={true} className="basicInput registerInput color-black placeholder-black"></input>
                    <input name='VATIdentNumber' inputMode="text" placeholder="DIČ" readOnly={true} className="basicInput registerInput color-black placeholder-black"></input>
                    <input name='street' inputMode="text" placeholder="NÁZEV ULICE" readOnly={true} className="basicInput registerInput color-black placeholder-black"></input>
                    <input name='cp' inputMode="text" placeholder="ČÍSLO POPISNÉ" readOnly={true} className="basicInput registerInput half-input color-black placeholder-black"></input>
                    <input name='co' inputMode="text" placeholder="CÍSLO ORIENTAČNÍ" readOnly={true} className="basicInput registerInput half-input color-black placeholder-black"></input>
                    <input name='residenceaddress_id.City' inputMode="text" placeholder="MĚSTO" readOnly={true} className="basicInput registerInput color-black placeholder-black"></input>
                    <input name='residenceaddress_id.PostCode' inputMode="text" placeholder="PSČ" readOnly={true} className="basicInput registerInput color-black placeholder-black"></input>
                    <input name='residenceaddress_id.Country' inputMode="text" placeholder="ZEMĚ" readOnly={true} className="basicInput registerInput color-black placeholder-black"></input>

                </div>

                <div className="col-12 homeTileDivider"></div>

                <div className="w-100 col-12 p-0 m-0 row justify-content-between">
                    <div className="col-4 deliveryTitle mb-4 vertical-center"><img className="d-inline" src="/assets/images/wallet.svg" width="17" height="17" alt="" /><p className="d-inline m-0 ml-2 color-red">ZPŮSOB PLATBY</p></div>
                    <div className="col-4 deliveryTitle max-only mb-4 vertical-center"><img className="d-inline icon-red" src="/assets/images/delivery.svg" width="17" height="17" alt="" /><p className="d-inline m-0 ml-2 color-red">ZPŮSOB DORUČENÍ</p></div>
                    <div className="col-4 desktopSpacer"></div>
                    <label className="position-relative deliveryLabel large basicInput color-black placeholder-black">{zpusobPlatby}<input type="radio" name="payment" checked={true}></input></label>
                    <label className="position-relative max-only deliveryLabel basicInput color-black placeholder-black">{doruceni}<input type="radio" name="delivery" checked={true}></input></label>
                    <div className="desktopSpacer deliveryLabel large invisible"></div>

                    <div className="min-only col-12 p-0 mt-2">
                        <div className="col-4 deliveryTitle mb-4 vertical-center"><img className="d-inline icon-red" src="/assets/images/delivery.svg" width="17" height="17" alt="" /><p className="d-inline m-0 ml-2 color-red">ZPŮSOB DORUČENÍ</p></div>
                        <label className="position-relative deliveryLabel basicInput color-black placeholder-black">{doruceni}<input type="radio" name="delivery2" checked></input></label>
                    </div>
                </div>

                <div className="col-12 homeTileDivider"></div>

                <div className="col-12 p-0 d-flex orderPageButtonWrapper">
                    <Link to="/platba"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0">ZPĚT</div></button></Link>
                    <Link to="/"><button className="registerFormButtonContinue vertical-center"><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/shopping-bag_r.svg" width="20" height="20" alt="" />POKRAČOVAT V NÁKUPU</div></button></Link>
                    <button className="registerFormButtonSave vertical-center" onClick={this.placeOrder}><div className="mx-auto mx-lg-0"><img className="mr-2" src="/assets/images/sent-mail.svg" width="20" height="20" alt="" />ODESLAT OBJEDNÁVKU</div></button>
                </div>

                <div className="my-5 py-5"></div>
                <DisplayCart items={this.props.items} delivery={this.state.delivery}/>
            </div>
        );

    }

}

export default SummaryPageForm;