import React, { Component } from 'react';
import { NavbarSpacer } from '../Utils/Utils.js';
import ServiceForm from '../ServiceForm/ServiceForm.js';


class ServicePage extends Component {

    render = () => {

        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto servicePage miniPage container">
                    <NavbarSpacer />
                    <ServiceForm />
                </div>
            </div>
        );

    }

}

export default ServicePage;