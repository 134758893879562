import React, { Component } from 'react';
import axios from 'axios';
import App from '../../App';
import { LoadingOverlay } from '../Utils/Utils';

class ApiComponent extends React.Component {
    
    postDataFromEndpoint = async(endpoint, requestBody) => {
        const headers = {
            "Authorization": "Basic ZXNob3A6ZVAxbi0yaEh1NDg5Xw==",
            "Accept": "*/*"
        }
        const result = await axios.post(endpoint, requestBody, {headers: headers});
        return result;
    }

    postDataFromEndpointExternal = async(endpoint, requestBody, headers) => {
        const defaultHeaders = {
            ...headers
        }
        const result = await axios.post(endpoint, requestBody, {headers: defaultHeaders});
        return result;
    }

    putDataFromEndpoint = async(endpoint, requestBody) => {
        const headers = {
            "Authorization": "Basic ZXNob3A6ZVAxbi0yaEh1NDg5Xw==",
            "Accept": "*/*"
        }
        const result = await axios.put(endpoint, requestBody, {headers: headers});
        return result;
    }

    getDataFromEndpoint = async(endpoint) => {
        const headers = {
            "Authorization": "Basic ZXNob3A6ZVAxbi0yaEh1NDg5Xw==",
            "Accept": "*/*"
        }
        const result = await axios.get(endpoint, {headers: headers});
        return result;
    }

    getDataFromEndpoint = async(endpoint, headers) => {
        const deafaultHeaders = {
            "Authorization": "Basic ZXNob3A6ZVAxbi0yaEh1NDg5Xw==",
            "Accept": "*/*",
            ...headers
        }
        const result = await axios.get(endpoint, {headers: deafaultHeaders});
        return result;
    }

    saveUserDataFromApi = (userID) => {
        const userDataPromise = this.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/firms/" + userID + "?select=name,OrgIdentNumber,VATIdentNumber,residenceaddress_id,residenceaddress_id.street,residenceaddress_id.City,residenceaddress_id.PostCode,residenceaddress_id.Country");
        userDataPromise.then((res) => {
            App.SetLocalStorageUserData(res.data);
        });
        return userDataPromise;
    }

    saveUserFromApi = (userID) => {
        const userPromise = this.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/firms/" + userID + "?select=Code,residenceaddress_id.EMail,ID,Name,residenceaddress_id.PhoneNumber1,X_FirstName,X_LastName,X_Suffix,X_Title");
        userPromise.then((res) => {
            res.data["EMail"] = res.data["residenceaddress_id.EMail"];
            delete(res.data["residenceaddress_id.EMail"]);
            res.data["PhoneNumber1"] = res.data["residenceaddress_id.PhoneNumber1"];
            delete res.data["residenceaddress_id.PhoneNumber1"];
            App.SetLocalStorageUser(res.data);
        });
        return userPromise;
    }

    componentWillUnmount = () => {
        LoadingOverlay.RemoveOverlay();
    }

}
 
export default ApiComponent;