import React from 'react';

const Sharer = (props) => {

    return (

        <div className="CDTileShareBody">
            <a className="CDTileShareItem" href={"http://twitter.com/share?text=" + encodeURIComponent(window.location.hostname) + props.link} target="_blank" rel="noopener">
                Twitter
            </a>
            <a className="CDTileShareItem" href={'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.hostname) + props.link} target="_blank" rel="noopener">
                Facebook
            </a>
        </div>

    );

}

export default Sharer;