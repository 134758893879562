import { Component } from 'react';

class FastShopAnimations extends Component {

    componentDidMount () {
        this.hideItemCount();
    }

    componentWillUnmount () {
        this.unbind();
    }

    innerHideItemCount (e) {
        if (!e.target.classList.contains("GridSelect")) {
            document.querySelector(".GridSelect").classList.remove("show");
        }
    }

    hideItemCount () {
        document.addEventListener("click", this.innerHideItemCount);
    }

    unbind () {
        document.removeEventListener("click", this.innerHideItemCount)
    }

    render () {
        return null;
    }

}

export default FastShopAnimations;