import React, { Component } from 'react';

import ApiComponent from '../ApiComponent/ApiComponent.js';

import { Link } from "react-router-dom";
import { LoadingOverlay, NavbarSpacer } from '../Utils/Utils.js';
import BlackSection from '../Sections/BlackSection.js';
import $ from 'jquery';
import Sharer from '../Sharer/Sharer.js';


class Track extends ApiComponent {

    state = {
        trackId : this.props.trackId,
        bandName : "Načítání",
        album : " ",
        price : "Načítání",
        year : " ",
        liveData : "",
        trackIndex : 0,
        trackName : [],
        songPath : [],
        songDuration : 0,
        imagePath : "/assets/images/newsImage.jpg",
        cdImagePath : "/assets/images/cd-ico.svg",
        relatedTitles : [],
        player: {
            additionalControlClasses: ["d-none"],
            additionalListClasses: [""]
        }
    }

    relatedTitles = [];

    /*
        trackId : this.props.trackId,
        bandName : "Načítání",
        album : " ",
        price : "Načítání",
        year : " ",
        liveData : "34 people are looking at this",
        trackIndex : 0,
        trackName : ["02 the game of love", "03 giorgio by moroder", "04 within", "08 I got lucky"],
        songPath : ["/assets/audio/02-the-game-of-love.mp3", "/assets/audio/03-giorgio-by-moroder.mp3", "/assets/audio/04-within.mp3",  "/assets/audio/08-get-lucky.mp3"],
        songDuration : 0,
        imagePath : "https://freesvg.org/img/Loading_icon_no_fade.png",
        cdImagePath : "/assets/images/cd-ico.svg",
        relatedTitles : [{link : "/juju", imagePath: "https://freesvg.org/img/Loading_icon_no_fade.png"}, {link : "/juju", imagePath: "/assets/images/daftpunk.jpg"}, {link : "/juju", imagePath: "/assets/images/daftpunk.jpg"}, {link : "/juju", imagePath: "/assets/images/daftpunk.jpg"}]
    */

    mouseDownOnVolumeSLider = false;
    volumeBeforeToggle = 0;

    share_fb = (url) => {
        window.open('https://www.facebook.com/sharer/sharer.php?u='+ encodeURIComponent(window.location.hostname) + url,'facebook-share-dialog',"width=626, height=436");
    }

    secondsToMinutes = (s) => {return(s-(s%=60))/60+(9<s?':':':0')+s}

    changeSongTime = (e) => {
        document.querySelector("#trackPageAudio").currentTime = e.target.value;
        this.updateTimeLine();
        this.updateSongTime();
    }
    
    getSongDuration = () => {
        try {
            return Math.floor(document.querySelector("#trackPageAudio").duration);
        } catch (error) {
            return 0
        }
    }

    getSongTime = () => {
        try {
            return Math.floor(document.querySelector("#trackPageAudio").currentTime);
        } catch (error) {
            return 0
        }
    }

    updateSongTime = () => {
        document.querySelector("#audioTime").innerHTML = this.secondsToMinutes(this.getSongTime()) + " / " + this.secondsToMinutes(this.state.songDuration);
    }

    updateSongTimePeriodically = (time) => {
        let el = document.querySelector("#audioTime");
        setInterval(() => {
            el.innerHTML = this.secondsToMinutes(this.getSongTime()) + " / " + this.secondsToMinutes(this.state.songDuration);
        }, time);
    }

    updateTimeLine = () => {
        let el = document.querySelector("#audioTimeline");
        let red = document.querySelector("#audioTimeLineRed");
        //let grey = document.querySelector("#audioTimeLineGrey"); //meant to show buffer
        el.value = this.getSongTime();
        let percentage = Number(this.getSongTime() / this.getSongDuration()).toString()
        red.style.width = percentage.substring(2).slice(0, 2) + "." + percentage.substr(2).slice(2) + "%";
    }

    setVolumeLevel = (volume) => {
        document.querySelector("#trackPageAudio").volume = volume;
        volume = volume * 100;
        document.querySelector("#audioVolumeRed").style.width = volume + "%";
    }

    changeSongVolume = (e) => {
        let volume = e.target.value / 100;
        this.setVolumeLevel(volume);
        this.volumeState(volume);
        if (volume === 0) {
            document.querySelector("#audioVolumeImage").classList.add("muted");
        } else {
            document.querySelector("#audioVolumeImage").classList.remove("muted");
        }
        
    }

    volumeState = (inpVolume = -1) => {
        if (inpVolume === -1) {
            let volume = window.localStorage.getItem("CDAudio");
            if (volume === null) {
                return -1;
            } else {
                return volume;
            }
        } else {
            window.localStorage.setItem("CDAudio", inpVolume);
        }
    }


    updateTimeLinePeriodically = (time) => {
        let el = document.querySelector("#audioTimeline");
        let red = document.querySelector("#audioTimeLineRed");
        //let grey = document.querySelector("#audioTimeLineGrey"); //meant to show buffer
        setInterval(() => {
            el.value = this.getSongTime();
            let percentage = Number(this.getSongTime() / this.getSongDuration()).toString()
            red.style.width = percentage.substring(2).slice(0, 2) + "." + percentage.substr(2).slice(2) + "%";
        }, time);
    }

    playerPlay = () => {
        try {
            //document.querySelector("#trackPageAudio").play();
            document.querySelector(".audioToggle").src = "/assets/images/musicicons_pause-white.png";
            document.querySelector(".audioToggle").classList.add("trackPagePulsing");
        } catch (error) {
        }
    }

    playerPause = () => {
        document.querySelector("#trackPageAudio").pause();
        document.querySelector(".audioToggle").src = "/assets/images/musicicons_play-white.png";
        document.querySelector(".audioToggle").classList.remove("trackPagePulsing");
    }

    playerToggle = () => {
        if (document.querySelector("#trackPageAudio").paused) {
            this.playerPlay();
        } else {
            this.playerPause();
        }
    }

    updateSongListIcon = () => {
        let songListItems = document.querySelectorAll(".songList > .songListItem");
        songListItems.forEach(function(item) {
            item.classList.remove("playing");
            item.classList.remove("paused");
        });
        if (document.querySelector("#trackPageAudio").paused) {
            songListItems[this.state.trackIndex].classList.add("paused");
        } else {
            songListItems[this.state.trackIndex].classList.add("playing");
        }
    }

    previousSong = () => {
        if (this.state.trackIndex === 0) {
            this.setState({trackIndex : this.state.songPath.length - 1});
        } else {
            this.setState({trackIndex : this.state.trackIndex - 1});
        }

        document.querySelector("#trackPageAudio").load()
        document.querySelector("#trackPageAudio").addEventListener("canplaythrough", () => {
            this.setState({songDuration : this.getSongDuration()});
            this.updateSongTime();
            this.updateTimeLine();
            this.playerPlay();
            this.updateSongListIcon();
        }, {once: true});
        
    }
    
    nextSong = () => {
        if ((this.state.trackIndex + 1) > (this.state.songPath.length - 1)) {
            this.setState({trackIndex : 0});
        } else {
            this.setState({trackIndex : (this.state.trackIndex + 1)});
        }

        document.querySelector("#trackPageAudio").load()
        document.querySelector("#trackPageAudio").addEventListener("canplaythrough", () => {
            this.setState({songDuration : this.getSongDuration()});
            this.updateSongTime();
            this.updateTimeLine();
            this.playerPlay();
            this.updateSongListIcon();
        }, {once: true});

    }

    playSong = (index) => {

        this.setState({trackIndex : index});
        
        document.querySelector("#trackPageAudio").load()
        document.querySelector("#trackPageAudio").addEventListener("canplaythrough", () => {
            this.setState({songDuration : this.getSongDuration()});
            this.updateSongTime();
            this.updateTimeLine();
            this.playerPlay();
            this.updateSongListIcon();
        }, {once: true});

    }

    innerNewsTextScroll = (item) => {
        if (item.textContent == "")
            return 0;

        let top_of_element = $(item).offset().top;
        let bottom_of_element = $(item).offset().top + $(item).outerHeight();
        let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        let top_of_screen = $(window).scrollTop() + 150;
    
        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
            $(item).get(0).classList.add("popedUp");
            $(item).get(0).classList.remove("popedDown");
        } else {                    
            $(item).get(0).classList.add("popedDown");
            $(item).get(0).classList.remove("popedUp");
        }
    }

    slideUp = (selector = ".newsText") => {
        $(selector).get().forEach(item => {
            window.addEventListener("scroll", this.innerNewsTextScroll.bind(null, item));
        });
    }

    volumeWrapperMouseDown = (e) => {
        e.preventDefault();
        this.mouseDownOnVolumeSLider = true;
        let source = e.nativeEvent.target || e.nativeEvent.srcElement;
        if (source.id !== "")
            source = e.nativeEvent.srcElement.parentElement;
        this.changeSongVolume({target: {"value": e.nativeEvent.layerX / source.offsetWidth * 100}});
    }

    volumeWrapperMouseMove = (e) => {
        if (this.mouseDownOnVolumeSLider) {
            let source = e.nativeEvent.target || e.nativeEvent.srcElement;
            if (source.id !== "")
                source = e.nativeEvent.srcElement.parentElement;
            this.changeSongVolume({target: {"value": e.nativeEvent.layerX / source.offsetWidth * 100}});
        }
    }

    volumeWrapperMouseUp = (e) => {
        if (this.mouseDownOnVolumeSLider) {
            this.mouseDownOnVolumeSLider = false;
            let source = e.nativeEvent.target || e.nativeEvent.srcElement;
            if (source.id !== "")
                source = e.nativeEvent.srcElement.parentElement;
            this.changeSongVolume({target: {"value": e.nativeEvent.layerX / source.offsetWidth * 100}});
        }
    }

    toggleVolume = () => {
        if (window.localStorage.getItem("CDAudio") === "0") {
            this.changeSongVolume({target: {"value": this.volumeBeforeToggle * 100}});
        } else {
            this.volumeBeforeToggle = window.localStorage.getItem("CDAudio");
            this.changeSongVolume({target: {"value": 0}});
        }
    }

    componentDidMount = () => {
        let self = this;
        let fired = false;
        if (!fired) {
            document.querySelector("#trackPageAudio").addEventListener("canplaythrough", () => {
                this.setState({songDuration : this.getSongDuration()});
                this.updateSongTimePeriodically(495);
                this.updateTimeLinePeriodically(495);
                fired = true;
                this.updateSongListIcon();
            }, {once: true});
        }

        this.slideUp();
        $(this.playerPlay);
        document.querySelector("#trackPageAudio").addEventListener("ended", this.nextSong);

        let volume = this.volumeState();
        if (volume === -1) {
            this.setVolumeLevel(0.50);
        } else {
            this.setVolumeLevel(volume);
        }

        const getItemIcon = (iconString) => {
            if (iconString == "CD")
              return "cd-ico.svg";
            else
              return "dvd-ico.svg"
          }

        LoadingOverlay.ApplyOverlay();

        const response = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/query", {
            "class": "storecards",
            "select": ["id", "code", "name",
            "StoreMenuItem_ID.Text",
            "X_Interpret_ID.FullName",
            "X_Interpret_ID",
            "X_Publisher_ID.Name",
            "X_DateOfPublication",
            "X_Price",
            "Picture_ID"
            ],
            "where": ["id eq '" + this.state.trackId + "'","Hidden eq false", "X_PublishEshop eq 'A'"],
            "take": 1
          }
        );

        response.then((res) => {
            const data = res.data[0];

            if (!data) {
                LoadingOverlay.RemoveOverlay();
                //TODO redirect to 404
            }
            
            if (data["X_Interpret_ID.FullName"] == undefined || data["X_Interpret_ID.FullName"] == null) {
                const interpretResponse = this.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/persons/" + data.x_interpret_id);
                interpretResponse.then((intRes) => {
                    this.setState({bandName : intRes.data.X_Interpret});
                }).catch((e) => {});
            }

            let imgSrc = "/assets/images/newsImage.jpg";
            this.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/script/API/StoreCards/GetPicture?STORECARD=" + data.id)
                .then((pic) => {
                    let base64Image = pic.data;
                    this.setState({imagePath:"data:image/png;base64," + base64Image});

                }).catch((e) => {
                    this.setState({imagePath:"/assets/images/newsImage.jpg"});
                })

            /*let additionalPlayerClasses = this.state.player.additionalClasses;
            if (typeof data.musicSamples != "object")
                additionalPlayerClasses.concat(["d-none"]);
            else if (data.musicSamples.length == 0)
                additionalPlayerClasses.concat(["d-none"]);*/

            let yearData = "";
            
            if (data.X_DateOfPublication) {
                yearData = "Rok vydání: " + data.X_DateOfPublication.substring(0, 4);
            }

            this.setState({
                bandName : data["X_Interpret_ID.FullName"],
                album : data.name,
                price : data["X_Price"] + " Kč",
                year : yearData,
                trackIndex : 0,
                trackName : [],
                songPath : [],
                songDuration : 0,
                imagePath : imgSrc,
                cdImagePath : "/assets/images/" + getItemIcon(data["StoreMenuItem_ID.Text"]),
                relatedTitles : []/*,
                player: {
                    additionalClasses: additionalPlayerClasses
                }*/
            }, () => {
                document.querySelectorAll(".trackPageAddToCartButton").forEach(element => {
                    element.addEventListener("click", (event) => this.props.AddToCartHandler({id:this.state.trackId, bandName: this.state.bandName, albumName: this.state.album, price: this.state.price}, event));
                    LoadingOverlay.RemoveOverlay();
                });
            });

            if (data["X_Interpret_ID"] !== undefined || data["X_Interpret_ID"] !== null) {
                const responseRelated = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/query", {
                    "class": "storecards",
                    "select": [
                        "id",
                        "displayname",
                        "Picture_ID.blobdata"
                    ],
                    "where": [
                        "Hidden eq false",
                        "X_PublishEshop eq 'A'",
                        "id ne '" + data.id + "'",
                        "X_Interpret_ID eq '" + data["X_Interpret_ID"] + "'"
                    ],
                    "take": 4
                  }
                );

                
                responseRelated.then((resRelated) => {
                    let promises = [];
                    const data = resRelated.data;
                    
                    data.forEach(element => {
                        promises.push(self.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/script/API/StoreCards/GetPicture?STORECARD=" + element.id)
                            .then((pic) => {
                                self.relatedTitles.push({link : "/track/" + element.id, imagePath: "data:image/png;base64," + pic.data, alt: element.displayname})
                            }).catch((e) => {
                                self.relatedTitles.push({link : "/track/" + element.id, imagePath: "/assets/images/newsImage.jpg", alt: element.displayname})
                            })
                        )

                    });
    
                    Promise.all(promises).then(() => {self.setState({ relatedTitles: self.relatedTitles })});
                });

            }

            self.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/script/API/StoreCards/GetTrackList?STORECARD=" + data.id)
            .then((res) => {
                if (!res.data.TrackList) {
                    return;
                }

                let newTracks = [];
                res.data.TrackList.forEach(track => {
                    newTracks.push(track.Title);
                })

                self.setState({ trackName: newTracks });

            });

        });

    }

    redirectTo = (url) => {
        window.location.href = url;
    }

    render = (props) => {

        let self = this;
        let songList = [];
        let i = 0;

        this.state.trackName.forEach(function(item) {
            songList.push(<p key={i} onClick={this.playSong.bind(this, i)} className="songListItem">{item}</p>);
            i++;
        }.bind(this));

        let relatedList = [];
        i = 1000;
        
        this.state.relatedTitles.forEach(function(item) {
            relatedList.push(<Link onClick={self.redirectTo.bind(this, item.link)} to={item.link} className="relatedTitleWrapper" key={i} alt={"Odkaz na položku " + item.alt}><div  className="relatedTitle"><img src={item.imagePath} alt={item.alt} /></div></Link>);
            i++;
        });

        let comment = false;
        /*if (this.state.comment) {
            comment = (
                <div className="commentPopupWapper revealImage hidden">
                    <div className="commentPopupInner">
                        {this.state.comment}
                    </div>
                </div>
            );
        }*/
        if (comment) {
            comment = (
                <div className="commentPopupWapper">
                    <div className="commentPopupInner">
                        lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    </div>
                </div>
            );
        }
        
        return (
            <div>
                <div className="row container-fluid m-0">
                    <BlackSection>
                        <div className="mx-auto row p-0 trackPage mt-5 container">
                            <NavbarSpacer />
                            <div className="trackPlayerBlackSection p-0 mb-5">
                                <div className="trackPlayerWrapper">
                                    <div className="newsText popedDown"><img src="/assets/images/tileEye.svg" alt="" />{this.state.liveData}</div>
                                    <img className="trackPlayerImage" src={this.state.imagePath} alt="Obrázek alba"/>
                                    <img src={this.state.cdImagePath} className="CDDiscIcon" alt="" />
                                    <div className="CDTileShare">
                                        <img className="revealImage" src="/assets/images/share.svg"  alt="tlačítko pro sdílení" />
                                        <Sharer link={this.props.link} />
                                    </div>
                                    <div className={"audioControls " + this.state.player.additionalControlClasses.join(" ")}>
                                        <div className="audioPlay">
                                            <img className="audioPrevious" onClick={this.previousSong} src="/assets/images/musicicons_previous-white.png" alt="minulá písnička"/>
                                            <div className="audioToggleWrapper"><img className="audioToggle" onClick={() => {this.playerToggle(); this.updateSongListIcon();}} src="/assets/images/musicicons_play-white.png" alt="spustit nebo zastavit pisničku"/></div>
                                            <img className="audioNext" onClick={this.nextSong} src="/assets/images/musicicons_next-white.png" alt="příští písnička"/>
                                        </div>
                                        <div className="audioTimelineWrapper">
                                            <input id="audioTimeline" className="audioTimeline" onChange={this.changeSongTime} type="range" min="0" max={this.state.songDuration}/>
                                            <div id="audioTimeLineRed"></div>
                                            <div id="audioTimeLineGrey"></div>
                                            <div id="audioTime" className="audioTime"></div>
                                        </div>
                                        <div className="audioVolumeWrapper">
                                            <div id="audioVolumeImage" onClick={this.toggleVolume}/>
                                            <div className="audioVolumeSliderWrapper" onMouseDownCapture={this.volumeWrapperMouseDown} onMouseUpCapture={this.volumeWrapperMouseUp} onMouseMoveCapture={this.volumeWrapperMouseMove}>
                                                <input id="audioVolume" className="audioVolume" onChange={this.changeSongVolume} type="range" min="0" max="100"/>
                                                <div id="audioVolumeRed"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <audio id="trackPageAudio" className={"trackPlayerAudio invisible " + this.state.player.additionalControlClasses.join(" ")} controls controlsList="nodownload">
                                        <source src={this.state.songPath[this.state.trackIndex]} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            </div>
                            <div className="p-0 trackInfo">
                                <h2>{this.state.bandName}</h2>
                                <h3 className="mt-5">{this.state.album}</h3>
                                <h3 className="mt-4 pb-3">{this.state.year}</h3>
                                <h4 className="mt-4 d-none">{this.state.trackName[this.state.trackIndex]}</h4>
                                {comment}
                                
                                <div className="CDTilePriceWrapper">
                                    <div className="CDTilePrice">
                                        <div /*onClick={(event)=>this.props.AddToCartHandler({id:this.state.trackId, bandName: this.props.bandName, albumName: this.props.album, price: this.props.price}, event)}*/ data-id={this.state.trackId} className="trackPageAddToCartButton addageButton w-100 h-100 overflow-hidden position-relative rounded-circle">
                                            <div className="CDTilePriceInner">
                                                {this.state.price}
                                                <br />
                                                <br />
                                                DO KOŠÍKU
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BlackSection>
                    <div className="mx-auto row trackPage mt-5 p-0 container">
                        <div className="col-lg-5 p-0 trackInfo mobile">
                            <h2>{this.state.bandName}</h2>
                            <h3 className="mt-5">{this.state.album}</h3>
                            <h3 className="mt-4 pb-3">{this.state.year}</h3>
                            <h4 className="mt-4 d-none">{this.state.trackName[this.state.trackIndex]}</h4>
                            {comment}
                            
                            <div className="CDTilePriceWrapper">
                                <div className="CDTilePrice">
                                    <div /*onClick={(event)=>this.props.AddToCartHandler({id:this.state.trackId, bandName: this.props.bandName, albumName: this.props.album, price: this.props.price}, event)}*/ data-id={this.state.trackId} className="trackPageAddToCartButton addageButton w-100 h-100 overflow-hidden position-relative rounded-circle">
                                        <div className="CDTilePriceInner">
                                            {this.state.price}
                                            <br />
                                            <br />
                                            DO KOŠÍKU
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="trackPageSectionDivider" />
                        <div className="trackPageSongList">
                            <div className={"songList " + this.state.player.additionalListClasses.join(" ")}>
                                <img className="songListImage" alt="" src="/assets/images/cd-new.svg" />
                                <h4 className="songListTitle">| SONG LIST</h4>
                                {songList}
                            </div>
                        </div>
                        <div className="trackPageSectionDivider" />
                        <div className="trackPageRelatedList">
                            <div className="col-12 col-lg-6 row relatedTitles">
                                <div className="songListTitleWrapper">
                                    <img className="songListImage mr-1" alt="" src="/assets/images/cd-new.svg" />
                                    <h4 className="songListTitle">| DALŠÍ TITULY</h4>
                                </div>
                                <div className="row mx-0 mt-5 w-100">
                                    {relatedList}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

export default Track;