import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

class navbar extends Component {

    scrollToTop = () => {
        if (window.location.pathname === "/") {
            window.scrollTo(0, 0);
        }
    }

    cartClicked () {
        let el = document.querySelector("#popupCartWrapper");
        el.classList.add("cartPopedUp");
        el.classList.remove("cartPopedDown");
    }

    render = (props) =>{

        let userDesktopElement;
        let userMobileElement;

        if (!this.props.userName) {
            userDesktopElement = <HashLink className="nav-item nav-link navbarLink" to="/prihlaseni"><img src="/assets/images/login_desktop.png" alt="Přihlásit" /></HashLink>
            userMobileElement = <img className="mobile-bring-out-login" src="/assets/images/login.png" alt="" />
        } else {
            userDesktopElement = <Link className="nav-item nav-link navbarLink profile" to="/profil"><img src="/assets/images/user_w.svg" alt="Profil" />&nbsp;&nbsp;{this.props.userName}</Link>
            userMobileElement = <Link to="/profil"><img src="/assets/images/user.svg" alt="Profil" /></Link>
        }

        let count = this.props.itemCount;

        let cartCountCounter = (<p id='navbarItemCount' onClick={this.cartClicked} className='d-none'></p>);
        if (count != 0) {
            cartCountCounter = (<p id='navbarItemCount' onClick={this.cartClicked}>⬤</p>);
        }

        return (
            <nav id="navbar" className="navbar navbar-expand-lg w-100 fixed-top">
                <div className="desktop-navbar">
                    <div className="navbarBG"></div>
                    <HashLink onClick={this.scrollToTop} className="navbar-brand" to="/#"><img src="/assets/images/cd-logo-5.svg" alt="cd bazar logo a odkaz na hlavní stránku"/></HashLink>
                    <div className="navbar-items navbar-nav ml-auto">
                        <HashLink className="nav-item nav-link navbarLink" to="/sluzby"><img src="/assets/images/sluzby_desktop.png" alt="Služby" /></HashLink>
                        <Link className="nav-item nav-link navbarLink" to="/zkracene/0/10"><img src="/assets/images/shopzkracene_desktop.png" alt="E-Shop Zkráceně" /></Link>
                        <HashLink className="nav-item nav-link navbarLink" to="/novinky" ><img src="/assets/images/novinky_desktop.png" alt="Novinky" /></HashLink>
                        <HashLink className="nav-item nav-link navbarLink" to="/veverka"><img src="/assets/images/veverka_desktop.png" alt="Hlídací Veverka" /></HashLink>
                        <Link className="nav-item nav-link navbarLink" to="/Podminky"><img src="/assets/images/podminky_desktop.png" alt="Dodací Podmínky" /></Link>
                        <Link className="nav-item nav-link navbarLink" to="/kontakt"><img src="/assets/images/kontakt_desktop.png" alt="Kontakt" /></Link>
                        {userDesktopElement}
                        {cartCountCounter}
                        <img className="nav-item nav-link navbarLink navbar-svg" onClick={this.cartClicked} src="/assets/images/kosik.svg" alt="kosik" />
                    </div>
                </div>
                <div className="mobile-navbar">
                    <div className="navbarBG"></div>
                    <HashLink onClick={this.scrollToTop} className="navbar-brand" to="/#"><img src="/assets/images/cd-logo-5.svg" alt="cd bazar logo a odkaz na hlavní stránku"/></HashLink>
                    <div className="mobile-navbar-images" >
                        <img className="mobile-bring-out-search" src="/assets/images/eyeglass.svg" alt="" />
                        <div onClick={this.cartClicked} className="mobile-cart-count">
                            <p>{count}</p>
                            <img src="/assets/images/cart.svg" alt="" />
                        </div>
                        {userMobileElement}
                        <img className="mobile-bring-out-menu" src="/assets/images/menu.svg" alt="" />
                    </div>
                </div>
            </nav>
        )
    
    }

}

export default navbar;