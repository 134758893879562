import React from 'react';

import { LoadingOverlay, NavbarSpacer, Popup } from '../Utils/Utils.js';
import RowSection from '../Sections/RowSection.js';
import Order from '../Order/Order.js';
import ApiComponent from '../ApiComponent/ApiComponent.js';

class OrderPage extends ApiComponent {

    state = {
        items : [
            //{id: "1", bandName: "DAFT PUNK", albumName: "Random Access Memories", price: "250", link: "/track/1", code: "9875345436654", image: "/assets/images/motorhead.jpg"},
            //{id: "0", bandName: "DAFT PUNK DAFT", albumName: "Random Access Memories Random Access Memories Random Access Memories Random Access", price: "270", link: "/track/0", code: "9875345436654", image: "/assets/images/daftpunk.jpg"}
        ],
        totalPrice: 0,
        displayName: "Načítání"
    }

    //cachedOrder = null;

    loadData = (orderId) => {
        /*this.cachedOrder = JSON.parse(window.localStorage.getItem("cachedOrder"))
        if (!this.cachedOrder) {*/
            LoadingOverlay.ApplyOverlay();
            const response = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/query", {
                "class": "receivedorders",
                "select": [
                    "ID",
                    "Amount",
                    "displayname",
                    {
                        "name": "Rows",
                        "value": [
                            "ID",
                            {
                                "name": "storecard_id",
                                "value": [
                                    "ID",
                                    "name",
                                    "X_price",
                                    "code",
                                    "X_Interpret_ID.FullName",
                                    "displayname",
                                    "Picture_ID.BlobData"
                                ],
                                "where" : ["EXAMPLE"]
                            }
                        ],
                        "where": ["EXAMPLE"]
                    }
                ],
                    "where": ["id eq '" + orderId + "'"],
                    "take": 1
                }
            );

            response.then((res) => {
                if (res.status === 200) {
                    LoadingOverlay.RemoveOverlay();
                    //this.cachedOrder = res.data;
                    let data = res.data[0];
                    console.log(data);

                    let newState = {
                        items : [
                            //{id: "1", bandName: "DAFT PUNK", albumName: "Random Access Memories", price: "250", link: "/track/1", code: "9875345436654", image: "/assets/images/motorhead.jpg"},
                            //{id: "0", bandName: "DAFT PUNK DAFT", albumName: "Random Access Memories Random Access Memories Random Access Memories Random Access", price: "270", link: "/track/0", code: "9875345436654", image: "/assets/images/daftpunk.jpg"}
                        ],
                        totalPrice: 0,
                        displayName: data.displayname
                    };
                    
                    newState.totalPrice = data.Amount;
                    data.Rows.forEach(row => {
                        let item = row.storecard_id;
                        if (!item) {
                            return;
                        }
                        newState.items.push({id: item.ID, bandName: item["X_Interpret_ID.FullName"], albumName: item.name, price: item.X_price, link: "/track/" + item.ID, code: item.code, image: item["Picture_ID.BlobData"]})
                    });

                    this.setState(newState);

                } else {
                    Popup.DisplayMessage("Něco se pokazilo");
                    return;
                }
            })
        /*}*/
    }

    render = (props) => {
        return (
            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto profilePage cartPage container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/user.svg" header={"OBJEDNÁVKA " + this.state.displayName}>
                        <Order items={this.state.items} totalPrice={this.state.totalPrice}/>
                    </RowSection>
                </div>
            </div>
        )
    }

    componentDidMount = () => {
        document.querySelector(".profilePage .homeTileDivider").style.display = "none";
        this.loadData(this.props.id);

        switch (this.props.result) {
            case "su":
                Popup.DisplayMessage("Objednávka přijata");
                break;
            case "ca":
                Popup.DisplayMessage("Platba zrušena");
                break;
            case "un":
                Popup.DisplayMessage("Platba nevyřízena");
                break;
            default:
                break;
        }
        
    }

}

export default OrderPage;