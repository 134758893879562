import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import ZasilkovnaHandler from '../ApiComponent/ZasilkovnaHandler/ZasilkovnaHandler';

class DisplayCart extends Component {

    wrapperClicked = (e) => {
        if (e.target.id === "displayCartWrapper" || e.target.classList.contains("displayCartClose")) {
            this.closeCart();
        }
    }

    static BringUp = () => {
        let el = document.querySelector("#displayCartWrapper");
            el.classList.add("cartPopedUp");
            el.classList.remove("cartPopedDown");
    }

    closeCart = (e) => {
        
        let el = document.querySelector("#displayCartWrapper");
                el.classList.add("cartPopedDown");
                el.classList.remove("cartPopedUp");
        
    }

    render = (props) => {

        let innerProps = this.props;

        let totalPrice = 0;
        let itemsSummary = [];
        let index = 0;
        let self = this;

        this.props.items.forEach(function(item) {
            if (typeof Number.parseFloat(item.price) == 'number')
                totalPrice += Number.parseFloat(item.price);

            let price = item.price;
            if (Number.isInteger(price))
                price = price.toString();

            let link;
            if (link == undefined) link = "/track/" + item.id;
            else link = item.link;

            itemsSummary.push(
            <div key={itemsSummary.length} className="popupCartItem">
                <div className="popupCartItemLineOne">
                    <Link onClick={self.closeCart} to={link} alt="odkaz na položku">{item.bandName + " | " + item.albumName}</Link>
                </div>
                <div className="popupCartItemLineTwo">
                    <p>{price} Kč</p>
                </div>
            </div>);
            index++;
        });

        if (this.props.delivery) {
            itemsSummary.push(
                <div key={itemsSummary.length + 1} className="popupCartItem">
                    <div className="popupCartItemLineOne">
                        <p>{this.props.delivery.name}</p>
                    </div>
                    <div className="popupCartItemLineTwo">
                        <p>{this.props.delivery.price} Kč</p>
                    </div>
                </div>);
            totalPrice += Number.parseFloat(this.props.delivery.price);
        }
        
        if (itemsSummary.length === 0)
            itemsSummary.push(<div key={itemsSummary.length} className="popupCartItem"><p className="controlCartEmptyText">Váš košík neobsahuje žádnou položku :-(</p></div>);
        
        return (
            <div onClick={this.wrapperClicked} id="displayCartWrapper" className="cartPopedDown">
                <div id="displayCart">
                    <img onClick={this.closeCart} className="popupCartClose" src="/assets/images/close_no_decor.png" alt="tlačítko pro zavření nákupního košíku" />
                    <div className="popupCartPriceSumarry">
                        <p>CELKEM</p>
                        <p>{totalPrice.toString() + " Kč"}</p>
                    </div>
                    <div className="displayCartItemsSummary">
                        {itemsSummary}
                    </div>
                </div>
            </div>
            
        );

    }

    

}

export default DisplayCart;