import React from 'react';
import { Link } from "react-router-dom";

import ApiComponent from '../ApiComponent/ApiComponent.js';
import Sharer from '../Sharer/Sharer.js';

class CDTile extends ApiComponent {

    imgSrc = null;

    share_fb = (url) => {
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.hostname) + url,'facebook-share-dialog',"width=626, height=436");
    }

    componentDidMount = () => {
        const el = document.querySelector("#image" + this.props.id);
        //https://api.abra.slox.cz/BazarCD/pictures/ --- old picture api
        this.getDataFromEndpoint("https://api.abra.slox.cz/BazarCD/script/API/StoreCards/GetPicture?STORECARD=" + this.props.id)
        .then((pic) => {
            let base64Image = pic.data;
            el.src = "data:image/png;base64," + base64Image;
        }).catch((e) => {
            el.src = "/assets/images/newsImage.jpg";
        })
    }

    render = (props) => {
        
        //true on empty/null/...
        if (!this.props.imgSrc) {
            this.imgSrc = "/assets/images/newsImage.jpg";
        //false on string
        } else {
            this.imgSrc = this.props.imgSrc;
        }

        let popUp;
        if (this.props.popUpText) {
            
            popUp = (
                <div className="newsText">
                    <img src="/assets/images/tileEye.svg" alt="" />
                    {this.props.popUpText}
                </div>
            );
        }

        let comment;
        if (this.props.comment) {
            comment = (
                <div className="commentPopupWapper revealImage hidden">
                    <div className="commentPopupInner">
                        {this.props.comment}
                    </div>
                </div>
            );
        }

        return (
            <div className="m-auto newsTile">
                <div className="CDTileInner mx-auto">
                    {popUp}
                    <div className="position-relative">
                        {comment}
                        <Link to={this.props.link}>
                            <div className="newsImageWrapper">
                                <img src={this.imgSrc} className="newsImage" alt="" id={"image" + this.props.id}></img>
                            </div>
                            <div className="CDPlayIconWrapper">
                                <img src="/assets/images/play.svg" className="CDPlayIcon" alt="" />
                            </div>
                            <img src={this.props.discIcon} className="CDDiscIcon revealImage hidden" alt="" />
                        </Link>
                        <div className="CDTileShare">
                            <img className="revealImage hidden" src="/assets/images/share.svg"  alt="tlačítko pro sdílení" />
                            <Sharer link={this.props.link} />
                        </div>
                    </div>
                    
                    <h3>{this.props.bandName}</h3>
                    <h4>{this.props.albumName}</h4>
                    <h5>{this.props.year}</h5>
                    <div className="CDTilePriceWrapper">
                        <div className="CDTilePrice">
                            <div data-index={this.props.index} data-id={this.props.id} onClick={(event)=>this.props.addHandler({id:this.props.id, bandName: this.props.bandName, albumName: this.props.albumName, price: this.props.price}, event)} className="addageButton w-100 h-100 overflow-hidden position-relative rounded-circle">
                                <div className="CDTilePriceInner">
                                    {this.props.price}
                                    <br />
                                    <br />
                                    DO KOŠÍKU
                                </div>
                            </div>
                            <div className="CDTilePriceEffect"></div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

export default CDTile;