import React from 'react';

import ApiComponent from '../ApiComponent/ApiComponent.js';

import RowSection from '../Sections/RowSection.js';
import Grid from '../Grid/Grid.js';
import { LoadingOverlay, NavbarSpacer } from '../Utils/Utils.js';
import FastPageAnimation from '../Animations/FastShopAnimations.js';

class FastShop extends ApiComponent {

    constructor (props){
        super(props);
      
        this.state = {
            items: null
        }

    }

    loadItems = (loadByXItems, startIndex = 0) => {
        let self = this;
        const apiResponse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/query", {
            "class": "storecards",
            "select": ["id", "code", "name",
            "StoreMenuItem_ID.Text",
            "X_Interpret_ID.FullName",
            "X_Publisher_ID.Name",
            "X_DateOfPublication",
            "X_Price"
            ],
            "where": ["Hidden eq false", "X_PublishEshop eq 'A'"],
            "take": loadByXItems,
            "skip": startIndex
          }
        );
        
        apiResponse.then((res) => {
            
            let data = res.data;
            let newItems = this.state.items.concat(data)

            this.setState({ items: newItems });

            if (data.length == loadByXItems) {
                this.loadItems(loadByXItems, startIndex + loadByXItems);
            } else {
                this.setState({ items: self.state.items.reverse() });
                LoadingOverlay.RemoveOverlay();
            }
        });
    }

    shouldComponentUpdate(nextProps) { //TODO need to finish this so the page doesnt refresh when you add something to the cart
        return true;
    }

    componentDidMount = () => {
        let firstItemsToLoadCount = 1000;

        LoadingOverlay.ApplyOverlay();

        const apiResponse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/query", {
            "class": "storecards",
            "select": ["id", "ean", "name",
            "StoreMenuItem_ID.Text",
            "X_Interpret_ID.FullName",
            "X_Publisher_ID.Name",
            "X_DateOfPublication",
            "X_Price",
            "dateofchange"
            ],
            "where": ["Hidden eq false", "X_PublishEshop eq 'A'"],
            "take": firstItemsToLoadCount
          }
        );

        apiResponse.then((res) => {

            let data = res.data;
            this.setState({ items: data });

            this.loadItems(1000, firstItemsToLoadCount);

        });
    }

    render (props) {

        return (

            <div className="row container-fluid m-0">
                <div className="col-12 col-lg-8 mx-auto fastShop container">
                    <NavbarSpacer />
                    <RowSection icon="/assets/images/cd-new.svg" header="E-SHOP ZKRÁCENĚ">
                        <Grid onClickHandler={this.props.AddToCartHandler} page={this.props.page} perPage={this.props.perPage} search={this.props.search} items={this.state.items}></Grid>
                    </RowSection>
                </div>
                <FastPageAnimation />
            </div>

        );

    }

}

export default FastShop;