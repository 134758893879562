import React from 'react';

import ApiComponent from '../ApiComponent/ApiComponent.js';

import { getItemIcon, LoadingOverlay, NavbarSpacer } from '../Utils/Utils.js';
import TripleSection from '../Sections/TripleSection.js';
import TripleTile from '../Tiles/TripleTile.js';

class SearchPage extends ApiComponent {

    searchParameters = [
        "Hidden eq false",
        "X_PublishEshop eq 'A'"
    ];

    currentIndex = 0;

    constructor (props){
        super(props);
      
        this.state = {
            tiles: [
                /*<TripleTile key="0" id="0" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="oooooo" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg" comment="test comment" />,
                <TripleTile key="1" id="1" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="uuuuuu" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg" comment="test comment" />,
                <TripleTile key="2" id="2" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="yyyyyy" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg" comment="test comment" />,
                <TripleTile key="3" id="3" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="tttttt" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg" comment="test comment" />,
                <TripleTile key="4" id="4" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="rrrrrr" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg" comment="test comment" />,
                <TripleTile key="5" id="5" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="eeeeee" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg" comment="test comment" />,
                //<TripleTile key="6" id="6" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="wwwwww" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg" comment="test comment" />,
                <TripleTile key="7" id="7" addHandler={this.addToCart} link="/track/0" popUpText="34 people are looking at this" bandName="qqqqqq" discIcon="/assets/images/dvd-ico.svg" albumName="Under Cover" year="2017" price="300 Kč" imgSrc="/assets/images/motorhead.jpg" comment="test comment" />*/
            ],
            loadingButtons: [],
            itemCount: 0
        }

        let searchArr = [];
        let searchString = props.search;
        let clearArray = false;
        searchString.split("&").forEach(el => {
            const lastSpaceIndex = el.lastIndexOf("=");
            let value = el.substring(lastSpaceIndex + 1);
            switch (el.substring(0, 5)) {
                case "X_Int":
                    searchArr.push("X_Interpret_ID.FirstName like '*" + value + "*' or X_Interpret_ID.LastName like '*" + value + "*'")
                    break;
                case "displ":
                    searchArr.push("Name like '*" + value + "*' or Code like '*" + value + "*'");
                    break;;
                case "X_Dat":
                    searchArr.push("X_DateOfPublication like '*" + value + "*'");
                    break;
                case "Store":
                    searchArr.push("StoreMenuItem_ID.Text eq '" + value + "'");
                    break;
                case "X_Pri":
                    searchArr.push("X_Price lt " + value);
                    break;
                case "searc":
                    clearArray = "X_Interpret_ID.FirstName like '*" + value + "*' or X_Interpret_ID.LastName like '*" + value + "*' or Name like '*" + value + "*' or Code like '*" + value + "*'";
                    break;
                default:
                    break;
            }
        });
        if (clearArray !== false) {
            searchArr = [clearArray];
        }
        
        this.searchParameters = this.searchParameters.concat(searchArr);
        
        try {
            this.getResultCount();
        } catch {}

    }

    getResultCount = () => {
        let requestBody = {
            "class": "storecards",
            "select": ["id"],
            "where": this.searchParameters
        };

        const apiResponse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/query", requestBody);

        apiResponse.then((res) => {
            let data = res.data;

            this.setState({itemCount: data.length});
        });
    }

    loadItems = (startIndex, itemCount) => {

        let self = this;

        let requestBody = {
            "class": "storecards",
            "select": [
                "id",
                "code",
                "name",
                "StoreMenuItem_ID.Text",
                "X_Interpret_ID.FullName",
                "X_DateOfPublication",
                "X_Price",
                "Picture_ID"
            ],
            "where": this.searchParameters,
            "skip": startIndex
        };

        if (!!itemCount) {
            requestBody.take = itemCount;
        }

        const apiResponse = this.postDataFromEndpoint("https://api.abra.slox.cz/BazarCD/query", requestBody);

        apiResponse.then((res) => {

            let data = res.data;
            let newTiles = [];
  
            data.forEach((el, index) => {
                let yearData = "";

                if (el.X_DateOfPublication) {
                    yearData = "Rok vydání: " + el.X_DateOfPublication.substring(0, 4);
                }

                newTiles.push(<TripleTile key={index + startIndex} id={el.id} addHandler={self.props.AddToCartHandler} link={"/track/" + el.id} bandName={el["X_Interpret_ID.FullName"]} discIcon={"/assets/images/" + getItemIcon(el["StoreMenuItem_ID.Text"])} albumName={el.name} year={yearData} price={el.X_Price + " Kč"}></TripleTile>);
            });

            let newState = {
                tiles : this.state.tiles.concat(newTiles)
            };

            this.currentIndex += newTiles.length;
            
            if (!itemCount || newTiles.length != itemCount || (newTiles.length + this.state.tiles.length) == (this.state.itemCount == 0 ? 10000000 : this.state.itemCount)) {
                newState.loadingButtons = [];
            } else {
                newState.loadingButtons = [
                    <div key="9000" className="RedButton" onClick={this.loadItems.bind(this, this.currentIndex, 6)}>NAČÍST DALŠÍ</div>,
                    <div key="9100" className="RedButton" onClick={this.loadItems.bind(this, this.currentIndex, null)}>NAČÍST VŠE</div>
                ];
            }
            
            this.setState(newState);
            
          });

          return apiResponse;
    }

    componentDidMount() {
        LoadingOverlay.ApplyOverlay();
        let promise = this.loadItems(0, 6);
        promise.then(LoadingOverlay.RemoveOverlay());
    }

    render (props) {

        return (

            <div className="row container-fluid m-0">
                <div className="SearchPage col-12 col-lg-8 mx-auto container">
                    <NavbarSpacer />
                    <TripleSection icon="/assets/images/cd-new.svg" header="VÝSLEDKY VYHLEDÁVÁNÍ" resultCount={this.state.itemCount} emptyMessage="Žádné položky nebyly nalezeny">
                        {this.state.tiles}
                    </TripleSection>
                    <div className="ButtonWrapper w-100 my-5">
                        {this.state.loadingButtons}
                    </div>
                </div>
            </div>

        );

    }

}

export default SearchPage;