import React from 'react';

const Footer = () => {

    return (
        <div className="footer container-fluid">
            <p className="float-left">COPYRIGHT {new Date().getFullYear()} CD BAZAR   |   DESIGNED BY  →  </p>
            <a  href="https://followyellow.cz" rel="noopener noreferrer" target="_blank" className="float-left" alt="odkaz na stranku designera">FOLLOW YELLOW</a>
        </div>
    );

}

export default Footer;